import { Component } from '@angular/core';
import { FundCardComponent } from '../fund-card/fund-card.component';

@Component({
  selector: 'ovation-fund-card-horizontal',
  templateUrl: './fund-card-horizontal.component.html',
  styleUrls: [
    '../fund-card/fund-card.component.scss',
    './fund-card-horizontal.component.scss'
  ]
})
export class FundCardHorizontalComponent extends FundCardComponent {
  constructor() {
    super();
  }
}

export const fundLegendDictionary = [
  {
    title: 'blue',
    color: '#19aae3'
  },
  {
    title: 'orange',
    color: '#ff7200'
  },
  {
    title: 'green',
    color: '#9cbd54'
  },
  {
    title: 'yellow',
    color: '#ebb338'
  },
  {
    title: 'red',
    color: '#e10201'
  }
];

import { Fund } from '../../models';

export const FUNDS: Fund[] = [
  {
    fundName: 'Investec Value I',
    fundFactSheetUrl:
      'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.22,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.22,
    returnsMonthToDate: -2.16,
    returnsYearToDate: -2.87,
    returnsLast3Years: 11.61,
    returnsLast5Years: 11.56,
    starRating: 2,
    riskRating: 5,
    id: 20,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Worldwide Equity FF H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 1.25,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.05,
    returnsMonthToDate: -0.52,
    returnsYearToDate: 24.61,
    returnsLast3Years: 14.47,
    returnsLast5Years: 19.8,
    starRating: 3,
    riskRating: 3,
    id: 22,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Value H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.51,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.22,
    returnsMonthToDate: -2.16,
    returnsYearToDate: -2.88,
    returnsLast3Years: 11.44,
    returnsLast5Years: 11.35,
    starRating: 1,
    riskRating: 5,
    id: 23,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Property Equity H',
    fundFactSheetUrl:
      'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.96,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.28,
    returnsMonthToDate: 0.71,
    returnsYearToDate: 11.8,
    returnsLast3Years: 11.26,
    returnsLast5Years: 13.81,
    starRating: 4,
    riskRating: 3,
    id: 24,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Property Equity I',
    fundFactSheetUrl:
      'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.68,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.28,
    returnsMonthToDate: 0.73,
    returnsYearToDate: 12.08,
    returnsLast3Years: 11.57,
    returnsLast5Years: 14.14,
    starRating: 4,
    riskRating: 3,
    id: 25,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Opportunity I',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 1.93,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.13,
    returnsMonthToDate: -0.65,
    returnsYearToDate: 11.33,
    returnsLast3Years: 9.04,
    returnsLast5Years: 9.98,
    starRating: 4,
    riskRating: 3,
    id: 26,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Managed I',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.34,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.24,
    returnsMonthToDate: 0.67,
    returnsYearToDate: 15.63,
    returnsLast3Years: 12.35,
    returnsLast5Years: 13.28,
    starRating: 4,
    riskRating: 2,
    id: 27,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Opportunity H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 2.1,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.13,
    returnsMonthToDate: -0.66,
    returnsYearToDate: 11.16,
    returnsLast3Years: 8.85,
    returnsLast5Years: 9.8,
    starRating: 4,
    riskRating: 3,
    id: 28,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec High Income Z',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 1,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.02,
    returnsMonthToDate: 0.51,
    returnsYearToDate: 9.02,
    returnsLast3Years: 9.16,
    returnsLast5Years: 8.1,
    starRating: 5,
    riskRating: 3,
    id: 29,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Managed H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.62,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.24,
    returnsMonthToDate: 0.65,
    returnsYearToDate: 15.34,
    returnsLast3Years: 12.06,
    returnsLast5Years: 12.97,
    starRating: 4,
    riskRating: 3,
    id: 31,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Global Strategic Managed FF I',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.96,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.14,
    returnsMonthToDate: 0.03,
    returnsYearToDate: 19.3,
    returnsLast3Years: 15.4,
    returnsLast5Years: 17.75,
    starRating: 5,
    riskRating: 5,
    id: 32,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Global Multi-Asset Income FF I',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.11,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.06,
    returnsMonthToDate: -0.72,
    returnsYearToDate: 8.42,
    returnsLast3Years: null,
    returnsLast5Years: null,
    starRating: 2,
    riskRating: 2,
    id: 33,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Global Strategic Managed FF H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 1.25,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: -0.14,
    returnsMonthToDate: 0.02,
    returnsYearToDate: 19,
    returnsLast3Years: 15.04,
    returnsLast5Years: 17.41,
    starRating: 5,
    riskRating: 5,
    id: 34,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Global Franchise FF H',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 1.25,
    performanceFee: 0,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 0.22,
    returnsMonthToDate: 0.34,
    returnsYearToDate: 25.08,
    returnsLast3Years: 18.34,
    returnsLast5Years: 20.24,
    starRating: 4,
    riskRating: 1,
    id: 35,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  },
  {
    fundName: 'Investec Equity I',
    fundFactSheetUrl: null,
    fundManagerName: 'Investec Fund Mgrs SA',
    fundManagerWebsite: 'www.investecassetmanagement.com',
    pensionProviderName: 'Dashboard Investec',
    actualManagementFee: 0.22,
    performanceFee: 20,
    valuationDate: '2017-11-21T00:00:00+02:00',
    changePercentage: 1.17,
    returnsMonthToDate: 2.59,
    returnsYearToDate: 19.07,
    returnsLast3Years: 11.26,
    returnsLast5Years: 14.86,
    starRating: 5,
    riskRating: 4,
    id: 36,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null
  }
];

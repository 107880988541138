import { Investment } from '../../models/investment.interface';

export const INVESTMENTS: Investment[] = [
  {
    id: 1,
    title: 'Ovation Scheme Fund',
    type: 'Retirement Annuity',
    valuation: 249012,
    userId: 1,
    managed: true
  },
  {
    id: 2,
    title: 'Old Mutual Invest Tax Free Plan',
    type: 'Tax Free Savings Account',
    valuation: 98400,
    userId: 1,
    managed: false
  },
  {
    id: 3,
    title: 'Templeton Global Balanced Fund',
    type: 'Other Investments',
    valuation: 30000,
    userId: 1,
    managed: false
  }
];

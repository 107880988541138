import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  tap,
  debounceTime,
  takeUntil
} from 'rxjs/operators';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FundContribution } from '@ovation/core';
import { FundTable } from '../../classes';

@Component({
  selector: 'ovation-fund-contibutions-table',
  templateUrl: './fund-contibutions-table.component.html',
  styleUrls: ['./fund-contibutions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FundContibutionsTableComponent extends FundTable {
  @HostBinding('attr.id') id: string = 'fund-contributions-table';

  @Input() fundsRemovable = true;

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super(fb, cd, locale);
  }

  public fundSplitChange() {
    console.log('fund split change');
  }
}

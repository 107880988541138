import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Band } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  public DateDiff: any;

  constructor(private dataService: DataService) {
    this.DateDiff = {
      inDays: function(d1: Date, d2: Date) {
        const t2 = d2.getTime() - d2.getTimezoneOffset() * 60 * 1000;
        const t1 = d1.getTime() - d1.getTimezoneOffset() * 60 * 1000;

        return (t2 - t1) / (24 * 3600 * 1000);
      },
      inWeeks: function(d1: Date, d2: Date) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return (t2 - t1) / (24 * 3600 * 1000 * 7);
      },
      inMonths: function(d1: Date, d2: Date) {
        const years = d2.getFullYear() - d1.getFullYear();
        const monthDiff = d2.getMonth() - d1.getMonth();
        const dayDiff = d2.getDate() - d1.getDate();

        let months = years * 12 + monthDiff;

        if (dayDiff < 0) {
          months -= 1;
        }

        return months;
      },
      inYears: function(d1: Date, d2: Date) {
        const r1 = this.JSDateToExcelDate(d1);
        const r2 = this.JSDateToExcelDate(d2);

        const dayDifference = r1 - r2;
        const years = dayDifference / 365.25 + 0.5;
        // round down to the next whole number
        return Math.floor(years);
      }.bind(this)
    };
  }

  private JSDateToExcelDate = function(inDate) {
    const returnDateTime =
      25569.0 +
      (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
        (1000 * 60 * 60 * 24);
    return returnDateTime.toString().substr(0, 5);
  };

  public yearDate() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public roundToDp(value: number, dp: number): number {
    const divisor = Math.pow(10, dp);
    value = value * divisor;
    value = Math.round(value);
    value = value / divisor;
    return value;
  }

  public roundToNearestUnit(value, unit) {
    return Math.round(value / unit) * unit;
  }

  public getTaxYearFromDate(date) {
    if ((date.getMonth() === 3 && date.getDate() >= 6) || date.getMonth() > 3)
      return date.getFullYear();
    else return date.getFullYear() - 1;
  }

  public getTaxRateFromSalary(taxRates, salary) {
    for (let i = 0; i < taxRates.length; i++) {
      const rate = taxRates[i];
      const _earnings = rate.earnings;
      const _value = rate.value;
      if (salary >= _earnings) {
        const nextRate = taxRates[i + 1];
        if (nextRate !== undefined && salary >= nextRate.earnings) {
          continue;
        } else {
          return _value;
        }
      }
    }
    // return app.Resources.Region.lowerTaxBand;
  }

  public getCompanyRateFromBand(bands: Band[], employeeRate: number) {
    for (let i = 0; i < bands.length; i++) {
      const _banding = bands[i];
      _banding.current = false;
    }

    let _currentCompany = 0.0;

    for (let i = 0; i < bands.length; i++) {
      const _banding = bands[i];
      const _employee = parseFloat(_banding.employee);
      if (employeeRate < _employee) {
        break;
      } else {
        const _nextBanding = bands[i + 1];
        if (
          _nextBanding !== undefined &&
          employeeRate >= parseFloat(_nextBanding.employee)
        ) {
          _banding.current = false;
          continue;
        } else {
          _currentCompany = parseFloat(_banding.company);
          _banding.current = true;
          break;
        }
      }
    }
    return _currentCompany;
  }

  /** Return a number between two numbers. */
  public clamp(value: number, min = 0, max = 1) {
    return Math.max(min, Math.min(value, max));
  }
}

import {
  Directive,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkAccordion } from '@angular/cdk/accordion';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { HOME, END } from '@angular/cdk/keycodes';
import {
  OVN_ACCORDION,
  OvnAccordionBase,
  OvnAccordionDisplayMode
} from './accordion-base';
import { OvnExpansionPanelHeader } from './expansion-panel-header';

/**
 * Directive for Ovation Accordion.
 */
@Directive({
  selector: 'ovn-accordion',
  exportAs: 'ovnAccordion',
  inputs: ['multi'],
  providers: [
    {
      provide: OVN_ACCORDION,
      useExisting: OvnAccordion
    }
  ],
  host: {
    class: 'ovn-accordion'
  }
})
export class OvnAccordion extends CdkAccordion
  implements OvnAccordionBase, AfterContentInit {
  private _keyManager: FocusKeyManager<OvnExpansionPanelHeader>;

  @ContentChildren(OvnExpansionPanelHeader, { descendants: true })
  _headers: QueryList<OvnExpansionPanelHeader>;

  /** Whether the expansion indicator should be hidden. */
  @Input()
  get hideToggle(): boolean {
    return this._hideToggle;
  }
  set hideToggle(show: boolean) {
    this._hideToggle = coerceBooleanProperty(show);
  }
  private _hideToggle = false;

  /**
   * Display mode used for all expansion panels in the accordion. Currently two display
   * modes exist:
   *  default - a gutter-like spacing is placed around any expanded panel, placing the expanded
   *     panel at a different elevation from the rest of the accordion.
   *  flat - no spacing is placed around expanded panels, showing all panels at the same
   *     elevation.
   */
  @Input() displayMode: OvnAccordionDisplayMode = 'default';

  ngAfterContentInit() {
    this._keyManager = new FocusKeyManager(this._headers).withWrap();
  }

  /** Handles keyboard events coming in from the panel headers. */
  _handleHeaderKeydown(event: KeyboardEvent) {
    const { keyCode } = event;
    const manager = this._keyManager;

    if (keyCode === HOME) {
      manager.setFirstItemActive();
      event.preventDefault();
    } else if (keyCode === END) {
      manager.setLastItemActive();
      event.preventDefault();
    } else {
      this._keyManager.onKeydown(event);
    }
  }

  _handleHeaderFocus(header: OvnExpansionPanelHeader) {
    this._keyManager.updateActiveItem(header);
  }
}

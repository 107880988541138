export const GUARANTEE_PERIOD_OPTIONS = [
  {
    id: '1',
    displayValue: '0 Years',
    calcValue: '0'
  },
  {
    id: '2',
    displayValue: '5 Years',
    calcValue: '5'
  },
  {
    id: '3',
    displayValue: '10 Years',
    calcValue: '10'
  }
];

import { Profile } from '../../models';

export const PROFILES: Profile[] = [
  {
    id: 1,
    name: 'FirstName LastName',
    birthDate: '1981-02-29',
    gender: 'Male',
    adjustForInflation: false,
    annualSalary: '800000.00',
    taxBand: '0.0000',
    retirementAge: '65',
    partnerBirthDate: '1943-03-01',
    partnerGender: 'Female',
    employment: {
      startDate: '2014-01-01',
      grossInvestmentReturn: '0.1550',
      rates: {
        salaryIncrease: '0.1250',
        offsetIncrease: '0.0250',
        grossInvestmentReturn: '0.15500',
        annualManagementCharge: '0.0100',
        ilg5: '0.0005',
        ilg0: '0.0008',
        yieldAdjustment: '-0.0050',
        low: '0.0200',
        medium: '0.0500',
        high: '0.0800',
        lowAdjustment: '0.0150',
        mediumAdjustment: '0.0350'
      }
    },
    annuity: {
      partnerPercentage: '0.5000',
      guaranteePeriod: '5',
      pensionIncrease: '0.0500',
      expenseAssumption: '0.0400',
      monthlyPaymentAdjustment: '0.4583'
    },
    links: {
      document: null,
      modifyContributions:
        '/Modelling/Pension/ChangeContributionsWithDefault?contributionValue=0',
      canModifyContributions: true
    },
    schemes: [
      {
        name: 'Lindt Scheme',
        code: '14',
        type: 'DC',
        managed: true,
        annualManagementCharge: '0.0450',
        policy: {
          name: null,
          dateJoined: '2014-05-01',
          status: 'ACT',
          initialFunding: '650701.01',
          initialValuationDate: '2014-12-11',
          contributionsIncreaseRate: '0.0400',
          contributions: {
            employee: '0.0700',
            company: '0.1000',
            contributionAmount: '0.0000',
            band: {
              type: 'MATCHING',
              title: 'Contributions Banding',
              helpText: null,
              bands: [
                {
                  employee: '0.0100',
                  company: '0.0400',
                  employeeText: '1.00 %',
                  companyText: '4.00 %',
                  current: false
                },
                {
                  employee: '0.0500',
                  company: '0.0500',
                  employeeText: '5.00 %',
                  companyText: '5.00 %',
                  current: true
                },
                {
                  employee: '0.1000',
                  company: '0.0600',
                  employeeText: '10.00 %',
                  companyText: '6.00 %',
                  current: false
                },
                {
                  employee: '0.1500',
                  company: '0.0750',
                  employeeText: '15.00 %',
                  companyText: '7.500 %',
                  current: false
                }
              ]
            }
          },
          salaryIncreaseRate: null
        },
        scheme: null
      },
      {
        name: 'Nemo Fund',
        code: '',
        type: 'DC',
        managed: false,
        annualManagementCharge: '0.0500',
        policy: {
          name: 'Nemo Fund',
          dateJoined: '2014-05-20',
          status: 'active',
          initialFunding: '5200000.00',
          initialValuationDate: '2014-12-11',
          contributionsIncreaseRate: '0.0000',
          contributions: {
            employee: '0.0000',
            company: '0.0000',
            contributionAmount: '3000.00',
            band: null
          },
          salaryIncreaseRate: null
        },
        scheme: null
      }
    ]
  }
];

export * from './memberProfile.service';
import { MemberProfileService } from './memberProfile.service';
export * from './referenceData.service';
import { ReferenceDataService } from './referenceData.service';
export * from './retirementPlan.service';
import { RetirementPlanService } from './retirementPlan.service';
export const APIS = [
  MemberProfileService,
  ReferenceDataService,
  RetirementPlanService
];

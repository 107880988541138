import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';

import { Checkbox } from './checkbox.component';
import { OvnCheckboxRequiredValidator } from './checkbox-required-validator';

@NgModule({
  imports: [CommonModule, ObserversModule],
  exports: [Checkbox, OvnCheckboxRequiredValidator],
  declarations: [Checkbox, OvnCheckboxRequiredValidator]
})
export class CheckboxModule {}

export const REGIONS = [{
  id: 1,
  name: 'South Africa',
  inflation: '0.0250',
  annualManagementCharge: '0.0100',
  finalSalaryLifetimeAllowanceConversionFactor: '20',
  taxEfficiencyLimit: '0.1500',
  lowerTaxBand: '0.1800',
  lifeTimeAllowanceIncreaseRate: '0.0025',
  taxFreeCashMax: {
    percentage: '0.3300',
    amount: '-1',
    increaseRate: '0.0000'
  },
  maxContributions: {
    earningsCap: '1500000',
    capIncreaseRate: '0.0333',
    onlyEmployeeContributions: true
  },
  statePension: {},
  limits: {
    contributionPercentageTaxLimit: '15.00',
    contributionAmountTaxLimit: '350000.00',
    lumpSumAmountLimit: '500000.00',
    ageMin: '55',
    ageMax: '95'
  }
}];

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  LOCALE_ID,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FundTable } from '../../classes';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'ovation-benefits-summary-table',
  templateUrl: './benefits-summary-table.component.html',
  styleUrls: ['./benefits-summary-table.component.scss']
})
export class BenefitsSummaryTableComponent implements OnInit, OnChanges {
  @Input() monthlySalary = 0;
  // @Input() bonus = 0;
  // @Input() retirementPlan = 0;
  // @Input() medicalScheme = 0;
  // @Input() overallBenefitValue = 0;

  public bonus = 1000; //annual
  //public retirementPlan = 500000; //annual
  //public medicalScheme = 20000; //annual. do i pay this or the company
  //public overallBenefitValue = this.retirementPlan + this.medicalScheme;
  public companyFundedBenefits = 3108.84;
  public carAllowance = 3000.0;

  public total =
    this.monthlySalary +
    this.bonus +
    this.carAllowance +
    this.companyFundedBenefits;

  constructor(
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnInit() {
    this.total =
      this.monthlySalary +
      this.bonus +
      this.carAllowance +
      this.companyFundedBenefits;
    // this.medicalScheme;
    // console.log('total:', this.total);
    // console.log(this.monthlySalary);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.total =
      this.monthlySalary +
      this.bonus +
      this.carAllowance +
      this.companyFundedBenefits;
    //this.medicalScheme;
  }
}

import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'ovation-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent extends DialogContentBase implements OnInit {
  @Input() title: string;
  @Input() template: TemplateRef<any>;
  @Input() color: string = 'primary';

  constructor(public dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit() {}

  public onCancelAction(): void {
    this.dialog.close({ text: 'Cancel' });
  }

  public onConfirmAction(): void {
    this.dialog.close({ text: 'Submit', primary: true });
  }
}

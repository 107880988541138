import { Component, OnInit } from '@angular/core';
import { DataService, User, ProfileService, Profile } from '@ovation/core';
import { Router, NavigationEnd } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

import { EnvironmentService } from './shared/services';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'ovation-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private envService: EnvironmentService,
    private profileService: ProfileService,
    private router: Router
  ) {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: '1s',
        ss: '%ds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1m',
        MM: '%dm',
        y: '1y',
        yy: '%dy'
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.dataService
      .getUser(1)
      .pipe(take(1))
      .subscribe((user: User) => (this.envService.user = user));

    forkJoin(
      this.dataService.getProfile(1),
      this.dataService.getRegion(1),
      this.dataService.getTaxRates(),
      this.dataService.getMortalityFactors()
    ).subscribe(([profile, region, taxRates, mortalityFactors]) => {
      this.envService.profile = profile;
      this.profileService.addProfile(
        profile,
        region,
        taxRates,
        mortalityFactors
      );
    });

    // this.dataService
    //   .getProfile(1)
    //   .pipe(take(1))
    //   .subscribe((profile: Profile) => {
    //     this.envService.profile = profile;
    //     // this.profileService.setPensions();
    //     this.profileService.addProfile(profile);
    //   });
  }
}

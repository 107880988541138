import { Pension } from './pension';

export class PensionPeriod {
  private pension: Pension;
  private projectionStartDate: Date;
  private projectionEndDate: Date;
  private startDate: Date;
  private _endDate: Date;
  public get endDate(): Date {
    const _anniversaryDate = new Date(
      this.startDate.getFullYear() + 1,
      this.projectionStartDate.getMonth(),
      this.projectionStartDate.getDate()
    );
    if (_anniversaryDate >= this.projectionEndDate) {
      return this.projectionEndDate;
    }
    return _anniversaryDate;
  }
  public set endDate(value: Date) {
    this._endDate = value;
  }
  private isFirstPeriod: boolean;
  private isLastPeriod: boolean;
  private isAnniversary: boolean;
  private employeeContributionRate: number;
  private employerContributionRate: number;
  private netInvestmentReturn: number;
  private salaryIncreaseRate: number;
  private initialSalary: number;
  private _monthsInPeriod: number;
  public get monthsInPeriod(): number {
    const _monthsInPeriod = this.pension.utilitiesService.DateDiff.inMonths(
      this.startDate,
      this.endDate
    );

    return _monthsInPeriod;
  }
  public set monthsInPeriod(value: number) {
    this._monthsInPeriod = value;
  }
  private _percentageOfYear: number;
  public get percentageOfYear(): number {
    let _percentageOfYear = 0;
    if (this.monthsInPeriod) {
      const _monthsInPeriod = this.monthsInPeriod;
      _percentageOfYear = _monthsInPeriod / 12;
    }
    return _percentageOfYear;
  }
  public set percentageOfYear(value: number) {
    this._percentageOfYear = value;
  }

  // SALARY
  private _salary: number;
  public get salary(): number {
    let _initialSalary = this.initialSalary;

    if (this.isAnniversary && this.monthsInPeriod) {
      const _monthsInPeriod = this.monthsInPeriod;
      if (_monthsInPeriod > 0) {
        _initialSalary = _initialSalary * (1 + this.salaryIncreaseRate);
      }
    }
    return _initialSalary;
  }
  public set salary(value: number) {
    this._salary = value;
  }

  private _fundValue: number;
  public get fundValue(): number {
    return this._fundValue;
  }
  public set fundValue(value: number) {
    // console.log('set fundValue', value);

    this._fundValue = value;

    if (this._fundValue > 0) {
      const _percentageOfYear = this.percentageOfYear;
      const _netInvestmentReturn = this.netInvestmentReturn;
      const _interestFactor = Math.pow(
        1 + _netInvestmentReturn,
        _percentageOfYear
      );

      this._fundValue = this._fundValue * _interestFactor;
    }
  }

  private _employeeContributionAmount: number;
  public get employeeContributionAmount(): number {
    let _employeeContributionAmount = 0;
    if (this.salary) {
      _employeeContributionAmount = this.employeeContributionRate * this.salary;
    }
    return _employeeContributionAmount;
  }
  public set employeeContributionAmount(value: number) {
    this._employeeContributionAmount = value;
  }

  private _employerContributionAmount: number;
  public get employerContributionAmount(): number {
    let _employerContributionAmount = 0;
    if (this.salary) {
      _employerContributionAmount = this.employerContributionRate * this.salary;
    }
    return _employerContributionAmount;
  }
  public set employerContributionAmount(value: number) {
    this._employerContributionAmount = value;
  }

  // CONTRIBUTION AMOUNT
  private _contributionAmount: number;
  public get contributionAmount(): number {
    let _contributionAmount = 0;
    if (
      this.employeeContributionAmount !== undefined &&
      this.employeeContributionAmount !== null &&
      this.employerContributionAmount !== undefined &&
      this.employerContributionAmount !== null
    ) {
      _contributionAmount =
        this.employeeContributionAmount + this.employerContributionAmount;
    }
    return _contributionAmount;
  }
  public set contributionAmount(value: number) {
    this._contributionAmount = value;
  }

  private _valuation: number;
  public get valuation(): number {
    let _valuation = 0;

    if (
      this.contributionAmount !== undefined &&
      this.contributionAmount !== null &&
      this.percentageOfYear !== undefined &&
      this.percentageOfYear !== null
    ) {
      const _percentageOfYear = this.percentageOfYear;

      // for unmanaged funds use the user-defined monthly contributions amount
      const _contributionAmount = !this.managed
        ? this.pension.contributionAmount
        : this.contributionAmount;

      // console.log('this.managed', this.managed);
      // console.log('_contributionAmount', _contributionAmount);

      let _interestFactor = 1;

      if (_percentageOfYear > 0) {
        const _netInvestmentReturn = this.netInvestmentReturn;
        const _monthlyRate = Math.pow(1 + _netInvestmentReturn, 1 / 12);
        const _monthsInPeriod = this.monthsInPeriod;

        _interestFactor =
          _netInvestmentReturn === 0
            ? 1
            : ((1 / _monthsInPeriod) *
                _monthlyRate *
                (1 - Math.pow(1 + _netInvestmentReturn, _percentageOfYear))) /
              (1 - _monthlyRate);
      }

      const _contributionsOverYear = _contributionAmount * _percentageOfYear;
      const _contributionsEndAmount = _contributionsOverYear * _interestFactor;
      _valuation = this.fundValue + _contributionsEndAmount;
    }

    return _valuation;
  }
  public set valuation(value: number) {
    this._valuation = value;
  }

  private managed: boolean;
  private annualSalary: any;

  constructor(
    pension: Pension,
    projectionStartDate: Date,
    projectionEndDate: Date,
    startDate: any,
    isFirstPeriod: boolean,
    salaryIncreaseRate: number,
    annualSalary: any,
    // employeeContributionRate: any,
    // netInvestmentReturn: any,
    // salary: any,
    fundValue: any
    // managed: any,
    // contributionAmount: any
  ) {
    this.pension = pension;
    this.projectionStartDate = projectionStartDate;
    this.projectionEndDate = projectionEndDate;
    this.startDate = startDate;
    this.isFirstPeriod = isFirstPeriod;
    this.employeeContributionRate = pension.employeeRate;
    this.employerContributionRate = pension.companyRate;
    this.netInvestmentReturn = pension.netInvestmentReturn;
    this.salaryIncreaseRate = salaryIncreaseRate;
    this.managed = pension.managed;
    this.initialSalary = annualSalary;

    this.isAnniversary = this.isFirstPeriod
      ? false
      : this.startDate.getMonth() === this.projectionStartDate.getMonth() &&
        this.startDate.getDate() === this.projectionStartDate.getDate();

    this.fundValue = fundValue;
  }
}

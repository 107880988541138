import { OnInit, OnDestroy, EventEmitter, Input } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  forkJoin,
  combineLatest
} from 'rxjs';
import { takeUntil, map, mergeMap, take } from 'rxjs/operators';

import {
  DataService,
  User,
  Fund,
  FundContribution,
  Benefit
} from '@ovation/core';
import { EnvironmentService } from '../services';
import { RISKS, fundLegendDictionary } from '../dictionaries';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { benefits } from '../../member/benefits/data.service';

export abstract class Pension implements OnInit, OnDestroy {
  public user$: BehaviorSubject<User>;
  public benefit$: Observable<Benefit>;
  public portfolioGraphFunds$: Observable<any[]>;
  public sortFunds = [];
  public userFunds$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public userBenefits$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public userContributions$: Observable<any>;
  public userFundsData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public graphData$: Observable<any[]>;
  public ratingFilters = [5, 4, 3, 2, 1];
  public userRiskAppetite: any;
  public availableFunds = 0;
  public allocatedFunds = 0;

  public userContributionPct = 0;
  public userContributionPct$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(0);
  public benefitAmount = 0;
  public amountMonthly = 0;
  public amountYearly = 0;
  public minEmployeeContribution = 0.04;
  public companyMin = 0.04;
  public companyMax = 0.06;
  public companyContributionPct = 0;
  public companyContributionPct$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(0);
  public companyAmountMonthly = 0;
  public companyAmountYearly = 0;
  public totalContributionPct = 0;
  public totalAmountMonthly = 0;
  public totalBenefitsAmount = 0;
  public totalAmountYearly = 0;
  public taxThreshold = 0.275;
  public monthlyTaxReliefSaving = 0;
  public annualTaxReliefSaving = 0;
  public yearlySalary = 0;
  public additionalVoluntaryContributionPct = 0;
  public additionalVoluntaryContributionAmount = 0;
  public onContributionChange: EventEmitter<any> = new EventEmitter<any>();

  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  @Input() data: FundContribution[];

  protected destroyed$ = new Subject<boolean>();

  constructor(
    protected dataService: DataService,
    protected envService: EnvironmentService
  ) {
    this.user$ = this.envService.user$;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadFunds();
  }

  protected loadFunds(): void {
    this.gridView = {
      data: orderBy(this.data, this.sort),
      total: this.data.length
    };
  }

  ngOnInit() {
    const fundGraphData = fund => this.dataService.getGraphData(fund.fundName);

    this.user$.pipe(takeUntil(this.destroyed$)).subscribe((user: User) => {
      if (user) {
        if (user.yearlySalary) {
          this.yearlySalary = user.yearlySalary;
        }

        if (user.riskAppetite) {
          this.userRiskAppetite = RISKS.find(
            risk => risk.value === user.riskAppetite
          );
        }

        if (user.benefits) {
          this.userRiskAppetite = RISKS.find(
            risk => risk.value === user.riskAppetite
          );
        }

        if (user.funds) {
          this.userFunds$.next(this.mapLegend(user.funds));

          // Get funds' graph data
          this.graphData$ = this.userFunds$.pipe(
            map(fundcontribs => {
              return fundcontribs.map(c => c.fund);
            }),
            mergeMap(funds => forkJoin(...funds.map(fundGraphData))),
            map(graphData => {
              return graphData.map((data: any) => {
                return {
                  title: data[0].fundName,
                  data: data
                };
              });
            }),
            map(data => this.mapLegend(data))
          );
        }

        if (user.benefits) {
          this.userBenefits$.next(user.benefits);
        }

        if (user.userMonthlyContribution) {
          this.availableFunds =
            user.monthlySalary * user.userMonthlyContribution;
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public removeFund(fundId: number) {
    console.log('remove fund', fundId);
  }

  public removeBenefit(benefitId: number) {
    console.log('remove benefit', benefitId);
  }

  public addFund(fundId: number) {
    console.log('add fund', fundId);
  }

  public updateAllocated(val: number) {
    this.allocatedFunds = val;
  }

  public contributionChange(value: number) {
    this.userContributionPct = value;
    this.userContributionPct$.next(this.userContributionPct);

    if (
      this.minEmployeeContribution &&
      this.userContributionPct >= this.minEmployeeContribution
    ) {
      this.additionalVoluntaryContributionPct =
        this.userContributionPct - this.minEmployeeContribution;
    }

    combineLatest(this.user$)
      .pipe(take(1))
      .subscribe(([user]) => {
        this.amountMonthly = user.monthlySalary * this.userContributionPct;
        this.amountYearly = user.yearlySalary * this.userContributionPct;
        this.yearlySalary = user.yearlySalary;

        if (
          this.additionalVoluntaryContributionPct ||
          this.additionalVoluntaryContributionPct === 0
        ) {
          this.additionalVoluntaryContributionAmount =
            user.monthlySalary * this.additionalVoluntaryContributionPct;
        }

        const companyContributionPct =
          this.userContributionPct > this.companyMin
            ? this.clamp(
                this.userContributionPct,
                this.companyMin,
                this.companyMax
              )
            : 0;

        this.companyContributionPct = companyContributionPct;

        this.companyContributionPct$.next(companyContributionPct);

        this.companyAmountMonthly =
          user.monthlySalary * this.companyContributionPct;

        this.companyAmountYearly =
          user.yearlySalary * this.companyContributionPct;

        this.totalContributionPct =
          this.userContributionPct + this.companyContributionPct;
        this.totalAmountMonthly =
          this.amountMonthly + this.companyAmountMonthly;
        this.totalAmountYearly = this.amountYearly + this.companyAmountYearly;

        this.annualTaxReliefSaving =
          this.totalAmountYearly * user.userTaxBracket;
        this.monthlyTaxReliefSaving = this.annualTaxReliefSaving / 12;

        this.onContributionChange.emit({
          userContributionPct: this.userContributionPct,
          companyContributionPct: this.companyContributionPct
        });
      });
  }

  protected mapLegend(funds: any[]): any[] {
    return funds.map((fund: FundContribution | Fund, i: number) => {
      const legend = fundLegendDictionary[i]
        ? fundLegendDictionary[i]
        : fundLegendDictionary[i - (fundLegendDictionary.length - 1)];

      return { ...fund, legend };
    });
  }

  protected update(user: User): Observable<any> {
    return this.dataService.updateUser(user);
  }

  /** Return a number between two numbers. */
  protected clamp(value: number, min = 0, max = 1) {
    return Math.max(min, Math.min(value, max));
  }
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  QueryList
} from '@angular/core';
import {
  ValueAxisLabels,
  ChartComponent,
  SeriesItemComponent,
  LegendLabelsContentArgs
} from '@progress/kendo-angular-charts';

import { OvationChart } from '@ovation/chart';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'ovation-contributions-history-chart',
  templateUrl: './contributions-history-chart.component.html',
  styleUrls: ['./contributions-history-chart.component.scss']
})
export class ContributionsHistoryChartComponent extends OvationChart
  implements OnInit, AfterViewInit {
  @ViewChild(ChartComponent) chart: ChartComponent;

  @Input() rangeData: any[];
  @Input() contributionsData: any;
  @Input() valuationsData: any;

  public seriesComponents: QueryList<SeriesItemComponent>;
  public graphBaseUnit = 'months';
  public grapValueAxisLabels: ValueAxisLabels = {
    position: 'start'
  };
  public legend: any[] = [];

  constructor(protected breakpointObserver: BreakpointObserver) {
    super(breakpointObserver);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    this.seriesComponents = this.chart.seriesComponents;
    this.legend = this.chart.seriesComponents.toArray();
  }

  public formatGraphLegendLabels(e: LegendLabelsContentArgs) {
    return e.text;
  }
}

import { InjectionToken } from '@angular/core';
import { CdkAccordion } from '@angular/cdk/accordion';

/** OvnAccordion's display modes. */
export type OvnAccordionDisplayMode = 'default' | 'flat';

/**
 * Base interface for a `OvnAccordion`.
 */
export interface OvnAccordionBase extends CdkAccordion {
  /** Whether the expansion indicator should be hidden. */
  hideToggle: boolean;

  /** Display mode used for all expansion panels in the accordion. */
  displayMode: OvnAccordionDisplayMode;

  /** Handles keyboard events coming in from the panel headers. */
  _handleHeaderKeydown: (event: KeyboardEvent) => void;

  /** Handles focus events on the panel headers. */
  _handleHeaderFocus: (header: any) => void;
}

/**
 * Token used to provide a `OvnAccordion` to `OvnExpansionPanel`.
 * Used primarily to avoid circular imports between `OvnAccordion` and `OvnExpansionPanel`.
 */
export const OVN_ACCORDION = new InjectionToken<OvnAccordionBase>(
  'OVN_ACCORDION'
);

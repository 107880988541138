import {
  Component,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';

import { FundTable } from '../../classes';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged,
  tap
} from 'rxjs/operators';

@Component({
  selector: 'ovation-switch-contributions-table',
  templateUrl: './switch-contributions-table.component.html',
  styleUrls: ['./switch-contributions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SwitchContributionsTableComponent extends FundTable
  implements OnChanges {
  @Input() increment = 1;
  @Output() totalSwitchChange = new EventEmitter<object>();
  public valueDate = moment()
    .subtract(1, 'days')
    .format('DD/MM/YYYY');
  public amountToSwitch = 0;

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super(fb, cd, locale);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.data) {
      this.formGroup.addControl(
        'fundsSwitchOutTotal',
        this.fb.control(null, [Validators.max(100)])
      );
      this.formGroup.addControl('fundsSwitchOut', this.fb.array([]));
      this.formGroup.addControl('fundsTotalContributions', this.fb.array([]));

      for (let i = 0; i < this.data.length; i++) {
        (<FormArray>this.formGroup.controls.fundsSwitchOut).push(
          this.fb.control(0, [Validators.max(100)])
        );

        (<FormArray>this.formGroup.controls.fundsTotalContributions).push(
          this.fb.control(null, [])
        );
      }

      this.formGroup.controls.fundsSwitchOut.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          map(vals => {
            console.log(vals);

            return vals.map((val, i) => {
              const pct = val ? parseInt(<string>val, 10) : 0;

              const fundValue = (<FormArray>(
                this.formGroup.get('fundsTotalContributions')
              )).at(i).value;

              const amount = (fundValue * pct) / 100;

              return amount;
            });
          }),
          map(vals => vals.reduce((acc, curr) => acc + curr, 0)),
          debounceTime(100),
          distinctUntilChanged(),
          tap(v => {
            this.updateSwitchTotal(v);

            this.cd.markForCheck();
          })
        )
        .subscribe();

      for (let i = 0; i < this.data.length; i++) {
        (<FormArray>this.formGroup.controls.fundsTotalContributions)
          .at(i)
          .setValue(this.data[i].totalContributionAmount);
      }
    }
  }

  private updateSwitchTotal(value: number) {
    this.amountToSwitch = value;

    this.totalSwitchChange.emit({
      value,
      switchAmounts: [
        ...this.formGroup.controls.fundsSwitchOut.value.map((val, i) => {
          return (
            (val / 100) *
            (<FormArray>this.formGroup.controls.fundsTotalContributions).at(i)
              .value
          );
        })
      ]
    });
  }
}

export const PENSION_INCREASE_OPTIONS = [
  {
    id: '1',
    displayValue: '0.0%',
    calcValue: '0.0000'
  },
  {
    id: '2',
    displayValue: '1.0%',
    calcValue: '0.0100'
  },
  {
    id: '3',
    displayValue: '2.0%',
    calcValue: '0.0200'
  },
  {
    id: '4',
    displayValue: '2.5%',
    calcValue: '0.0250'
  },
  {
    id: '5',
    displayValue: '3.0%',
    calcValue: '0.0300'
  },
  {
    id: '6',
    displayValue: '4.0%',
    calcValue: '0.0400'
  },
  {
    id: '7',
    displayValue: '5.0%',
    calcValue: '0.0500'
  }
];

import { Injectable } from '@angular/core';
import {
  Validators,
  ValidatorFn,
  AbstractControl,
  FormGroup
} from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ValidationService {
  public static dateFormat = 'YYYY-MM-DD';

  constructor() {}

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      email: 'Invalid email address',
      invalidEmailAddress: 'Invalid email address',
      invalidTelNr: 'Invalid telephone number',
      invalidPassword:
        'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      maxlength: `Maximum length ${validatorValue.requiredLength}`,
      pattern: 'Only alphanumeric characters',
      invalidDateFormat: 'Invalid date',
      minDate: `Can\'t be earlier than ${validatorValue.label}`,
      maxDate: `Can\'t be later than ${validatorValue.label}`,
      notCurrentDate: "Today's date required",
      invalidAmount: 'Invalid amount',
      invalidRate: 'Invalid rate',
      duplicate: `Duplicate ${validatorValue.label}`,
      notGreater: validatorValue.multiplier
        ? `Can't exceed ${validatorValue.label} x${validatorValue.multiplier}`
        : `Can't exceed ${validatorValue.label}`,
      notLess: validatorValue.multiplier
        ? `Can't be less than ${validatorValue.label} x${
            validatorValue.multiplier
          }`
        : `Can't be less than ${validatorValue.label}`,
      invalidCountryCode: 'Invalid Country Code',
      invalidBranchCode: validatorValue.label
        ? validatorValue.label
        : 'Invalid Branch Code',
      notGreaterThanZero: 'Must be greater than 0',
      notNumeric: `${validatorValue.label} can't be numeric`,
      invalidAccountNumber: validatorValue.label
        ? validatorValue.label
        : 'Invalid account number',
      invalidCharacter: `Invalid character/s: ${validatorValue.value}`
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      // RFC 2822 compliant regex
      if (
        control.value.match(
          /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
        )
      ) {
        return null;
      } else {
        return { invalidEmailAddress: { value: control.value } };
      }
    };
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  static checkPasswordMatch(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const pass = group.controls.password.value;
      const confirmPass = group.controls.confirmPassword.value;

      return pass === confirmPass ? null : { noPasswordsMatch: true };
    };
  }

  static alpaNumericValidator() {
    return Validators.pattern('[a-zA-Z0-9_-]*');
  }

  static specialCharactersValidator(): ValidatorFn {
    // VALID CHARS
    // “A” – “Z”	26 capital characters of the Latin alphabet
    // “a” – “z”	26 lowercase characters of the Latin alphabet
    // “0” – “9”	10 Numeric characters
    // “.”	Period
    // “-”	Hyphen
    // “*”	Asterisk
    // “,”	Comma
    // “(“	Left parenthesis
    // “)”	Right parenthesis
    // “%”	Percentage
    // “+”	Plus
    // “$”	Dollar
    // “;”	Semi-colon
    // “=”	Equal
    // “@”	At
    // “?”	Question mark
    // “:”	Colon
    // “~”	Tilda
    // “ “	Space

    const validChars = /[a-zA-Z0-9\.\-\*\,\(\)\%\+\$\;\=\@\?\~ ]*/g;

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const invalidChars = control.value.replace(validChars, '');

      if (invalidChars.length === 0) {
        return null;
      } else {
        return { invalidCharacter: { value: invalidChars } };
      }
    };
  }

  static minMaxValidator(min: number, max: number) {
    return Validators.compose([
      Validators.minLength(min),
      Validators.maxLength(max)
    ]);
  }

  static dateValidator(dateFormat?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = control.value
        ? moment(
            control.value,
            dateFormat || ValidationService.dateFormat,
            true
          ).isValid()
        : true;

      return valid ? null : { invalidDateFormat: { value: control.value } };
    };
  }

  static currentDateValidator(): ValidatorFn {
    const today = moment().format(ValidationService.dateFormat);

    return (control: AbstractControl): { [key: string]: any } => {
      const value = moment(control.value).format(ValidationService.dateFormat);

      const valid = control.value ? value === today : true;

      return valid ? null : { notCurrentDate: { value: control.value } };
    };
  }

  static minDateValidator(
    dateFormat: string = null,
    compareDate: string,
    label?: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const format = dateFormat || ValidationService.dateFormat;
      const compare = moment(compareDate).format(format);
      const value = moment(control.value).format(format);
      const valid = moment(value).isBefore(compare) ? false : true;

      return valid
        ? null
        : { minDate: { value: control.value, label: label || compare } };
    };
  }

  static maxDateValidator(
    dateFormat: string = null,
    compareDate: string,
    label?: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const format = dateFormat || ValidationService.dateFormat;
      const compare = moment(compareDate).format(format);
      const value = moment(control.value).format(format);
      const valid = moment(value).isAfter(compare) ? false : true;

      return valid
        ? null
        : { maxDate: { value: control.value, label: label || compare } };
    };
  }

  static telNrValidator(): ValidatorFn {
    // const telRe = /\+[0-9]{1,3}-[0-9()+\-]{1,30}$/;

    const telRe = /^\+27-[0-9]{9}$/;

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value.match(telRe)) {
        return null;
      } else {
        return { invalidTelNr: { value: control.value } };
      }
    };
  }

  static greaterThanZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (parseFloat(control.value) > 0) {
        return null;
      } else {
        return { notGreaterThanZero: { value: control.value } };
      }
    };
  }

  static amountValidator(): ValidatorFn {
    // const amountRe = /^[0-9]{1,13}\.[0-9]{2}$/;
    const amountRe = /^[0-9]{1,13}(\.[0-9]{1}([0-9]{1})?)?$/;

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value.toString().match(amountRe)) {
        return null;
      } else {
        return { invalidAmount: { value: control.value } };
      }
    };
  }

  static rateValidator(): ValidatorFn {
    const rateRe = /^[0-9]{1,3}\.[0-9]{1,5}$/;

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value.match(rateRe)) {
        return null;
      } else {
        return { invalidRate: { value: control.value } };
      }
    };
  }

  static notGreaterValidator(
    field: string,
    label: string,
    multiplier?: number
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.parent || !control.value) {
        return null;
      }

      const group = control.parent;
      const fieldToCompare = group.get(field);

      if (!fieldToCompare.value) {
        return null;
      }

      const formattedValue = ValidationService.formatDecimalValue(
        Number(fieldToCompare.value * multiplier)
      );

      const isGreaterThan =
        Number(control.value) >
        Number(multiplier ? formattedValue : fieldToCompare.value);

      return isGreaterThan
        ? {
            notGreater: {
              value: control.value,
              label: label,
              ...(multiplier ? { multiplier: multiplier.toString() } : {})
            }
          }
        : null;
    };
  }

  static notLessValidator(
    field: string,
    label: string,
    multiplier?: number
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.parent || !control.value) {
        return null;
      }

      const group = control.parent;
      const fieldToCompare = group.get(field);

      if (!fieldToCompare.value) {
        return null;
      }

      const formattedValue = ValidationService.formatDecimalValue(
        Number(control.value)
      );

      const isLessThan = Number(formattedValue) < Number(fieldToCompare.value);

      return isLessThan
        ? {
            notLess: {
              value: control.value,
              label: label,
              ...(multiplier ? { multiplier: multiplier.toString() } : {})
            }
          }
        : null;
    };
  }

  static notNumericValidator(label?: string): ValidatorFn {
    const re = /(?!^\d+$)^.+$/;

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value.match(re)) {
        return null;
      } else {
        return { notNumeric: { label: label ? label : '' } };
      }
    };
  }

  private static formatDecimalValue(value: string | number): string {
    const decimalPipe = new DecimalPipe('en-ZA');

    return value
      ? decimalPipe.transform(value, '1.2-2').replace(/\,/gi, '')
      : '';
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentChecked,
  ChangeDetectorRef
} from '@angular/core';
import * as moment from 'moment';

import { Fund } from '@ovation/core';
import { ChartComponent } from '@progress/kendo-angular-charts';

@Component({
  selector: 'ovation-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineGraphComponent implements OnInit, OnChanges {
  @ViewChild('chartWrapper') chartWrapper: ElementRef;
  @ViewChild(ChartComponent) chart: ChartComponent;
  @Input() funds: Fund[];
  @Input() chartType: 'scatterLine' | 'line' = 'line';

  public displayFunds: any[];
  public data: any;
  public categories: string[];
  public baseUnit = 'months';

  public majorGridLines = {
    // color: 'lightgray',
    visible: true
  };

  public minorGridLines = {
    // color: 'lightgray',
    visible: true
  };

  public majorTicks = {
    color: 'lightgray',
    visible: false
  };

  public minorTicks = {
    color: 'lightgray'
  };

  public maxDivisions = 10;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.funds) {
      if (this.funds) {
        this.displayFunds = this.funds.map(fund => {
          return {
            title: (<any>fund).title,
            color: (<any>fund).legend.color,
            data: (<any>fund).data.map(item => {
              return {
                ...item,
                dayEndDate: new Date(moment(item.dayEndDate).format())
              };
            })
          };
        });

        console.log(this.displayFunds);
      }
    }
  }

  ngOnInit() {}
}

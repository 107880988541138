import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, tap, skipWhile } from 'rxjs/operators';
import { MortalityFactor, Profile, Pension } from '../models';
import { ProfileModel } from './profile';
import { UtilitiesService } from '../services/utilities.service';

export class Calculations {
  static readonly maxAge = 120;
  static readonly minAge = 16;

  public monthlyPaymentAdjustment$: BehaviorSubject<any> = new BehaviorSubject<
    any
  >(null);
  private initial$: BehaviorSubject<any> = new BehaviorSubject<any>({
    //monthlyPaymentAdjustment: 0,
    guaranteedDiscountFactor: 0,
    timingAdjustor: 0,
    guaranteedAnnuity: 0,
    annuityBeforeExpenses: 0,
    marketInterestRate: 0
  });
  private term$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private profile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(
    null
  );
  private annuityEscalationRate$: BehaviorSubject<any> = new BehaviorSubject<
    any
  >(null);
  private partnerAgeOffset$: Observable<any>;
  private partnerRetirementAge$: Observable<any>;
  private mortalityFactors$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private mortalityBase$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private mortality$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private mortalityAdjustor$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private annuityDivisor$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public contributionsAtRetirement$: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(null);
  public employerContributionsAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(0);
  public employeeContributionsAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(0);
  private taxRelief$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public annualPensionBreakdown$: BehaviorSubject<any> = new BehaviorSubject<
    any
  >(0);
  public taxFreeCash$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public netReplacementRatio$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public contributionBreakdownSummary$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public lumpSumLimitReached$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public contributionsLimitReached$: BehaviorSubject<boolean>;

  public finalSalary$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  get finalSalary() {
    let finalSalary = parseFloat(this.finalSalary$.value);

    const assumptions = this.assumptions$.value;
    const term = this.term$.value;
    const adjustForInflation = this.profileModel.adjustForInflation$.value;

    if (assumptions && term && finalSalary > 0) {
      if (adjustForInflation) {
        const _inflation = parseFloat(assumptions.inflation);
        const _termYears = parseInt(term.years, 10);
        const _termMonths = parseInt(term.months, 10);

        const _years = _termYears + _termMonths / 12;
        const _compoundedInflation = Math.pow(1 + _inflation, _years);

        finalSalary = finalSalary / _compoundedInflation;
      }
    }

    return finalSalary;
  }

  set finalSalary(value: any) {
    this.finalSalary$.next(value);
  }

  public annualPension$: BehaviorSubject<any> = new BehaviorSubject<any>(0);

  get annualPension() {
    const pensions = this.profileModel.pensions$.value;
    const annuityDivisor = this.annuityDivisor$.value;

    return this.calculateAnnualPension(pensions, annuityDivisor);
  }

  set annualPension(value: any) {
    const pensions = this.profileModel.pensions$.value;

    if (pensions && pensions.length > 0) {
      const _initialAnnualPension = parseFloat(this.annualPension$.value);
      // Managed pension is always the first
      const _managedPension = pensions[0];
      const _employeeContributionRate = parseFloat(
        _managedPension.rates.employee
      );

      let _modifiedContributionRate = _employeeContributionRate;
      const CONT_STEP_SIZE = 0.0025;
      const MAX_ITERATIONS = 7;
      const MAX_EMPLOYEE_CONTRIBUTIONRATE = 0.9975;

      let found = false;
      let iterationCount = 0;
      // TODO : disable user interface binding handlers (UI updates disabled)
      while (!found) {
        const _currentAnnualPension = parseFloat(this.annualPension);
        // Difference between the initial annual pension and the current
        const _difference = _initialAnnualPension - _currentAnnualPension;
        // determine how many times the difference goes into making up the total
        // > the target minus the inital, divided by the difference = minimum number of iterations
        // > modify the contribution rate by the contribution step size x number of times
        // > where x is equal to the difference to get to the desired outcome
        const _totalDifference = parseFloat(value) - _currentAnnualPension;
        const _baseIterations = parseInt(
          (_totalDifference / _difference).toFixed(0),
          10
        );

        for (let i = _baseIterations; i < -1; i++) {
          if (_difference < 0) {
            _modifiedContributionRate += CONT_STEP_SIZE;
            if (
              this.utilitiesService.roundToDp(_modifiedContributionRate, 4) >=
              MAX_EMPLOYEE_CONTRIBUTIONRATE
            ) {
              break;
            }
          } else if (_difference > 0) {
            for (let i = _baseIterations; i < -1; i++) {
              _modifiedContributionRate -= CONT_STEP_SIZE;
            }
          }
        }
        // Is the target annual pension is greater than or equal, or less than the current annual pension
        if (value > _currentAnnualPension && _modifiedContributionRate < 1) {
          // add to the employee contribution rate
          _modifiedContributionRate += CONT_STEP_SIZE;
        } else if (value < _currentAnnualPension) {
          // minus from the employee contribution rate
          _modifiedContributionRate -= CONT_STEP_SIZE;
        } else {
          found = true;
        }

        if (
          iterationCount++ >= MAX_ITERATIONS &&
          _currentAnnualPension >= value
        ) {
          found = true;
        }

        if (!found) {
          // trigger a recalculation
          _managedPension.rates.employee(
            this.utilitiesService.roundToDp(_modifiedContributionRate, 4)
          );
          if (_modifiedContributionRate > MAX_EMPLOYEE_CONTRIBUTIONRATE) {
            found = true;
          }
        } else if (_modifiedContributionRate > MAX_EMPLOYEE_CONTRIBUTIONRATE) {
          _managedPension.rates.employee(
            this.utilitiesService.roundToDp(_modifiedContributionRate, 4)
          );
        }
      }
    }
  }

  private assumptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private utilitiesService: UtilitiesService;
  private profileModel$: BehaviorSubject<ProfileModel>;
  private profileModel: ProfileModel;

  constructor(
    profileModel: ProfileModel,
    utilitiesService: UtilitiesService,
    mortalityFactors: any
  ) {
    this.utilitiesService = utilitiesService;

    this.profileModel = profileModel;
    this.profile$.next(profileModel.profile$.value);
    // this.assumptions$.next(profileModel.assumptions$.value);

    this.assumptions$ = profileModel.assumptions$;

    this.mortalityFactors$.next(mortalityFactors);

    this.contributionsLimitReached$ = this.profileModel.contributionsLimitReached$;

    this.profileModel.annualSalary$
      .pipe(skipWhile(val => val === undefined || val === null))
      .subscribe(val => {
        this.finalSalary = val;
      });

    this.profileModel.employerContributionAmount$
      .pipe(skipWhile(val => !val))
      .subscribe(val => {
        this.employerContributionsAmount$.next(val);
      });

    this.profileModel.employeeContributionAmount$
      .pipe(skipWhile(val => !val))
      .subscribe(val => {
        this.employeeContributionsAmount$.next(val);
      });

    this.profileModel.annuity$
      .pipe(skipWhile(val => !val))
      .subscribe(val =>
        this.monthlyPaymentAdjustment$.next(
          parseFloat(val.monthlyPaymentAdjustment)
        )
      );

    this.profileModel.dateOfRetirement$
      .pipe(
        skipWhile(val => !val),
        map(date => {
          const monthsFullTerm = this.utilitiesService.DateDiff.inMonths(
            this.utilitiesService.yearDate(),
            date
          );
          const yearsTerm = Math.floor(monthsFullTerm / 12);

          return {
            monthsFull: monthsFullTerm,
            years: yearsTerm,
            months: monthsFullTerm - yearsTerm * 12
          };
        })
      )
      .subscribe(v => {
        this.term$.next(v);
      });

    // ANNUAL PENSION

    // ANNUITY ESCALATION RATE
    combineLatest(
      this.profileModel.grossInvestmentGrowth$,
      this.assumptions$,
      this.profileModel.annuity$
    )
      .pipe(
        skipWhile(
          ([grossInvestmentGrowth, assumptions, annuity]) =>
            !grossInvestmentGrowth || !assumptions || !annuity
        ),
        map(([grossInvestmentGrowth, assumptions, annuity]) => {
          let result = 1;

          const _annuityGrowth = parseFloat(grossInvestmentGrowth.calcValue);
          const _pensionIncrease = parseFloat(
            annuity.pensionIncrease.calcValue
          );
          const _lowerInvestmentAssumption = parseFloat(
            assumptions.lowerInvestment
          );
          const _midInvestmentAssumption = parseFloat(
            assumptions.midInvestment
          );
          const _additionToYield = parseFloat(assumptions.yieldAdjustment);
          const _ilg0 = parseFloat(assumptions.ilg0);
          const _ilg5 = parseFloat(assumptions.ilg5);
          const _mediumAdjustment = parseFloat(assumptions.mediumAdjustment);
          const _lowAdjustment = parseFloat(assumptions.lowAdjustment);
          const marketInterestRate = (_ilg5 + _ilg0) / 2 + _additionToYield;

          this.initial$.next({
            ...this.initial$.value,
            marketInterestRate
          });

          /*
          Round marketInterestRate to the nearest multiple of 0.2%.
          BUT
          Round down if exact multiple of 0.1%.
          */
          let marketInterestRateRounded;
          if (marketInterestRate % 0.1 === 0) {
            marketInterestRateRounded = Math.floor(marketInterestRate);
          } else {
            marketInterestRateRounded =
              Math.round(marketInterestRate / 0.002) * 0.002;
          }

          //=((MarketInterestRate+0.015)-(MarketInterestRate+0.035))/(Lower-Mid)
          const m =
            (marketInterestRateRounded +
              _lowAdjustment -
              (marketInterestRateRounded + _mediumAdjustment)) /
            (_lowerInvestmentAssumption - _midInvestmentAssumption);

          //=MarketInterestRate+0.015-B17*Lower
          const b =
            marketInterestRateRounded +
            _lowAdjustment -
            m * _lowerInvestmentAssumption;

          result =
            (m * _annuityGrowth + b - _pensionIncrease) /
            (1 + _pensionIncrease);

          result = this.utilitiesService.roundToDp(result, 4);

          return result;
        })
      )
      .subscribe(val => this.annuityEscalationRate$.next(val));

    this.partnerAgeOffset$ = combineLatest(
      this.profileModel.partnerBirthDate$,
      this.profileModel.birthDate$
    ).pipe(
      map(([partnerBirthDate, birthDate]) => {
        let _partnerAgeOffset = 0;

        const _partnerDateOfBirth = partnerBirthDate;
        const _employeeBirthDate = new Date(birthDate);
        _partnerAgeOffset = this.utilitiesService.DateDiff.inYears(
          _partnerDateOfBirth,
          _employeeBirthDate
        );

        return _partnerAgeOffset;
      })
    );

    this.partnerRetirementAge$ = combineLatest(
      this.profileModel.ageOfRetirement$,
      this.partnerAgeOffset$
    ).pipe(
      map(([ageOfRetirement, partnerAgeOffset]) => {
        let _partnerRetirementAge = 0;

        const _retirementAge = ageOfRetirement;
        const _partnerAgeOffset = parseInt(partnerAgeOffset, 10);

        _partnerRetirementAge = _retirementAge - _partnerAgeOffset;

        return _partnerRetirementAge;
      })
    );

    combineLatest(
      this.profileModel.birthYear$,
      this.profileModel.ageOfRetirement$,
      this.profileModel.gender$,
      this.profileModel.partnerBirthDate$,
      this.partnerRetirementAge$,
      this.profileModel.partnerGender$,
      this.profileModel.annuity$,
      this.partnerAgeOffset$
    ).pipe(
      tap(
        ([
          birthYear,
          retirementAge,
          gender,
          partnerBirthDate,
          partnerRetirementAge,
          partnerGender,
          annuity
        ]) => {
          this.profile$.next({
            ...this.profile$.value,
            birthYear,
            retirementAge,
            gender,
            partnerBirthDate,
            partnerRetirementAge,
            partnerGender,
            annuity
          });
        }
      )
    );

    combineLatest(
      this.profile$,
      this.profileModel.birthYear$,
      this.profileModel.ageOfRetirement$,
      this.profileModel.partnerBirthDate$,
      this.partnerRetirementAge$,
      this.mortalityFactors$
    )
      .pipe(
        skipWhile(
          ([
            profile,
            birthYear,
            ageOfRetirement,
            partnerBirthDate,
            partnerRetirementAge,
            mortalityFactors
          ]) =>
            !profile ||
            !birthYear ||
            !ageOfRetirement ||
            !partnerBirthDate ||
            !partnerRetirementAge ||
            !mortalityFactors
        )
      )
      .subscribe(
        ([
          profile,
          birthYear,
          ageOfRetirement,
          partnerBirthDate,
          partnerRetirementAge,
          mortalityFactors
        ]) => {
          const partnerBirthYear = partnerBirthDate.getFullYear();
          const guaranteePeriod = parseInt(profile.annuity.guaranteePeriod, 10);

          this.mortalityBase$.next({
            lx: this.lifeExpectancyFactor(
              mortalityFactors,
              birthYear,
              ageOfRetirement,
              profile.gender
            ),
            ly: this.lifeExpectancyFactor(
              mortalityFactors,
              partnerBirthYear,
              partnerRetirementAge,
              profile.partnerGender
            ),
            lx_plus_g: this.lifeExpectancyFactor(
              mortalityFactors,
              birthYear,
              ageOfRetirement + guaranteePeriod,
              profile.gender
            ),
            ly_plus_g: this.lifeExpectancyFactor(
              mortalityFactors,
              partnerBirthYear,
              partnerRetirementAge + guaranteePeriod,
              profile.partnerGender
            )
          });
        }
      );

    combineLatest(
      this.mortalityFactors$,
      this.mortalityBase$,
      this.annuityEscalationRate$,
      this.profile$,
      this.profileModel.partnerBirthDate$,
      this.profileModel.ageOfRetirement$,
      this.partnerRetirementAge$,
      this.partnerAgeOffset$
    )
      .pipe(
        map(
          ([
            mortalityFactors,
            mortalityBase,
            annuityEscalationRate,
            profile,
            partnerBirthDate,
            ageOfRetirement,
            partnerRetirementAge,
            partnerAgeOffset
          ]) => {
            const _employeeBirthYear = new Date(partnerBirthDate).getFullYear();
            const _guaranteePeriod = parseInt(
              profile.annuity.guaranteePeriod,
              10
            );

            const _retirementAge = ageOfRetirement;
            const _gender = profile.gender;
            const _partnerRetirementAge = partnerRetirementAge;
            const _escalationRate = parseFloat(annuityEscalationRate);
            const _partnerAgeOffset = parseInt(partnerAgeOffset, 10);
            const _partnerBirthYear = partnerBirthDate.getFullYear();
            const partnerGender = profile.partnerGender;

            /* AX_PLUS_G */
            let ax_plus_g = 1;

            if (annuityEscalationRate && mortalityBase) {
              let nx = 0; // n as 'variable'
              let dx = 0; // discount factor
              const startAge = Math.min(
                _retirementAge + _guaranteePeriod,
                _partnerRetirementAge + _guaranteePeriod
              );

              for (let i = startAge; i <= Calculations.maxAge; i++) {
                const isRetired = i >= _retirementAge + _guaranteePeriod;
                const isRetiredYear = i === _retirementAge + _guaranteePeriod;

                if (isRetired || isRetiredYear) {
                  const vx_temp = 1 / Math.pow(1 + _escalationRate, i);
                  const lx_temp = this.lifeExpectancyFactor(
                    mortalityFactors,
                    _employeeBirthYear,
                    i,
                    _gender
                  );
                  const dx_temp = vx_temp * lx_temp;

                  if (isRetired) {
                    nx += dx_temp;
                  }
                  if (isRetiredYear) {
                    dx = dx_temp;
                  }
                }
              }

              nx = Math.round(nx * 1000000) / 1000000;
              // support very large and very small decimal points
              let _dx = 0;
              let multiplier_dx = 1000000;

              while (_dx === 0) {
                _dx = Math.round(dx * multiplier_dx) / multiplier_dx;
                if (_dx === 0) {
                  multiplier_dx = multiplier_dx * 10;
                }
              }
              dx = _dx || 0; //Math.round(dx * 1000000) / 1000000;

              ax_plus_g = (nx / dx || 0) - this.monthlyPaymentAdjustment$.value;
            }

            /* AY_PLUS_G */
            let ay_plus_g = 1;
            let nx_partner = 0;
            let dx_partner = 0;

            const startAge_partner = Math.min(
              _retirementAge + _guaranteePeriod,
              _partnerRetirementAge + _guaranteePeriod
            );

            for (let i = startAge_partner; i <= Calculations.maxAge; i++) {
              const isNotRetired =
                i + _partnerAgeOffset <= Calculations.maxAge &&
                i + _partnerAgeOffset >= Calculations.minAge;
              const isRetired = i >= _partnerRetirementAge + _guaranteePeriod;
              const isRetiredYear =
                i === _partnerRetirementAge + _guaranteePeriod;

              if (isNotRetired || isRetired || isRetiredYear) {
                const vx_temp = 1 / Math.pow(1 + _escalationRate, i);
                const lx_partner_temp = this.lifeExpectancyFactor(
                  mortalityFactors,
                  _partnerBirthYear,
                  i,
                  partnerGender
                );

                let ly_temp = 0;

                if (isNotRetired) {
                  if (_partnerAgeOffset >= 0) {
                    ly_temp = this.lifeExpectancyFactor(
                      mortalityFactors,
                      _partnerBirthYear,
                      i - _partnerAgeOffset,
                      partnerGender
                    );
                  } else {
                    const y = i - _partnerAgeOffset;
                    ly_temp = this.lifeExpectancyFactor(
                      mortalityFactors,
                      _partnerBirthYear,
                      y > Calculations.maxAge ? Calculations.maxAge : y,
                      partnerGender
                    );
                  }
                }

                const dx_partner_temp = vx_temp * lx_partner_temp;

                if (isRetired) {
                  nx_partner += dx_partner_temp;
                }

                if (isRetiredYear) {
                  dx_partner = dx_partner_temp;
                }
              }
            }

            nx_partner = Math.round(nx_partner * 1000000) / 1000000;
            // support very large and very small decimal points
            let _dx_partner = 0;
            let multiplier_dx_partner = 1000000;

            while (_dx_partner === 0) {
              _dx_partner =
                Math.round(dx_partner * multiplier_dx_partner) /
                multiplier_dx_partner;
              if (_dx_partner === 0) {
                multiplier_dx_partner = multiplier_dx_partner * 10;
              }
            }
            dx_partner = _dx_partner; //Math.round(dx_partner * 1000000) / 1000000;

            ay_plus_g =
              nx_partner / dx_partner - this.monthlyPaymentAdjustment$.value;

            /* AX_PLUS_G_Y_PLUS_G */
            let ax_plus_g_y_plus_g = 1;

            if (annuityEscalationRate) {
              let nxy = 0;
              let dxy = 0;

              const startAge = Math.min(
                _retirementAge + _guaranteePeriod,
                _partnerRetirementAge + _guaranteePeriod
              );

              for (let i = startAge; i <= Calculations.maxAge; i++) {
                const isNotRetired =
                  i + _partnerAgeOffset <= Calculations.maxAge &&
                  i + _partnerAgeOffset >= Calculations.minAge;
                const isRetired = i >= _retirementAge + _guaranteePeriod;
                const isRetiredYear = i === _retirementAge + _guaranteePeriod;

                if (isNotRetired || isRetired || isRetiredYear) {
                  const vx_temp = 1 / Math.pow(1 + _escalationRate, i);
                  const lx_temp = this.lifeExpectancyFactor(
                    mortalityFactors,
                    _employeeBirthYear,
                    i,
                    _gender
                  );

                  let ly_temp = 0;
                  if (isNotRetired) {
                    if (_partnerAgeOffset >= 0) {
                      ly_temp = this.lifeExpectancyFactor(
                        mortalityFactors,
                        _partnerBirthYear,
                        i - _partnerAgeOffset,
                        partnerGender
                      );
                    } else {
                      const y = i - _partnerAgeOffset;
                      ly_temp = this.lifeExpectancyFactor(
                        mortalityFactors,
                        _partnerBirthYear,
                        y > Calculations.maxAge ? Calculations.maxAge : y,
                        partnerGender
                      );
                    }
                  }

                  const lxy_temp = lx_temp * ly_temp;

                  if (isRetired) {
                    const dxy_temp = lxy_temp * vx_temp;
                    nxy += dxy_temp;
                  }
                  if (isRetiredYear) {
                    dxy = lxy_temp * vx_temp;
                  }
                }
              }
              nxy = Math.round(nxy * 1000000) / 1000000;

              ax_plus_g_y_plus_g =
                nxy / dxy - this.monthlyPaymentAdjustment$.value;
            }

            return {
              lxy: mortalityBase ? mortalityBase.lx * mortalityBase.ly : 0,
              lx_plus_g_y_plus_g: mortalityBase
                ? mortalityBase.lx_plus_g * mortalityBase.ly_plus_g
                : 0,
              // annuity expectancy
              ax_plus_g,
              ay_plus_g,
              ax_plus_g_y_plus_g
            };
          }
        )
      )
      .subscribe(mortality => this.mortality$.next(mortality));

    combineLatest(this.mortalityBase$, this.mortality$)
      .pipe(
        tap(([mortalityBase, mortality]) => {
          let _mortalityAdjustor = 0;
          if (mortalityBase && mortality) {
            _mortalityAdjustor =
              (parseFloat(mortalityBase.lx_plus_g) *
                parseFloat(mortalityBase.ly_plus_g)) /
              parseFloat(mortality.lxy);
          }

          this.mortalityAdjustor$.next(_mortalityAdjustor);
        })
      )
      .subscribe();

    /* annuityDivisor$ */
    combineLatest(
      this.mortalityBase$,
      this.mortality$,
      this.annuityEscalationRate$,
      this.assumptions$,
      this.mortalityAdjustor$,
      this.profile$
    )
      .pipe(
        skipWhile(
          ([
            mortalityBase,
            mortality,
            annuityEscalationRate,
            assumptions,
            mortalityAdjustor,
            profile
          ]) =>
            mortalityBase === null ||
            mortalityBase === undefined ||
            mortality === null ||
            mortality === undefined ||
            annuityEscalationRate === null ||
            annuityEscalationRate === undefined ||
            assumptions === null ||
            assumptions === undefined ||
            mortalityAdjustor === null ||
            mortalityAdjustor === undefined ||
            profile === null ||
            profile === undefined
        ),
        map(
          ([
            mortalityBase,
            mortality,
            annuityEscalationRate,
            assumptions,
            mortalityAdjustor,
            profile
          ]) => {
            let annuityAfterExpenses = 1;

            if (annuityEscalationRate && mortality) {
              const _annuityOptions = profile.annuity;
              const _escalationRate = parseFloat(annuityEscalationRate);
              const _annuityExpenseAssumption = parseFloat(
                assumptions.annuityExpense
              );
              const _guaranteedPeriod = parseInt(
                _annuityOptions.guaranteePeriod,
                10
              );
              const _partnerPercentage = parseFloat(
                _annuityOptions.partnerPercentage
              );

              const adjustorJoint =
                parseFloat(mortality.ax_plus_g) +
                _partnerPercentage *
                  (parseFloat(mortality.ay_plus_g) -
                    parseFloat(mortality.ax_plus_g_y_plus_g));

              const timingAdjustor =
                (1 - 1 / Math.pow(1 + _escalationRate, 1 / 12)) * 12;
              this.initial$.next({
                ...this.initial$.value,
                timingAdjustor
              });

              const guaranteedAnnuity =
                (1 - 1 / Math.pow(1 + _escalationRate, _guaranteedPeriod)) /
                timingAdjustor;
              this.initial$.next({
                ...this.initial$.value,
                guaranteedAnnuity
              });

              const guaranteeDiscountFactor =
                1 / Math.pow(1 + _escalationRate, _guaranteedPeriod);
              this.initial$.next({
                ...this.initial$.value,
                guaranteeDiscountFactor
              });

              const muladj1 = adjustorJoint * parseFloat(mortalityAdjustor);
              const _ax_plus_g = parseFloat(mortality.ax_plus_g);
              const _ay_plus_g = parseFloat(mortality.ay_plus_g);
              const _lx_plus_g = parseFloat(mortalityBase.lx_plus_g);
              const _ly_plus_g = parseFloat(mortalityBase.ly_plus_g);
              const _lx = parseFloat(mortalityBase.lx);
              const _ly = parseFloat(mortalityBase.ly);

              const muladj2 =
                (_lx_plus_g / _lx) * (1 - _ly_plus_g / _ly) * _ax_plus_g;
              const muladj3 =
                (_ly_plus_g / _ly) *
                (1 - _lx_plus_g / _lx) *
                _ay_plus_g *
                _partnerPercentage;

              const totalAnnuity = muladj1 + muladj2 + muladj3;
              const annuityBeforeExpenses =
                totalAnnuity * guaranteeDiscountFactor + guaranteedAnnuity;

              this.initial$.next({
                ...this.initial$.value,
                annuityBeforeExpenses
              });

              annuityAfterExpenses =
                annuityBeforeExpenses * (1 + _annuityExpenseAssumption);
            }

            this.annuityDivisor$.next(annuityAfterExpenses);

            return annuityAfterExpenses;
          }
        )
      )
      .subscribe();

    /** contributionsAtRetirement$ */
    combineLatest(
      this.profileModel.pensions$,
      this.profileModel.managedPension$.value.employeeRate$
    )
      .pipe(skipWhile(([pensions]) => !pensions || !pensions.length))
      .subscribe(([pensions]) => {



        let _projectedFundValue = 0;

        if (pensions && pensions.length > 0) {
          _projectedFundValue = pensions.reduce((acc, curr) => {
            // console.log('pension finalValuation', curr.finalValuation);

            return acc + curr.finalValuation;
          }, 0);
        }

        // console.log('contributionsAtRetirement', _projectedFundValue);

        this.contributionsAtRetirement$.next(_projectedFundValue);
      });

    // annuityDivisor
    combineLatest(
      this.profileModel.pensions$,
      this.annuityDivisor$,
      this.taxFreeCash$
    )
      .pipe(
        skipWhile(
          ([pensions, annuityDivisor, taxFreeCash]) =>
            !pensions ||
            !pensions.length ||
            annuityDivisor === undefined ||
            annuityDivisor === null ||
            taxFreeCash === null ||
            taxFreeCash === undefined
        )
      )
      .subscribe(([pensions, annuityDivisor]) => {
        this.annualPension$.next(
          this.calculateAnnualPension(pensions, annuityDivisor)
        );
      });

    /** taxRelief$ */
    combineLatest(this.assumptions$, this.employeeContributionsAmount$)
      .pipe(
        tap(([assumptions, employeeContributionsAmount]) => {
          let _taxRelief = 0;

          if (assumptions) {
            const _taxReliefRate = assumptions.taxRelief;
            const _grossEmployeeContribution = employeeContributionsAmount;

            _taxRelief = _grossEmployeeContribution * _taxReliefRate;
          }

          this.taxRelief$.next(_taxRelief);
        })
      )
      .subscribe();

    /** annualPensionBreakdown$ */
    this.annualPensionBreakdown$.next({
      managed: 100,
      unmanaged: 0
    });

    /** taxFreeCash$ */
    combineLatest(
      this.assumptions$,
      this.profileModel.pensions$,
      this.contributionsAtRetirement$
    )
      .pipe(
        skipWhile(
          ([assumptions, pensions, contributionsAtRetirement]) =>
            !assumptions ||
            !pensions ||
            contributionsAtRetirement === undefined ||
            contributionsAtRetirement === null
        )
      )
      .subscribe(([assumptions, pensions, contributionsAtRetirement]) => {
        let mainFundCashAmount = 0;

        if (pensions && pensions.length > 0) {
          const _tfcPercentage = assumptions.taxFreeCashPercentage;
          const _projectedFundValue = contributionsAtRetirement;

          if (_tfcPercentage > 0 && _projectedFundValue > 0) {
            mainFundCashAmount = _tfcPercentage * _projectedFundValue;
          }
        }

        this.taxFreeCash$.next(mainFundCashAmount);
      });

    /** netReplacementRatio$ */
    combineLatest(this.finalSalary$, this.annualPension$).pipe(
      tap(([finalSalary, annualPension]) => {
        this.netReplacementRatio$.next(annualPension / finalSalary);
      })
    );

    /** contributionBreakdownSummary$ */
    combineLatest(
      this.contributionsAtRetirement$,
      this.taxRelief$,
      this.employerContributionsAmount$
    )
      .pipe(
        tap(values => {
          this.contributionBreakdownSummary$.next(values.join(','));
        })
      )
      .subscribe();

    /** lumpSumLimitReached$ */
    combineLatest(this.assumptions$, this.contributionsAtRetirement$)
      .pipe(
        skipWhile(
          ([assumptions, contributionsAtRetirement]) =>
            !assumptions ||
            contributionsAtRetirement === undefined ||
            contributionsAtRetirement === null
        )
      )
      .subscribe(([assumptions, contributionsAtRetirement]) => {
        const region = this.profileModel.region;
        this.lumpSumLimitReached$.next(
          assumptions.taxFreeCashPercentage * contributionsAtRetirement >
            region.limits.lumpSumAmountLimit
        );
      });
  }

  private lifeExpectancyFactor(
    mortalityFactors: any[],
    birthYear: number,
    retirementAge: number,
    gender: string
  ): any {

    const mortalityFactor = mortalityFactors.find(mortalityItem => {
      const mortalityItemYear = parseInt(mortalityItem.year, 10);
      return mortalityItemYear === birthYear && mortalityItem.gender === gender;
    });

    const ageFactor =
      mortalityFactor && mortalityFactor.factors
        ? mortalityFactor.factors.find((factorItem: MortalityFactor) => {
            return parseInt(factorItem.age, 10) === retirementAge;
          })
        : null;

    if (!ageFactor || !ageFactor.lx) {
      for (let age = Calculations.minAge; age <= retirementAge; age++) {
        const currentAgeFactor = mortalityFactor.factors.find(
          (factorItem: MortalityFactor) => {
            // console.log('factorItem.age', factorItem.age, age);
            return parseInt(factorItem.age, 10) === age;
          }
        );

        // console.log('currentAgeFactor', currentAgeFactor);

        if (currentAgeFactor) {
          if (age === Calculations.minAge) {
            currentAgeFactor['lx'] = 100000;
          } else {
            const previousAgeFactor = mortalityFactor.factors.find(
              (factorItem: MortalityFactor) => {
                return parseInt(factorItem.age, 10) === age - 1;
              }
            );
            let lx = previousAgeFactor.lx;

            if (previousAgeFactor.value !== 0) {
              lx =
                (1 - previousAgeFactor.value) *
                (previousAgeFactor['lx'] === undefined
                  ? 100000
                  : previousAgeFactor.lx);
              currentAgeFactor['lx'] = Math.round(lx * 1000000) / 1000000;
            }
            currentAgeFactor['lx'] = lx;
          }
        }
      }
    }
    return ageFactor.lx;
  }

  private calculateAnnualPension(pensions: Pension[], annuityDivisor: any) {
    let _annualPension = 0;

    if (pensions && pensions.length > 0) {
      const _annuityDivisor = annuityDivisor;
      const _taxFreeCash = this.taxFreeCash$.value;
      const _projectedFundValue = this.contributionsAtRetirement$.value;
      const _mainFundCommuted = _projectedFundValue - _taxFreeCash;

      _annualPension = _mainFundCommuted / _annuityDivisor;

      // update annualPensionBreakdown % of total pension
      let _managedPension = 1.0;

      pensions.forEach(pensionItem => {
        const _pensionValuation = pensionItem.finalValuation;
        const _percentageOfAnnualPension =
          _pensionValuation / _projectedFundValue;

        if (pensionItem.managed) {
          // only ever a single managed pension
          _managedPension = _percentageOfAnnualPension;
          return false;
        }
      });

      this.annualPensionBreakdown$.next({
        managed: _managedPension,
        unmanaged: 1 - _managedPension
      });
    }
    return _annualPension;
  }
}

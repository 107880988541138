import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
  HostBinding,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import PopperJs from 'popper.js';

import { SliderComponent, TickConfig, SliderChange } from '@ovation/slider';

@Component({
  selector: 'ovation-tax-relief-slider',
  templateUrl: './tax-relief-slider.component.html',
  styleUrls: ['./tax-relief-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TaxReliefSliderComponent
  implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private decimalPipe: DecimalPipe
  ) {}
  public tickConfig: TickConfig[] = [];

  @HostBinding('class.ovn-tax-relief-slider') class = 'ovn-tax-relief-slider';
  @ViewChild(SliderComponent) baseSlider: SliderComponent;
  /** Reference to the inner slider wrapper element. */
  @ViewChild('sliderWrapper')
  private sliderWrapper: ElementRef;

  @ViewChild('popperRef') private popperRef: ElementRef;
  @ViewChild('popper') private popper: ElementRef;

  @Input() value: number = 0;
  @Input() displayAmount: number = 0;
  @Input() showPopper: boolean = true;
  @Input() showTicksSuffix: boolean = true;

  /** Event emitted when the slider value has changed. */
  @Output() readonly change: EventEmitter<SliderChange> = new EventEmitter<
    SliderChange
  >();

  public max = 27.5;
  private popperJs: PopperJs;

  @HostBinding('class.ovn-tax-relief-slider-popper')
  get hasPopper() {
    return this.showPopper;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.tickConfig = [
      {
        value: 0,
        label: '0' + (this.showTicksSuffix ? '%' : '')
      },
      {
        value: this.max,
        label: this.max + (this.showTicksSuffix ? '%' : '')
      }
    ];

    if (changes.value) {
      if (this.value < this.max && this.value > 0) {
        this.tickConfig = [
          ...this.tickConfig.slice(0, 1),
          {
            value: this.value,
            label: this.decimalPipe.transform(this.value, '1.0-0') + '%'
          },
          // Need to hide last tick if value is close to max
          ...(this.value < this.max - 6 ? [...this.tickConfig.slice(1)] : [])
        ];

        this.cd.detectChanges();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.showPopper) {
      return;
    }

    this.popperJs = new PopperJs(
      this.popperRef.nativeElement,
      this.popper.nativeElement,
      {
        placement: 'top',
        modifiers: {
          flip: {
            behavior: ['left', 'right'],
            padding: 20,
            boundariesElement: this.el.nativeElement
          },
          preventOverflow: {
            boundariesElement: this.el.nativeElement,
            priority: ['right', 'left']
          }
        }
      }
    );

    this.updateLabelPosition();
    /** Emit initial value */
    this.change.emit({
      source: this.baseSlider,
      value: this.baseSlider.value
    });
  }

  public formatLabelDisplay(value: number | null) {
    if (!value) {
      return 0;
    }

    if (this.tickConfig) {
      return this.tickConfig.find(item => item.value === value);
    }

    return value;
  }

  public sliderThumbMoves(event: any) {
    this.updateLabelPosition();
  }

  public updateLabelPosition() {
    if (!this.showPopper) {
      return;
    }

    setTimeout(() => {
      this.popperJs.scheduleUpdate();
      this.cd.detectChanges();
    }, 300);
  }

  public valueChanges(event: SliderChange) {
    this.updateLabelPosition();
    this.change.emit(event);
  }
}

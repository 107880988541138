export const TAXRATES = [
  {
    earnings: '0',
    value: '0.18'
  },
  {
    earnings: '165600',
    value: '0.25'
  },
  {
    earnings: '258750',
    value: '0.30'
  },
  {
    earnings: '358110',
    value: '0.35'
  },
  {
    earnings: '500940',
    value: '0.38'
  },
  {
    earnings: '638600',
    value: '0.40'
  }
];

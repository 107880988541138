import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom, tap, skipWhile, take } from 'rxjs/operators';

import {
  Scheme,
  Profile,
  Pension,
  ProfileModel,
  Region,
  Calculations
} from '../models';
import { UtilitiesService } from './utilities.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profile$: Observable<Profile>;

  private schemes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private region$: Observable<any>;
  private taxRates$: Observable<any>;
  private fullRangePercentageOptions$: BehaviorSubject<
    any[]
  > = new BehaviorSubject<any>(null);
  private grossInvestmentReturn$: Observable<any[]>;
  private pensionIncreaseOptions$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private guaranteePeriodOptions$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private partnerPercentageOptions$: BehaviorSubject<
    any[]
  > = new BehaviorSubject<any[]>(null);

  public selectedGrossInvestmentGrowth$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);

  public managedPension$: BehaviorSubject<Pension> = new BehaviorSubject<
    Pension
  >(null);
  private _managedPension: Pension;
  public get managedPension(): Pension {
    return this._managedPension;
  }
  public set managedPension(value: Pension) {
    this._managedPension = value;
    this.managedPension$.next(this._managedPension);
  }

  public employeeContributionRateManaged$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public totalUnmanagedContribution$: Observable<any>;
  public totalPensionsValue$: Observable<any>;
  public managedPensions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public unmanagedPensions$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);

  private fullRangePercentageOptionsQuaterInc$: BehaviorSubject<
    any[]
  > = new BehaviorSubject<any[]>(null);

  public employerContributionAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public employeeContributionAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);

  public contributionsLimitReached$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  ///////////////////////////////////////////////////////////////////////////////////////
  public currentProfile: ProfileModel;
  public modelledProfile: ProfileModel;
  public currentProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<
    ProfileModel
  >(null);
  public modelledProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<
    ProfileModel
  >(null);
  public activeProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<
    ProfileModel
  >(null);

  constructor(
    private utilitiesService: UtilitiesService,
    private dataService: DataService
  ) {
    // this.profile$ = this.dataService.getProfile(1);
    // TODO : make a copy of the profile (which will be known as the modified profile)

    this.region$ = this.dataService.getRegion(1);
    this.taxRates$ = this.dataService.getTaxRates();
    this.dataService
      .getFullRangePercentageOptions()
      .pipe(take(1))
      .subscribe(val => this.fullRangePercentageOptions$.next(val));

    this.dataService
      .getPensionIncreaseOptions()
      .pipe(take(1))
      .subscribe(val => this.pensionIncreaseOptions$.next(val));

    this.dataService
      .getGuaranteePeriodOptions()
      .pipe(take(1))
      .subscribe(val => this.guaranteePeriodOptions$.next(val));

    this.dataService
      .getPartnerPercentageOptions()
      .pipe(take(1))
      .subscribe(val => this.partnerPercentageOptions$.next(val));

    this.dataService
      .getFullRangePercentageOptionsQuaterInc()
      .pipe(take(1))
      .subscribe(val => this.fullRangePercentageOptionsQuaterInc$.next(val));
  }

  public addProfile(
    profile: Profile,
    region: Region,
    taxRates: any,
    mortalityFactors: any
  ) {
    this.currentProfile = new ProfileModel(
      profile,
      region,
      taxRates,
      this.utilitiesService,
      this.fullRangePercentageOptions$,
      this.fullRangePercentageOptionsQuaterInc$,
      this.pensionIncreaseOptions$,
      this.guaranteePeriodOptions$,
      this.partnerPercentageOptions$
    );

    this.currentProfile.calculations = new Calculations(
      this.currentProfile,
      this.utilitiesService,
      mortalityFactors
    );

    this.modelledProfile = new ProfileModel(
      profile,
      region,
      taxRates,
      this.utilitiesService,
      this.fullRangePercentageOptions$,
      this.fullRangePercentageOptionsQuaterInc$,
      this.pensionIncreaseOptions$,
      this.guaranteePeriodOptions$,
      this.partnerPercentageOptions$
    );

    this.modelledProfile.calculations = new Calculations(
      this.modelledProfile,
      this.utilitiesService,
      mortalityFactors
    );

    this.currentProfile$.next(this.currentProfile);
    this.modelledProfile$.next(this.modelledProfile);

    this.currentProfile.update.subscribe(val => this.currentProfile$.next(val));
    this.modelledProfile.update.subscribe(val => {
      console.log('this.modelledProfile.update', val);
      this.modelledProfile$.next(val);
    });

    this.updateActiveProfile();
  }

  // public addTempPension(pensionItem: Scheme) {
  //   const _pension = new Pension(pensionItem);
  //   return _pension;
  // }

  public getProfile(): BehaviorSubject<ProfileModel> {
    return this.modelledProfile$;
  }

  public updateActiveProfile(): void {
    this.modelledProfile$.next(this.modelledProfile);

    this.activeProfile$.next(this.modelledProfile);

    this.managedPension = this.modelledProfile.managedPension$.value;
  }
}

import { Component, OnInit } from '@angular/core';
import { FundCardComponent } from '../fund-card/fund-card.component';

@Component({
  selector: 'ovation-fund-card-compact',
  templateUrl: './fund-card-compact.component.html',
  styleUrls: [
    '../fund-card/fund-card.component.scss',
    './fund-card-compact.component.scss'
  ]
})
export class FundCardCompactComponent extends FundCardComponent {
  constructor() {
    super();
  }
}

export const RISKS = [
  {
    value: 1,
    label: 'Scaredy cat',
    strapLine:
      "Who's afraid of the big bad market? You are. And you have no investment clue",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar elit sed ante vestibulum pellentesque. Quisque bibendum massa neque, eget eleifend turpis condimentum vel.',
    iconPath: 'assets/svg/scaredy-cat.svg'
  },
  {
    value: 2,
    label: 'Kinda curious',
    strapLine:
      "Who's afraid of the big bad market? You are. And you have no investment clue",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar elit sed ante vestibulum pellentesque. Quisque bibendum massa neque, eget eleifend turpis condimentum vel.',
    iconPath: 'assets/svg/kinda-curious.svg'
  },
  {
    value: 3,
    label: 'Regular Joe',
    strapLine:
      "Who's afraid of the big bad market? You are. And you have no investment clue",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar elit sed ante vestibulum pellentesque. Quisque bibendum massa neque, eget eleifend turpis condimentum vel.',
    iconPath: 'assets/svg/regular-joe.svg'
  },
  {
    value: 4,
    label: 'Adventurer',
    strapLine:
      "Who's afraid of the big bad market? You are. And you have no investment clue",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar elit sed ante vestibulum pellentesque. Quisque bibendum massa neque, eget eleifend turpis condimentum vel.',
    iconPath: 'assets/svg/adventurer.svg'
  },
  {
    value: 5,
    label: 'Hungry for wealth',
    strapLine:
      "Who's afraid of the big bad market? You are. And you have no investment clue",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar elit sed ante vestibulum pellentesque. Quisque bibendum massa neque, eget eleifend turpis condimentum vel.',
    iconPath: 'assets/svg/hungry-for-wealth.svg'
  }
];

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ApiModule as EmployeeApiModule } from '@ovation/api.employee';
import { CoreModule, DataServiceMock } from '@ovation/core';
import { SliderModule } from '@ovation/slider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SliderModule,
    EmployeeApiModule,
    environment.mock
      ? HttpClientInMemoryWebApiModule.forRoot(DataServiceMock, {
          apiBase: 'api',
          delay: 100,
          passThruUnknownUrl: true,
          put204: false
        })
      : []
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en-ZA' }],
  bootstrap: [AppComponent]
})
export class AppModule {}

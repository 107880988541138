import { Benefit, CoverLevel } from '../../models';

export const medicalCoverLevels = [
  {
    id: 1,
    levelName: 'Executive Plan',
    levelDescription: '',
    coverAmount: 0,
    youPay: 7257
  },
  {
    id: 2,
    levelName: 'Classic Comprehensive',
    levelDescription: '',
    coverAmount: 0,
    youPay: 5954
  },
  {
    id: 3,
    levelName: 'Classic Delta Comprehensive',
    levelDescription: '',
    coverAmount: 0,
    youPay: 5362
  },
  {
    id: 4,
    levelName: 'Essential Comprehensive',
    levelDescription: '',
    coverAmount: 0,
    youPay: 5077
  },
  {
    id: 5,
    levelName: 'Essential Delta Comprehensive',
    levelDescription: '',
    coverAmount: 0,
    youPay: 4507
  },
  {
    id: 6,
    levelName: 'Classic Smart Comproehensive (No MSA)',
    levelDescription: '',
    coverAmount: 0,
    youPay: 4327
  },
  {
    id: 7,
    levelName: 'Classic Priority',
    levelDescription: '',
    coverAmount: 0,
    youPay: 3994
  },
  {
    id: 8,
    levelName: 'Essential Priority',
    levelDescription: '',
    coverAmount: 0,
    youPay: 3278
  },
  {
    id: 9,
    levelName: 'Classic Saver',
    levelDescription: '',
    coverAmount: 0,
    youPay: 3290
  },
  {
    id: 10,
    levelName: 'Classic Delta Saver',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2628
  },
  {
    id: 11,
    levelName: 'Essential Saver',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2615
  },
  {
    id: 12,
    levelName: 'Essential Delta Saver',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2085
  },
  {
    id: 13,
    levelName: 'Coastal Saver',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2608
  },
  {
    id: 14,
    levelName: 'Classic Smart (No MSA)',
    levelDescription: '',
    coverAmount: 0,
    youPay: 1954
  },
  {
    id: 15,
    levelName: 'Essential Smart (No MSA)',
    levelDescription: '',
    coverAmount: 0,
    youPay: 1400
  },
  {
    id: 16,
    levelName: 'Classic Core',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2449
  },
  {
    id: 17,
    levelName: 'Classic Delta Core',
    levelDescription: '',
    coverAmount: 0,
    youPay: 1960
  },
  {
    id: 18,
    levelName: 'Essential Core',
    levelDescription: '',
    coverAmount: 0,
    youPay: 2104
  },
  {
    id: 19,
    levelName: 'Essential Delta Core',
    levelDescription: '',
    coverAmount: 0,
    youPay: 1681
  },
  {
    id: 20,
    levelName: 'Coastal Core',
    levelDescription: '',
    coverAmount: 0,
    youPay: 1946
  }
];

export const BENEFITS: Benefit[] = [
  {
    id: 1,
    benefitIcon: '/assets/svg/first-aid-kit.svg',
    benefitIconColor: 'green-3',
    benefitName: 'Disability Cover',
    benefitDescription: 'Automatically enrolled',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 2279.53,
    coverAmount: 3039.37,
    taxableAmount: 227.95,
    youPay: 569.88,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 2,
    benefitIcon: '/assets/svg/contributions-01.svg',
    benefitIconColor: 'green-3',
    benefitName: 'Gap Cover',
    benefitDescription: 'Automatically enrolled',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 3779.53,
    coverAmount: 5039.37,
    taxableAmount: 377.95,
    youPay: 944.88,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 3,
    benefitIcon: '/assets/svg/coin.svg',
    benefitIconColor: 'green-3',
    benefitName: 'Retirement Plan',
    benefitDescription: 'Automatically enrolled',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 1529.53,
    coverAmount: 2039.37,
    taxableAmount: 152.95,
    youPay: 382.38,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: 'PEN'
  },
  {
    id: 4,
    benefitIcon: '/assets/svg/income-protection.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Income Protection',
    benefitDescription: 'Automatically enrolled',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 3029.53,
    coverAmount: 4039.37,
    taxableAmount: 302.95,
    youPay: 757.38,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 5,
    benefitIcon: '/assets/svg/car-wash.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Car Wash',
    benefitDescription: 'R200 per month 3x car washes',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 225.0,
    coverAmount: 300.0,
    taxableAmount: 22.5,
    youPay: 56.25,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 6,
    benefitIcon: '/assets/svg/child-care.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Charity Giving',
    benefitDescription: 'Donate tax-free to any charity in SA',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 1125.36,
    coverAmount: 1500.48,
    taxableAmount: 112.54,
    youPay: 281.34,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 7,
    benefitIcon: '/assets/svg/first-aid-kit.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Discovery Medical Scheme',
    benefitDescription: '',
    coverLevels: medicalCoverLevels,
    levelId: 0,
    levelName: '',
    levelPercentage: 0,
    levelAmount: 0,
    coverAmount: 0,
    taxableAmount: 0,
    youPay: 0,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: 'MED'
  },
  {
    id: 8,
    benefitIcon: '/assets/svg/car-steeringwheel.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Travel Allowance',
    benefitDescription: 'Reimbursement for company travel',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 1500.0,
    coverAmount: 2000.0,
    taxableAmount: 150.0,
    youPay: 375.0,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 9,
    benefitIcon: '/assets/svg/graduation-hat-front-view.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Study Loan',
    benefitDescription: 'Relating to company',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 15000.0,
    coverAmount: 20000.0,
    taxableAmount: 1500.0,
    youPay: 3750.0,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 10,
    benefitIcon: '/assets/svg/graph.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Employee Share Option Scheme',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 570.0,
    coverAmount: 760.0,
    taxableAmount: 57.0,
    youPay: 142.5,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 11,
    benefitIcon: '/assets/svg/bandage.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Group Personal Accident',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 1087.5,
    coverAmount: 1450.0,
    taxableAmount: 108.75,
    youPay: 271.88,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 12,
    benefitIcon: '/assets/svg/parked-car.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Parking',
    benefitDescription: '50% subsidy for Gautrain users',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 900.0,
    coverAmount: 1200.0,
    taxableAmount: 90.0,
    youPay: 225.0,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 13,
    benefitIcon: '/assets/svg/flower.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Wellness Program',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 600.0,
    coverAmount: 800.0,
    taxableAmount: 60.0,
    youPay: 150.0,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 14,
    benefitIcon: '/assets/svg/ticket.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Computer Allowance',
    benefitDescription: '50% subsidy',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 262.5,
    coverAmount: 350.0,
    taxableAmount: 26.25,
    youPay: 65.63,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 15,
    benefitIcon: '/assets/svg/washing-machine.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Appliances',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 337.5,
    coverAmount: 450.0,
    taxableAmount: 33.75,
    youPay: 84.38,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 16,
    benefitIcon: '/assets/svg/clock.svg',
    benefitIconColor: 'blue-1',
    benefitName: '4 PM Finish Friday',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 150.0,
    coverAmount: 200.0,
    taxableAmount: 15.0,
    youPay: 37.5,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 17,
    benefitIcon: '/assets/svg/air-transport.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Air Miles',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 750.0,
    coverAmount: 1000.0,
    taxableAmount: 75.0,
    youPay: 187.5,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  },
  {
    id: 18,
    benefitIcon: '/assets/svg/book.svg',
    benefitIconColor: 'blue-1',
    benefitName: 'Child Care Vouchers',
    benefitDescription: '',
    coverLevels: [],
    levelId: 0,
    levelName: '',
    levelPercentage: 75,
    levelAmount: 487.5,
    coverAmount: 650.0,
    taxableAmount: 48.75,
    youPay: 121.88,
    createdDateTime: '0001-01-01T00:00:00+00:00',
    createdById: null,
    benefitType: ''
  }
];

import {
  Component,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { FundTable } from '../../classes/fund-table';

@Component({
  selector: 'ovation-dashboard-funds-table',
  templateUrl: './dashboard-funds-table.component.html',
  styleUrls: ['./dashboard-funds-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DashboardFundsTableComponent extends FundTable {
  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super(fb, cd, locale);
  }
}

import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'ovation-tax-relief-text',
  templateUrl: './tax-relief-text.component.html',
  styleUrls: ['./tax-relief-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxReliefTextComponent implements OnInit {
  @Input() taxReliefThreshold: number = 0;
  @Input() contributionPct: number = 0;

  constructor() {}

  ngOnInit() {}
}

import { Directive, Input, ElementRef } from '@angular/core';

import * as Inputmask from 'inputmask';

interface MaskOptions {
  mask?: string;
  maskAlias?: string;
  regex?: string;
  regexAlias?: string;
  placeholder?: string;
}

@Directive({
  selector: '[ovnRestrictInput]'
})
export class RestrictInputDirective {
  @Input('ovnRestrictInput')
  public set defineInputType(options: MaskOptions) {
    const { mask, maskAlias, regexAlias, regex, placeholder } = options;

    const maskOptions = {
      mask: maskAlias
        ? RestrictInputDirective.maskMap[maskAlias]
        : mask
        ? mask
        : undefined,
      regex: regexAlias
        ? RestrictInputDirective.regexMap[regexAlias]
        : regex
        ? regex
        : undefined,
      placeholder: placeholder ? placeholder : '',
      showMaskOnHover: false,
      showMaskOnFocus: false
    };

    Inputmask(maskOptions).mask(this.el.nativeElement);
  }

  private static maskMap = {
    date: '9999-99-99',
    decimal: '9{+}[.9{2}]',
    alphaNumeric: '*{*}'
  };

  // map of regex strings
  private static regexMap = {
    integer: '^[0-9]*$',
    alphaNumeric: '^[a-zA-Z0-9]+$',
    float: '^([0-9]*[.])?[0-9]+$',
    decimal: '^([0-9]*[.])?[0-9]{2}$',
    words: '([A-z]*\\s)*',
    point25: '^-?[0-9]*(?:\\.25|\\.50|\\.75|)$'
  };

  constructor(private el: ElementRef) {}
}

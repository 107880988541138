import {
  Component,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';

import { Contribution } from '@ovation/core';

@Component({
  selector: 'ovation-contributions-history-table',
  templateUrl: './contributions-history-table.component.html',
  styleUrls: ['./contributions-history-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContributionsHistoryTableComponent implements OnChanges {
  @Input() data: Contribution[];
  public latestBalance: number;
  public sort: SortDescriptor[] = [
    {
      field: 'dayEndDate',
      dir: 'desc'
    }
  ];
  public gridView: GridDataResult;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data && this.data.length) {
      // Default sorting by date
      this.data = orderBy(this.data, this.sort);

      this.setData();

      // Get latest balance, data already sorted by data
      this.latestBalance = this.data[0].balance;
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    this.setData();
  }

  public formatDate(date: string | Date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  private setData(): void {
    this.gridView = {
      data: orderBy(this.data, this.sort),
      total: this.data.length
    };
  }
}

import { Injectable } from '@angular/core';
import { User, WatchItem, Profile } from '@ovation/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private _user: User;
  private _user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  get user(): User {
    return this._user;
  }
  set user(user: User) {
    this._user = user;
    this._user$.next(user);
  }

  get user$(): BehaviorSubject<User> {
    return this._user$;
  }

  private _profile: Profile;
  private _profile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(
    null
  );
  get profile(): Profile {
    return this._profile;
  }
  set profile(profile: Profile) {
    this._profile = profile;
    this._profile$.next(profile);
  }
  get profile$(): BehaviorSubject<Profile> {
    return this._profile$;
  }

  private _userWatchList: WatchItem[];
  private _userWatchList$: BehaviorSubject<WatchItem[]> = new BehaviorSubject<
    WatchItem[]
  >(null);
  set userWatchList(watchList: WatchItem[]) {
    this._userWatchList = watchList;
    this._userWatchList$.next(watchList);
  }
  get userWatchList(): WatchItem[] {
    return this._userWatchList;
  }

  get userWatchList$(): BehaviorSubject<WatchItem[]> {
    return this._userWatchList$;
  }

  constructor() {}
}

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OvnAccordion } from './accordion';
import {
  OvnExpansionPanel,
  OvnExpansionPanelActionRow
} from './expansion-panel';
import { OvnExpansionPanelContent } from './expansion-panel-content';
import {
  OvnExpansionPanelDescription,
  OvnExpansionPanelHeader,
  OvnExpansionPanelTitle
} from './expansion-panel-header';

@NgModule({
  imports: [CommonModule, CdkAccordionModule, PortalModule],
  exports: [
    OvnAccordion,
    OvnExpansionPanel,
    OvnExpansionPanelActionRow,
    OvnExpansionPanelHeader,
    OvnExpansionPanelTitle,
    OvnExpansionPanelDescription,
    OvnExpansionPanelContent
  ],
  declarations: [
    OvnAccordion,
    OvnExpansionPanel,
    OvnExpansionPanelActionRow,
    OvnExpansionPanelHeader,
    OvnExpansionPanelTitle,
    OvnExpansionPanelDescription,
    OvnExpansionPanelContent
  ]
})
export class ExpansionModule {}

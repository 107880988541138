import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Message } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private static httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  public getAllMessages(): Observable<Message> {
    return this.http.get<Message>(`api/messages`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getMessage(id: number): Observable<Message> {
    return this.http.get<Message>(`api/messages/${id}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }
}

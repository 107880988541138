/**
 * OnlineBenefits Employee Client API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { CurrentValueComponentDto } from '../model/currentValueComponentDto';
import { FutureValueComponentDto } from '../model/futureValueComponentDto';
import { ProgressBarComponentDto } from '../model/progressBarComponentDto';
import { RetirementSavingCreateDto } from '../model/retirementSavingCreateDto';
import { RetirementSavingDeleteDto } from '../model/retirementSavingDeleteDto';
import { RetirementSavingUpdateDto } from '../model/retirementSavingUpdateDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class RetirementPlanService {
  protected basePath = 'http://localhost:26786';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param dto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanCreateRetirementSaving(
    dto: RetirementSavingCreateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public retirementPlanCreateRetirementSaving(
    dto: RetirementSavingCreateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public retirementPlanCreateRetirementSaving(
    dto: RetirementSavingCreateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public retirementPlanCreateRetirementSaving(
    dto: RetirementSavingCreateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dto === null || dto === undefined) {
      throw new Error(
        'Required parameter dto was null or undefined when calling retirementPlanCreateRetirementSaving.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/api/RetirementPlan/CreateRetirementSaving`,
      dto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param dto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanDeleteRetirementSaving(
    dto: RetirementSavingDeleteDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public retirementPlanDeleteRetirementSaving(
    dto: RetirementSavingDeleteDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public retirementPlanDeleteRetirementSaving(
    dto: RetirementSavingDeleteDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public retirementPlanDeleteRetirementSaving(
    dto: RetirementSavingDeleteDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dto === null || dto === undefined) {
      throw new Error(
        'Required parameter dto was null or undefined when calling retirementPlanDeleteRetirementSaving.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.delete<any>(
      `${this.basePath}/api/RetirementPlan/DeleteRetirementSaving`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanGetCurrentValueComponent(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CurrentValueComponentDto>;
  public retirementPlanGetCurrentValueComponent(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CurrentValueComponentDto>>;
  public retirementPlanGetCurrentValueComponent(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CurrentValueComponentDto>>;
  public retirementPlanGetCurrentValueComponent(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CurrentValueComponentDto>(
      `${this.basePath}/api/RetirementPlan/GetCurrentValueComponent`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param adjustForInflation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanGetFutureValueComponent(
    adjustForInflation: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<FutureValueComponentDto>;
  public retirementPlanGetFutureValueComponent(
    adjustForInflation: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<FutureValueComponentDto>>;
  public retirementPlanGetFutureValueComponent(
    adjustForInflation: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<FutureValueComponentDto>>;
  public retirementPlanGetFutureValueComponent(
    adjustForInflation: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (adjustForInflation === null || adjustForInflation === undefined) {
      throw new Error(
        'Required parameter adjustForInflation was null or undefined when calling retirementPlanGetFutureValueComponent.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (adjustForInflation !== undefined && adjustForInflation !== null) {
      queryParameters = queryParameters.set('adjustForInflation', <any>(
        adjustForInflation
      ));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<FutureValueComponentDto>(
      `${this.basePath}/api/RetirementPlan/GetFutureValueComponent`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanGetProgressBarComponent(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProgressBarComponentDto>;
  public retirementPlanGetProgressBarComponent(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProgressBarComponentDto>>;
  public retirementPlanGetProgressBarComponent(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProgressBarComponentDto>>;
  public retirementPlanGetProgressBarComponent(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProgressBarComponentDto>(
      `${this.basePath}/api/RetirementPlan/GetProgressBarComponent`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param retirementSavingId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanGetRetirementSavingForUpdate(
    retirementSavingId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RetirementSavingUpdateDto>;
  public retirementPlanGetRetirementSavingForUpdate(
    retirementSavingId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RetirementSavingUpdateDto>>;
  public retirementPlanGetRetirementSavingForUpdate(
    retirementSavingId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RetirementSavingUpdateDto>>;
  public retirementPlanGetRetirementSavingForUpdate(
    retirementSavingId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (retirementSavingId === null || retirementSavingId === undefined) {
      throw new Error(
        'Required parameter retirementSavingId was null or undefined when calling retirementPlanGetRetirementSavingForUpdate.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (retirementSavingId !== undefined && retirementSavingId !== null) {
      queryParameters = queryParameters.set('retirementSavingId', <any>(
        retirementSavingId
      ));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RetirementSavingUpdateDto>(
      `${this.basePath}/api/RetirementPlan/GetRetirementSavingForUpdate`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param dto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retirementPlanUpdateRetirementSaving(
    dto: RetirementSavingUpdateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public retirementPlanUpdateRetirementSaving(
    dto: RetirementSavingUpdateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public retirementPlanUpdateRetirementSaving(
    dto: RetirementSavingUpdateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public retirementPlanUpdateRetirementSaving(
    dto: RetirementSavingUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (dto === null || dto === undefined) {
      throw new Error(
        'Required parameter dto was null or undefined when calling retirementPlanUpdateRetirementSaving.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/RetirementPlan/UpdateRetirementSaving`,
      dto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

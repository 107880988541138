import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
  OnInit
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ovnDecimalFormatter]'
})
export class DecimalFormatterDirective implements OnInit {
  @Input('ovnDecimalFormatter') format = '1.2-2';

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private decimalPipe: DecimalPipe,
    @Optional() private control: NgControl
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.formatValue(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.formatValue(value);

    if (this.control) {
      this.control.control.setValue(this.el.value);
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.formatValue(value);

    if (this.control) {
      this.control.control.setValue(this.el.value);
    }
  }

  @HostListener('change', ['$event.target.value'])
  onChange(value) {
    this.el.value = this.formatValue(value);

    if (this.control) {
      this.control.control.setValue(this.el.value);
    }
  }

  private formatValue(value: string | number): string {
    return value
      ? this.decimalPipe.transform(value, this.format).replace(/\,/gi, '')
      : '';
  }
}

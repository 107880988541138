import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  ElementRef,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { OwlDateTimeComponent } from 'ng-pick-datetime';
import * as moment from 'moment';

@Component({
  selector: 'ovn-date-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() input: HTMLInputElement;
  @Input() control: FormControl;
  @Input() selectMode: 'range' | 'single' = 'single';
  @Input() min: any;
  @Input() max: any;
  @Input() dateFormat: string;
  @Input() displaySelection = false;
  @Input() displayDateFormat: string = this.dateFormat;
  @Input() button = true;
  @Output() change: any = new EventEmitter();

  @ViewChild('btn') btn: ElementRef;

  public value: any;
  public hasValue = false;
  public displayValue: string;

  constructor(private cd: ChangeDetectorRef) {}

  public dateTimeChange(e: any) {
    this.update(e.input.value);

    this.change.emit({
      type: e.value.length ? 'range' : 'single',
      value: e.value
    });
  }

  public update(value) {
    this.updateInput(value);
    this.updateControl(value);
  }

  private updateInput(val: string) {
    let valid = true;

    if (this.input) {
      this.input.value = val;
    }

    let modelVal: string | string[] = val;

    if (val) {
      const valArray = val.split(' ~ ');

      if (this.dateFormat) {
        for (let i = 0; i < valArray.length; i++) {
          if (!moment(valArray[i], this.dateFormat, true).isValid()) {
            valid = false;
            break;
          }
        }
      }

      modelVal = valArray.length > 1 ? valArray : valArray[0];
    }

    this.updateModel(modelVal);

    this.hasValue = val && valid ? true : false;

    this.cd.detectChanges();
  }

  private updateControl(val) {
    if (this.control) {
      this.control.setValue(val);
    }
  }

  public updateModel(val: any) {
    this.value = val;
    this.updateDisplayValue(val);
    this.cd.detectChanges();
  }

  private updateDisplayValue(value: any) {
    if (!value) return;
    this.displayValue =
      typeof value === 'string'
        ? moment(value).format(this.displayDateFormat)
        : [
            moment(value[0]).format(this.displayDateFormat),
            moment(value[1]).format(this.displayDateFormat)
          ].join(' - ');
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.control) {
      if (this.control.value && this.control.valid) {
        this.updateInput(this.control.value);
      } else {
        this.updateModel(null);
      }

      this.control.valueChanges.subscribe((val: string) => {
        this.updateInput(val);
      });

      // DISABLED STATE
      if (this.control.disabled) {
        this.btn.nativeElement.disabled = true;
      } else {
        this.btn.nativeElement.disabled = false;
      }
    }
  }
}

export const PARTNER_PERCENTAGE_OPTIONS = [
  {
    id: '1',
    displayValue: '0%',
    calcValue: '0.0000'
  },
  {
    id: '2',
    displayValue: '50%',
    calcValue: '0.5000'
  },
  {
    id: '3',
    displayValue: '66%',
    calcValue: '0.6600'
  }
];

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  Inject,
  LOCALE_ID
} from '@angular/core';

import { formatPercent } from '@angular/common';

function formatLabelValue(value: number) {
  return formatPercent(value / 100, PieChartComponent.loc, '1.0-1');
}

@Component({
  selector: 'ovation-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent<T> implements OnInit, OnChanges {

  constructor(@Inject(LOCALE_ID) public locale: string) {
    PieChartComponent.loc = this.locale;
  }

  public static loc;
  @Input() data: T[];
  @Input() chartType: 'donut' | 'pie' = 'donut';
  @Input() showTooltips = false;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() bindToolTips: string;
  @Input() showLabels = false;
  @Input() showLegend = false;
  @Input() bindValue: string = 'contribution';
  @Input() bindCategory: string = 'fund.fundName';
  @Input() labelFont: string = 'bold 16px Open Sans';

  public displayData: T[];

  public borderOptions = {
    color: '#ffffff',
    dashType: 'solid',
    width: 2
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (this.data && this.data.length) {
        this.displayData = this.data.map(fund => {
          return {
            ...fund,
            [this.bindValue]: fund[this.bindValue] * 100
          };
        });
      }
    }
  }

  ngOnInit() {}

  public labelContent(e: any): string {
    return formatLabelValue(e.value);
  }
}

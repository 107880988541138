import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { GestureConfig, OvationCommonModule } from '@ovation/core';
import { SliderComponent } from './slider/slider.component';
import { SliderThumbLabelTemplateDirective } from './slider/slider-thumb-label-template.directive';

@NgModule({
  imports: [CommonModule, OvationCommonModule],
  declarations: [SliderComponent, SliderThumbLabelTemplateDirective],
  exports: [
    SliderComponent,
    OvationCommonModule,
    SliderThumbLabelTemplateDirective
  ],
  providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig }]
})
export class SliderModule {}

import {
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import {
  map,
  distinctUntilChanged,
  tap,
  debounceTime,
  takeUntil
} from 'rxjs/operators';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FundContribution } from '@ovation/core';

export abstract class FundTable implements OnInit, OnChanges, OnDestroy {
  @Input() readonly = false;
  @Input() data: FundContribution[];
  @Input() availableFunds = 0;
  @Input() allocatedFunds = 0;
  @Input() fundsRemovable = true;
  @Output() removeItem = new EventEmitter();
  @Output() totalChange = new EventEmitter<number>();

  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public formGroup: FormGroup;
  public totalPct = 0;

  private formValueChangesSubscr: Subscription;
  protected destroyed$ = new Subject<boolean>();

  protected pctValidators = [
    Validators.required,
    Validators.maxLength(3),
    Validators.max(100),
    Validators.min(1)
  ];

  protected valueValidators = [
    Validators.required,
    Validators.max(this.allocatedFunds),
    Validators.min(1)
  ];

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data &&
      changes.data.previousValue !== changes.data.currentValue
    ) {
      this.formGroup = this.fb.group({
        total: this.fb.control(0, [...this.pctValidators, Validators.min(100)]),
        funds: this.fb.array([])
      });

      if (this.data && this.data.length) {
        if (this.formValueChangesSubscr) {
          this.formValueChangesSubscr.unsubscribe();
        }

        this.loadFunds();

        for (let i = 0; i < this.data.length; i++) {
          (<FormArray>this.formGroup.controls.funds).push(
            this.fb.control(null, this.pctValidators)
          );
        }

        this.formValueChangesSubscr = this.formGroup.controls.funds.valueChanges
          .pipe(
            takeUntil(this.destroyed$),
            map(vals => {
              return vals.map(val =>
                val && val ? parseInt(<string>val, 10) : 0
              );
            }),
            map(vals => vals.reduce((acc, curr) => acc + curr, 0)),
            debounceTime(100),
            distinctUntilChanged(),
            tap(v => {
              this.updateTotal(v);
              this.cd.markForCheck();
            })
          )
          .subscribe();

        this.syncFormControlsOrder();
      }
    }
  }

  ngOnInit() {}

  public removeFund(fundId: string) {
    this.removeItem.emit(fundId);
  }

  public getAbs(data: number): number {
    return Math.abs(data);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadFunds();

    this.syncFormControlsOrder();
  }

  protected loadFunds(): void {
    this.gridView = {
      data: orderBy(this.data, this.sort),
      total: this.data.length
    };
  }

  protected getValueFromPercentage(percentage: number): number {
    const value: number = (percentage / 100) * this.availableFunds;

    return value;
  }

  protected updateTotal(value: number) {
    const val = this.getValueFromPercentage(value);

    this.totalPct = value;

    this.formGroup.controls.total.setValue(value);

    this.totalChange.emit(val);
  }

  private syncFormControlsOrder() {
    const sortedData = orderBy(this.data, this.sort);

    for (let i = 0; i < sortedData.length; i++) {
      (<FormArray>this.formGroup.controls.funds)
        .at(i)
        .setValue(sortedData[i].contribution * 100);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

export const INVESTMENT_GROWTH = [
  {
    id: '1',
    displayValue: '5.0%',
    calcValue: '0.0500'
  },
  {
    id: '2',
    displayValue: '5.25%',
    calcValue: '0.0525'
  },
  {
    id: '3',
    displayValue: '5.5%',
    calcValue: '0.0550'
  },
  {
    id: '4',
    displayValue: '7.0%',
    calcValue: '0.0700'
  },
  {
    id: '5',
    displayValue: '8.0%',
    calcValue: '0.0800'
  },
  {
    id: '6',
    displayValue: '9.0%',
    calcValue: '0.0900'
  }
];

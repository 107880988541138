import {
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  FormGroup,
  ValidationErrors,
  AbstractControl,
  FormControl,
  FormBuilder
} from '@angular/forms';

import { FormModelItem } from '../models/form-model-item.model';
import { Subject } from 'rxjs';

export interface FormError {
  error: string;
  params: any;
}

export abstract class ReactiveForm implements OnInit, OnDestroy {
  @Output() onSubmit = new EventEmitter();

  protected formModel: { [key: string]: FormModelItem | FormModelItem };
  public formGroup: FormGroup;

  protected destroyed$ = new Subject<boolean>();

  constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {}

  public validateAllFormFields(formGroup?: FormGroup) {
    let fg: FormGroup;

    if (!formGroup) {
      fg = this.formGroup;
    } else {
      fg = formGroup;
    }

    //{1}
    Object.keys(fg.controls).forEach(field => {
      //{2}
      const control = fg.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }

  public formErrors(): FormError[] {
    if (this.formGroup.errors) {
      return this.getErrors(this.formGroup);
    }
  }

  public fieldErrors(name: string): FormError[] {
    const control = this.findFieldControl(name);
    if (control && control.touched && control.errors) {
      return this.getErrors(control);
    } else {
      return undefined;
    }
  }

  public resetFieldErrors(name: string): void {
    this.formGroup.get(name).setErrors(null);
  }

  public getControl(formGroup, controlName) {
    return this[formGroup].get(controlName);
  }

  protected handleSubmitError(error: any) {
    if (error.status === 422) {
      const data = error.json();
      const fields = Object.keys(data || {});
      fields.forEach(field => {
        const control = this.findFieldControl(field);
        const errors = this.fetchFieldErrors(data, field);
        control.setErrors(errors);
      });
    }
  }

  protected getErrors(control: AbstractControl): FormError[] {
    return Object.keys(control.errors)
      .filter(error => control.errors[error])
      .map(error => {
        const params = control.errors[error];
        return {
          error: error,
          params: params === true || params == {} ? null : params
        };
      });
  }

  protected findFieldControl(field: string): AbstractControl {
    let control: AbstractControl;
    if (field === 'base') {
      control = this.formGroup;
    } else if (this.formGroup.contains(field)) {
      control = this.formGroup.get(field);
    } else if (
      field.match(/_id$/) &&
      this.formGroup.contains(field.substring(0, field.length - 3))
    ) {
      control = this.formGroup.get(field.substring(0, field.length - 3));
    } else if (field.indexOf('.') > 0) {
      let group = this.formGroup;
      field.split('.').forEach(f => {
        if (group.contains(f)) {
          control = group.get(f);
          if (control instanceof FormGroup) group = control;
        } else {
          control = group;
        }
      });
    } else {
      // Field is not defined in form but there is a validation error for it, set it globally
      control = this.formGroup;
    }
    return control;
  }

  protected fetchFieldErrors(data: any, field: string): any {
    const errors = {};
    data[field].forEach(e => {
      const name: string = e.error;
      delete e.error;
      errors[name] = e;
    });
    return errors;
  }

  public clear() {
    this.formGroup.reset();
  }

  public disable() {
    this.formGroup.disable({
      emitEvent: false
    });
  }

  public enable() {
    this.formGroup.enable({
      emitEvent: false
    });
  }

  protected buildFormGroup(formModel?: any) {
    if (!this.formModel) return;

    if (!this.formGroup) {
      const model = { ...(formModel || this.formModel) };

      // Build formGroup from formModel
      this.formGroup = this.fb.group({});

      for (const key in model) {
        if (model.hasOwnProperty(key)) {
          this.formGroup.addControl(
            key,
            new FormControl(
              {
                value: model[key].value || null,
                disabled: model[key].disabled ? true : false
              },
              {
                validators: model[key].validators || [],
                asyncValidators: model[key].asyncValidators || []
              }
            )
          );
        }
      }
    }
  }

  protected getValidationErrors(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.formGroup.get(key).errors;

      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  protected updateFormControl(
    control: AbstractControl,
    validators?: any[]
  ): void {
    if (validators) {
      control.setValidators(validators);
    } else {
      control.reset();
    }

    control.updateValueAndValidity();
    this.cd.detectChanges();
  }

  public abstract submit(): void;

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { Component, ViewChild, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ovn-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  public isMobileDevice = false;
  @ViewChild('scrollTop') scrollTop: PerfectScrollbarComponent;

  constructor(private platform: Platform, private router: Router) {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.isMobileDevice = true;
    }
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.scrollTop.directiveRef.scrollToTop();
    });
  }
}

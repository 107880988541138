import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ovationClearInput]'
})
export class ClearInputDirective {
  private el: any;

  constructor(private elementRef: ElementRef) {
    console.log(this.elementRef);

    this.el = this.elementRef;
  }

  @HostListener('focus')
  onFocus() {
    console.log(this.el, this.el.value);

    if (this.el && (this.el.value === '0' || this.el.value === 0)) {
      this.el.value = null;
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ElementRef,
  ChangeDetectorRef,
  Optional,
  Attribute,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {
  HasTabIndexCtor,
  CanDisableCtor,
  mixinTabIndex,
  mixinDisabled
} from '@ovation/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import {
  hasModifierKey,
  PAGE_UP,
  PAGE_DOWN,
  END,
  HOME,
  LEFT_ARROW,
  UP_ARROW,
  RIGHT_ARROW,
  DOWN_ARROW
} from '@angular/cdk/keycodes';

/**
 * Provider Expression that allows Component to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)] and [formControl].
 */
export const INCREMENTER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => IncrementerComponent),
  multi: true
};

/** A simple change event emitted by the ContributionCounter component. */
export class IncrementerChange {
  /** The ContributionCounter that changed. */
  source: IncrementerComponent;

  /** The new value of the source slider. */
  value: number | null;
}

// Boilerplate for applying mixins to ContributionCounter.
/** @docs-private */
export class IncrementerBase {
  constructor(public _elementRef: ElementRef) {}
}
export const _IncrementerMixinBase: HasTabIndexCtor &
  CanDisableCtor &
  typeof IncrementerBase = mixinTabIndex(mixinDisabled(IncrementerBase));

@Component({
  selector: 'ovation-incrementer',
  templateUrl: './incrementer.component.html',
  styleUrls: ['./incrementer.component.scss'],
  providers: [INCREMENTER_VALUE_ACCESSOR],
  host: {
    '(focus)': '_onFocus()',
    '(blur)': '_onBlur()',
    '(keydown)': '_onKeydown($event)'
  }
})
export class IncrementerComponent extends _IncrementerMixinBase
  implements OnInit, ControlValueAccessor, OnChanges {
  @Input() public value = Math.floor(0 * 100) / 100;
  @Input() public increment = 0.0025;
  /** The maximum value that the slider can have. */
  @Input() max = 1;
  @Input() min = 0;
  @Input() format: null | 'pct' = null;

  @Output() change = new EventEmitter();

  public displayValue = 0;

  /** set focus to the host element */
  public focus() {
    this._focusHostElement();
  }

  /** blur the host element */
  public blur() {
    this._blurHostElement();
  }

  /** onTouch function registered via registerOnTouch (ControlValueAccessor). */
  onTouched: () => any = () => {};

  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  constructor(
    elementRef: ElementRef,
    protected _focusMonitor: FocusMonitor,
    protected _changeDetectorRef: ChangeDetectorRef,
    @Optional() protected _dir: Directionality,
    @Attribute('tabindex') tabIndex: string
  ) {
    super(elementRef);

    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.updateDisplayValue();
    }
  }

  ngOnInit() {
    // this._emitChangeEvent();
  }

  public minus() {
    this._increment(-1);
  }

  public plus() {
    this._increment(1);
  }

  _onBlur() {
    this.onTouched();
  }

  _onKeydown(event: KeyboardEvent) {
    if (this.disabled || hasModifierKey(event)) {
      return;
    }

    const oldValue = this.value;

    switch (event.keyCode) {
      case PAGE_UP:
        this._increment(10);
        break;
      case PAGE_DOWN:
        this._increment(-10);
        break;
      case END:
        this.value = this.max;
        break;
      case HOME:
        this.value = this.min;
        break;
      case LEFT_ARROW:
        this._increment(-1);
        break;
      case UP_ARROW:
        this._increment(1);
        break;
      case RIGHT_ARROW:
        // See comment on LEFT_ARROW about the conditions under which we flip the meaning.
        this._increment(1);
        break;
      case DOWN_ARROW:
        this._increment(-1);
        break;
      default:
        // Return if the key is not one that we explicitly handle to avoid calling preventDefault on
        // it.
        return;
    }

    if (oldValue !== this.value) {
      this._emitChangeEvent();
    }
    event.preventDefault();
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value
   */
  writeValue(value: any) {
    this.value = value;
  }

  /**
   * Registers a callback to be triggered when the value has changed.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /**
   * Registers a callback to be triggered when the component is touched.
   * Implemented as part of ControlValueAccessor.
   * @param fn Callback to be registered.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * Sets whether the component should be disabled.
   * Implemented as part of ControlValueAccessor.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /** Increments the slider by the given number of steps (negative number decrements). */
  private _increment(numSteps: number) {
    this.value = this._clamp(
      (this.value || 0) + this.increment * numSteps,
      this.min,
      this.max
    );

    this._emitChangeEvent();
  }

  /** Emits a change event if the current value is different from the last emitted value. */
  private _emitChangeEvent() {
    this._controlValueAccessorChangeFn(this.value);
    this.change.emit(this.value);
    this.updateDisplayValue();
  }

  /** Return a number between two numbers. */
  private _clamp(value: number, min = 0, max = 1) {
    return Math.max(min, Math.min(value, max));
  }

  /**
   * Focuses the native element.
   * Currently only used to allow a blur event to fire but will be used with keyboard input later.
   */
  private _focusHostElement() {
    this._elementRef.nativeElement.focus();
  }

  /** Blurs the native element. */
  private _blurHostElement() {
    this._elementRef.nativeElement.blur();
  }

  protected updateDisplayValue() {
    this.displayValue = this.value ? this.value * 100 : 0;
  }
}

import { Contribution } from '../../models/contribution.interface';

export const CONTRIBUTIONS: Contribution[] = [
  {
    dayEndDate: '2019-03-31T00:00:00+02:00',
    value: 4259,
    transactionType: 'credit',
    valuation: 316908,
    balance: 309911,
    description: 'Annual Fund Fee'
  },
  {
    dayEndDate: '2019-04-30T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 330000,
    balance: 317111,
    description: 'Employer Match'
  },
  {
    dayEndDate: '2019-04-30T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 326000,
    balance: 313511,
    description: 'Employee Contribution'
  },
  {
    dayEndDate: '2019-03-28T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 330000,
    balance: 314170,
    description: 'Employer Match'
  },
  {
    dayEndDate: '2019-03-28T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 326000,
    balance: 310570,
    description: 'Employee Contribution'
  },
  {
    dayEndDate: '2019-02-28T00:00:00+02:00',
    value: 10000,
    transactionType: 'withdrawal',
    valuation: 309800,
    balance: 306970,
    description: 'Withdrawal'
  },
  {
    dayEndDate: '2019-02-26T00:00:00+02:00',
    value: 230,
    transactionType: 'credit',
    valuation: 320000,
    balance: 316970,
    description: 'Credit'
  },
  {
    dayEndDate: '2019-01-30T00:00:00+02:00',
    value: 300000,
    transactionType: 'debit',
    valuation: 319800,
    balance: 317200,
    description: 'Lump Sum'
  },
  {
    dayEndDate: '2019-01-28T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 18000,
    balance: 17200,
    description: 'Employer Match'
  },
  {
    dayEndDate: '2019-01-28T00:00:00+02:00',
    value: 3600,
    transactionType: 'debit',
    valuation: 13800,
    balance: 13600,
    description: 'Employee Contribution'
  }

  // {
  //   dayEndDate: '2018-05-01T00:00:00+02:00',
  //   contribution: 3500,
  //   valuation: 3500
  // },
  // {
  //   dayEndDate: '2018-06-01T00:00:00+02:00',
  //   contribution: 7000,
  //   valuation: 7000
  // },
  // {
  //   dayEndDate: '2018-07-01T00:00:00+02:00',
  //   contribution: 10500,
  //   valuation: 12000
  // },
  // {
  //   dayEndDate: '2018-08-01T00:00:00+02:00',
  //   contribution: 14000,
  //   valuation: 15000
  // },
  // {
  //   dayEndDate: '2018-09-01T00:00:00+02:00',
  //   contribution: 17500,
  //   valuation: 18000
  // },
  // {
  //   dayEndDate: '2018-10-01T00:00:00+02:00',
  //   contribution: 21000,
  //   valuation: 22000
  // },
  // {
  //   dayEndDate: '2018-11-01T00:00:00+02:00',
  //   contribution: 25000,
  //   valuation: 23000
  // },
  // {
  //   dayEndDate: '2018-12-01T00:00:00+02:00',
  //   contribution: 29000,
  //   valuation: 27000
  // },
  // {
  //   dayEndDate: '2019-01-01T00:00:00+02:00',
  //   contribution: 33000,
  //   valuation: 32500
  // },
  // {
  //   dayEndDate: '2019-02-01T00:00:00+02:00',
  //   contribution: 37000,
  //   valuation: 38000
  // },
  // {
  //   dayEndDate: '2019-03-01T00:00:00+02:00',
  //   contribution: 41000,
  //   valuation: 43000
  // },
  // {
  //   dayEndDate: '2019-04-01T00:00:00+02:00',
  //   contribution: 45000,
  //   valuation: 48000
  // },
  // {
  //   dayEndDate: '2019-05-01T00:00:00+02:00',
  //   contribution: 49000,
  //   valuation: 55000
  // }
];

import { GraphDataPoint } from '../../models';

export const GRAPHDATA: GraphDataPoint[] = [
  {
    id: 16,
    fundName: 'Investec Value I',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 15.3
  },
  {
    id: 1173,
    fundName: 'Investec Value I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 15.8
  },
  {
    id: 1842,
    fundName: 'Investec Value I',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 15.71
  },
  {
    id: 2799,
    fundName: 'Investec Value I',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 15.76
  },
  {
    id: 3756,
    fundName: 'Investec Value I',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 15.94
  },
  {
    id: 4649,
    fundName: 'Investec Value I',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 16.1
  },
  {
    id: 5616,
    fundName: 'Investec Value I',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 16.04
  },
  {
    id: 6524,
    fundName: 'Investec Value I',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 16.11
  },
  {
    id: 7399,
    fundName: 'Investec Value I',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 16.2
  },
  {
    id: 8304,
    fundName: 'Investec Value I',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 16.18
  },
  {
    id: 9276,
    fundName: 'Investec Value I',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 16.21
  },
  {
    id: 10259,
    fundName: 'Investec Value I',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 16.32
  },
  {
    id: 11543,
    fundName: 'Investec Value I',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 16.34
  },
  {
    id: 12894,
    fundName: 'Investec Value I',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 16.42
  },
  {
    id: 12997,
    fundName: 'Investec Value I',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 16.06
  },
  {
    id: 14050,
    fundName: 'Investec Value I',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 15.9
  },
  {
    id: 15089,
    fundName: 'Investec Value I',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 15.7
  },
  {
    id: 16053,
    fundName: 'Investec Value I',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 15.61
  },
  {
    id: 16569,
    fundName: 'Investec Value I',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 15.56
  },
  {
    id: 17396,
    fundName: 'Investec Value I',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 15.6
  },
  {
    id: 17398,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 11.17
  },
  {
    id: 16571,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 11.16
  },
  {
    id: 16055,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 11.17
  },
  {
    id: 15091,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 11.22
  },
  {
    id: 14052,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 11.31
  },
  {
    id: 12999,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 12896,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 11.5
  },
  {
    id: 11545,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 10261,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 9278,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 11.26
  },
  {
    id: 8306,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 11.27
  },
  {
    id: 7401,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 11.31
  },
  {
    id: 6526,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 11.3
  },
  {
    id: 5618,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 11.12
  },
  {
    id: 4651,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 11.2
  },
  {
    id: 3758,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 11.23
  },
  {
    id: 2801,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 11.11
  },
  {
    id: 1844,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 11.17
  },
  {
    id: 1175,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 11.17
  },
  {
    id: 18,
    fundName: 'Investec Worldwide Equity FF H',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 10.99
  },
  {
    id: 19,
    fundName: 'Investec Value H',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 15.21
  },
  {
    id: 1176,
    fundName: 'Investec Value H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 15.7
  },
  {
    id: 1845,
    fundName: 'Investec Value H',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 15.62
  },
  {
    id: 2802,
    fundName: 'Investec Value H',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 15.66
  },
  {
    id: 3759,
    fundName: 'Investec Value H',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 15.85
  },
  {
    id: 4652,
    fundName: 'Investec Value H',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 16.01
  },
  {
    id: 5619,
    fundName: 'Investec Value H',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 15.94
  },
  {
    id: 6527,
    fundName: 'Investec Value H',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 16.01
  },
  {
    id: 7402,
    fundName: 'Investec Value H',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 16.1
  },
  {
    id: 8307,
    fundName: 'Investec Value H',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 16.08
  },
  {
    id: 9279,
    fundName: 'Investec Value H',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 16.11
  },
  {
    id: 10262,
    fundName: 'Investec Value H',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 16.22
  },
  {
    id: 11546,
    fundName: 'Investec Value H',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 16.25
  },
  {
    id: 12897,
    fundName: 'Investec Value H',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 16.32
  },
  {
    id: 13000,
    fundName: 'Investec Value H',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 15.97
  },
  {
    id: 14053,
    fundName: 'Investec Value H',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 15.8
  },
  {
    id: 15092,
    fundName: 'Investec Value H',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 15.61
  },
  {
    id: 16056,
    fundName: 'Investec Value H',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 15.51
  },
  {
    id: 16572,
    fundName: 'Investec Value H',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 15.47
  },
  {
    id: 17399,
    fundName: 'Investec Value H',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 15.5
  },
  {
    id: 17400,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 5.73
  },
  {
    id: 16573,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 5.75
  },
  {
    id: 16057,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 5.78
  },
  {
    id: 15093,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 5.8
  },
  {
    id: 14054,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 6.73
  },
  {
    id: 13001,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 5.75
  },
  {
    id: 12898,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 5.74
  },
  {
    id: 11547,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 5.72
  },
  {
    id: 10263,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 5.75
  },
  {
    id: 9280,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 5.7
  },
  {
    id: 8308,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 5.7
  },
  {
    id: 7403,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 5.73
  },
  {
    id: 6528,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 5.79
  },
  {
    id: 5620,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 5.78
  },
  {
    id: 4653,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 5.73
  },
  {
    id: 3760,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 5.69
  },
  {
    id: 2803,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 5.68
  },
  {
    id: 1846,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 1177,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 20,
    fundName: 'Investec Property Equity H',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 21,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 1178,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 1847,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 5.65
  },
  {
    id: 2804,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 5.68
  },
  {
    id: 3761,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 5.69
  },
  {
    id: 4654,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 5.74
  },
  {
    id: 5621,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 5.78
  },
  {
    id: 6529,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 5.79
  },
  {
    id: 7404,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 5.73
  },
  {
    id: 8309,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 5.7
  },
  {
    id: 9281,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 5.7
  },
  {
    id: 10264,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 5.75
  },
  {
    id: 11548,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 5.72
  },
  {
    id: 12899,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 5.74
  },
  {
    id: 13002,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 5.76
  },
  {
    id: 14055,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 5.73
  },
  {
    id: 15094,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 5.8
  },
  {
    id: 16058,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 5.78
  },
  {
    id: 16574,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 5.75
  },
  {
    id: 17401,
    fundName: 'Investec Property Equity I',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 5.74
  },
  {
    id: 17402,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 11.31
  },
  {
    id: 16575,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 11.33
  },
  {
    id: 16059,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 11.33
  },
  {
    id: 15095,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 14056,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 13003,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 11.47
  },
  {
    id: 12900,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 11.5
  },
  {
    id: 11549,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 11.51
  },
  {
    id: 10265,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 11.49
  },
  {
    id: 9282,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 11.42
  },
  {
    id: 8310,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 7405,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 11.42
  },
  {
    id: 6530,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 11.41
  },
  {
    id: 5622,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 4655,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 11.42
  },
  {
    id: 3762,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 11.38
  },
  {
    id: 2805,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 11.39
  },
  {
    id: 1848,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 1179,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 11.34
  },
  {
    id: 22,
    fundName: 'Investec Opportunity I',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 11.28
  },
  {
    id: 23,
    fundName: 'Investec Managed I',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 10.82
  },
  {
    id: 1180,
    fundName: 'Investec Managed I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 10.89
  },
  {
    id: 1849,
    fundName: 'Investec Managed I',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 10.89
  },
  {
    id: 2806,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 10.88
  },
  {
    id: 3763,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 10.92
  },
  {
    id: 4656,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 10.96
  },
  {
    id: 5623,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 10.92
  },
  {
    id: 6531,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 11
  },
  {
    id: 7406,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 8311,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 9283,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 10266,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 11.06
  },
  {
    id: 11550,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 11.06
  },
  {
    id: 12901,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 11.08
  },
  {
    id: 13004,
    fundName: 'Investec Managed I',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 11.04
  },
  {
    id: 14057,
    fundName: 'Investec Managed I',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 11
  },
  {
    id: 15096,
    fundName: 'Investec Managed I',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 10.98
  },
  {
    id: 16060,
    fundName: 'Investec Managed I',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 10.95
  },
  {
    id: 16576,
    fundName: 'Investec Managed I',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 10.97
  },
  {
    id: 17403,
    fundName: 'Investec Managed I',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 10.99
  },
  {
    id: 17404,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 11.31
  },
  {
    id: 16577,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 11.32
  },
  {
    id: 16061,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 11.33
  },
  {
    id: 15097,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 11.36
  },
  {
    id: 14058,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 13005,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 11.46
  },
  {
    id: 12902,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 11.5
  },
  {
    id: 11551,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 11.51
  },
  {
    id: 10267,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 11.49
  },
  {
    id: 9284,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 11.42
  },
  {
    id: 8312,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 11.4
  },
  {
    id: 7407,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 11.41
  },
  {
    id: 6532,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 11.41
  },
  {
    id: 5624,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 4657,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 11.42
  },
  {
    id: 3764,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 11.38
  },
  {
    id: 2807,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 11.39
  },
  {
    id: 1850,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 11.37
  },
  {
    id: 1181,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 11.34
  },
  {
    id: 24,
    fundName: 'Investec Opportunity H',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 11.28
  },
  {
    id: 25,
    fundName: 'Investec High Income Z',
    dayEndDate: '2017-10-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 1182,
    fundName: 'Investec High Income Z',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 1851,
    fundName: 'Investec High Income Z',
    dayEndDate: '2017-12-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 2808,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-01-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 3765,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-02-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 4658,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-03-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 5625,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-04-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 6533,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-05-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 7408,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-06-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 8313,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-07-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 9285,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-08-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 10268,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-09-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 11552,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-10-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 12903,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-11-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 13006,
    fundName: 'Investec High Income Z',
    dayEndDate: '2018-12-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 14059,
    fundName: 'Investec High Income Z',
    dayEndDate: '2019-01-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 15098,
    fundName: 'Investec High Income Z',
    dayEndDate: '2019-02-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 16062,
    fundName: 'Investec High Income Z',
    dayEndDate: '2019-03-01T00:00:00+02:00',
    dayEndPrice: 1.15
  },
  {
    id: 16578,
    fundName: 'Investec High Income Z',
    dayEndDate: '2019-04-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 17405,
    fundName: 'Investec High Income Z',
    dayEndDate: '2019-05-01T00:00:00+02:00',
    dayEndPrice: 1.16
  },
  {
    id: 17407,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 10.99
  },
  {
    id: 16580,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 10.96
  },
  {
    id: 16064,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 10.94
  },
  {
    id: 15100,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 10.98
  },
  {
    id: 14061,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 10.99
  },
  {
    id: 13008,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 11.04
  },
  {
    id: 12905,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 11.08
  },
  {
    id: 11554,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 11.05
  },
  {
    id: 10270,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 11.06
  },
  {
    id: 9287,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 8315,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 7410,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 11.03
  },
  {
    id: 6535,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 11
  },
  {
    id: 5627,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 10.92
  },
  {
    id: 4659,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 10.95
  },
  {
    id: 3767,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 10.92
  },
  {
    id: 2810,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 10.88
  },
  {
    id: 1853,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 10.89
  },
  {
    id: 1184,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 10.89
  },
  {
    id: 27,
    fundName: 'Investec Managed H',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 10.81
  },
  {
    id: 28,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 4.16
  },
  {
    id: 1185,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 4.23
  },
  {
    id: 1854,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 4.23
  },
  {
    id: 2811,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 4.2
  },
  {
    id: 3768,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 4.25
  },
  {
    id: 4660,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 4.24
  },
  {
    id: 5628,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 4.2
  },
  {
    id: 6536,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 4.28
  },
  {
    id: 7411,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 4.28
  },
  {
    id: 8316,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 4.28
  },
  {
    id: 9288,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 4.28
  },
  {
    id: 10271,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 4.32
  },
  {
    id: 11555,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 4.34
  },
  {
    id: 12906,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 4.37
  },
  {
    id: 13009,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 4.33
  },
  {
    id: 14062,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 4.32
  },
  {
    id: 15101,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 4.29
  },
  {
    id: 16065,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 4.25
  },
  {
    id: 16581,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 4.26
  },
  {
    id: 17408,
    fundName: 'Investec Global Strategic Managed FF I',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 4.26
  },
  {
    id: 17409,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 1.71
  },
  {
    id: 16582,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 1.71
  },
  {
    id: 16066,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 1.71
  },
  {
    id: 15102,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 14063,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 1.74
  },
  {
    id: 13010,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 1.76
  },
  {
    id: 12907,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 1.77
  },
  {
    id: 11556,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 1.76
  },
  {
    id: 10272,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 1.75
  },
  {
    id: 9289,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 8317,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 7412,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 1.74
  },
  {
    id: 6537,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 5629,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 1.7
  },
  {
    id: 4661,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 1.72
  },
  {
    id: 3769,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 2812,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 1.71
  },
  {
    id: 1855,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 1.73
  },
  {
    id: 1186,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 1.72
  },
  {
    id: 29,
    fundName: 'Investec Global Multi-Asset Income FF I',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 1.7
  },
  {
    id: 30,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 4.1
  },
  {
    id: 1187,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 4.17
  },
  {
    id: 1856,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 4.16
  },
  {
    id: 2813,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 4.14
  },
  {
    id: 3770,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 4.19
  },
  {
    id: 4662,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 4.18
  },
  {
    id: 5630,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 4.14
  },
  {
    id: 6538,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 4.22
  },
  {
    id: 7413,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 4.22
  },
  {
    id: 8318,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 4.22
  },
  {
    id: 9290,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 4.22
  },
  {
    id: 10273,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 4.26
  },
  {
    id: 11557,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 4.28
  },
  {
    id: 12908,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 4.31
  },
  {
    id: 13011,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 4.27
  },
  {
    id: 14064,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 4.26
  },
  {
    id: 15103,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 4.23
  },
  {
    id: 16067,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 4.19
  },
  {
    id: 16583,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 4.2
  },
  {
    id: 17410,
    fundName: 'Investec Global Strategic Managed FF H',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 4.19
  },
  {
    id: 17411,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 8.98
  },
  {
    id: 16584,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 8.96
  },
  {
    id: 16068,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 8.93
  },
  {
    id: 15104,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 8.97
  },
  {
    id: 14065,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 9.06
  },
  {
    id: 13012,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 9.11
  },
  {
    id: 12909,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 9.18
  },
  {
    id: 11558,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 9.11
  },
  {
    id: 10274,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 9.07
  },
  {
    id: 9291,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 8.94
  },
  {
    id: 8319,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 8.99
  },
  {
    id: 7414,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 8.99
  },
  {
    id: 6539,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 8.97
  },
  {
    id: 5631,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 8.85
  },
  {
    id: 4663,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 8.94
  },
  {
    id: 3771,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 8.95
  },
  {
    id: 2814,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 8.93
  },
  {
    id: 1857,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 8.98
  },
  {
    id: 1188,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 8.96
  },
  {
    id: 31,
    fundName: 'Investec Global Franchise FF H',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 8.82
  },
  {
    id: 32,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-10-25T00:00:00+02:00',
    dayEndPrice: 52.74
  },
  {
    id: 1189,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-10-26T00:00:00+02:00',
    dayEndPrice: 53.3
  },
  {
    id: 1858,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-10-27T00:00:00+02:00',
    dayEndPrice: 53.22
  },
  {
    id: 2815,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-10-30T00:00:00+02:00',
    dayEndPrice: 53.29
  },
  {
    id: 3772,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-10-31T00:00:00+02:00',
    dayEndPrice: 53.47
  },
  {
    id: 4664,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-01T00:00:00+02:00',
    dayEndPrice: 54.06
  },
  {
    id: 5632,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-02T00:00:00+02:00',
    dayEndPrice: 53.71
  },
  {
    id: 6540,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-03T00:00:00+02:00',
    dayEndPrice: 54.12
  },
  {
    id: 7415,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-06T00:00:00+02:00',
    dayEndPrice: 54.45
  },
  {
    id: 8320,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-07T00:00:00+02:00',
    dayEndPrice: 54.51
  },
  {
    id: 9292,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-08T00:00:00+02:00',
    dayEndPrice: 54.28
  },
  {
    id: 10275,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-09T00:00:00+02:00',
    dayEndPrice: 54.34
  },
  {
    id: 11559,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-10T00:00:00+02:00',
    dayEndPrice: 54.34
  },
  {
    id: 12910,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-13T00:00:00+02:00',
    dayEndPrice: 54.31
  },
  {
    id: 13013,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-14T00:00:00+02:00',
    dayEndPrice: 53.98
  },
  {
    id: 14066,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-15T00:00:00+02:00',
    dayEndPrice: 53.58
  },
  {
    id: 15105,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-16T00:00:00+02:00',
    dayEndPrice: 53.71
  },
  {
    id: 16069,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-17T00:00:00+02:00',
    dayEndPrice: 53.93
  },
  {
    id: 16585,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-20T00:00:00+02:00',
    dayEndPrice: 54.22
  },
  {
    id: 17412,
    fundName: 'Investec Equity I',
    dayEndDate: '2017-11-21T00:00:00+02:00',
    dayEndPrice: 54.86
  }
];

export const investmentsLegendDictionary = [
  {
    title: 'blue',
    color: '#19aae3'
  },
  {
    title: 'blue-gray',
    color: '#7c8b92'
  },
  {
    title: 'cyan',
    color: '#25a69a'
  },
  {
    title: 'teal',
    color: '#037980'
  },
  {
    title: 'green',
    color: '#9cbd54'
  },
  {
    title: 'yellow',
    color: '#ebb338'
  },
  {
    title: 'red',
    color: '#e10201'
  }
];

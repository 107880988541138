import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  ElementRef,
  Input,
  Inject,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewContainerRef,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { Subject } from 'rxjs';
import { filter, startWith, take, distinctUntilChanged } from 'rxjs/operators';
import { ovnExpansionAnimations } from './expansion-animations';
import { OvnExpansionPanelContent } from './expansion-panel-content';
import { OVN_ACCORDION, OvnAccordionBase } from './accordion-base';

/** OvnExpansionPanel's states. */
export type OvnExpansionPanelState = 'expanded' | 'collapsed';

/** Counter for generating unique element ids. */
let uniqueId = 0;

/**
 * `<ovn-expansion-panel>`
 *
 * This component can be used as a single element to show expandable content, or as one of
 * multiple children of an element with the OvnAccordion directive attached.
 */
@Component({
  styleUrls: ['./expansion-panel.scss'],
  selector: 'ovn-expansion-panel',
  exportAs: 'ovnExpansionPanel',
  templateUrl: './expansion-panel.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['disabled', 'expanded'],
  outputs: ['opened', 'closed', 'expandedChange'],
  animations: [ovnExpansionAnimations.bodyExpansion],
  providers: [
    // Provide OvnAccordion as undefined to prevent nested expansion panels from registering
    // to the same accordion.
    { provide: OVN_ACCORDION, useValue: undefined }
  ],
  host: {
    class: 'ovn-expansion-panel',
    '[class.ovn-expanded]': 'expanded',
    '[class._ovn-animation-noopable]': '_animationMode === "NoopAnimations"',
    '[class.ovn-expansion-panel-spacing]': '_hasSpacing()',
    '[class.ovn-panel-content-top]': 'contentPlacement === "top"'
  }
})
export class OvnExpansionPanel extends CdkAccordionItem
  implements AfterContentInit, OnChanges, OnDestroy {
  // @breaking-change 8.0.0 Remove `| undefined` from here
  // when the `_document` constructor param is required.
  private _document: Document | undefined;

  /** Whether the toggle indicator should be hidden. */
  @Input()
  get hideToggle(): boolean {
    return this._hideToggle || (this.accordion && this.accordion.hideToggle);
  }
  set hideToggle(value: boolean) {
    this._hideToggle = coerceBooleanProperty(value);
  }
  private _hideToggle = false;

  @Input()
  get contentPlacement(): 'top' | 'bottom' {
    return this._contentPlacement;
  }
  set contentPlacement(value: 'top' | 'bottom') {
    this._contentPlacement = value;
  }
  private _contentPlacement: 'top' | 'bottom' = 'bottom';

  /** An event emitted after the body's expansion animation happens. */
  @Output() afterExpand = new EventEmitter<void>();

  /** An event emitted after the body's collapse animation happens. */
  @Output() afterCollapse = new EventEmitter<void>();

  /** Stream that emits for changes in `@Input` properties. */
  readonly _inputChanges = new Subject<SimpleChanges>();

  /** Optionally defined accordion the expansion panel belongs to. */
  accordion: OvnAccordionBase;

  /** Content that will be rendered lazily. */
  @ContentChild(OvnExpansionPanelContent)
  _lazyContent: OvnExpansionPanelContent;

  /** Element containing the panel's user-provided content. */
  @ViewChild('body') _body: ElementRef<HTMLElement>;

  /** Portal holding the user's content. */
  _portal: TemplatePortal;

  /** ID for the associated header element. Used for a11y labelling. */
  _headerId = `ovn-expansion-panel-header-${uniqueId++}`;

  /** Stream of body animation done events. */
  _bodyAnimationDone = new Subject<AnimationEvent>();

  constructor(
    @Optional() @SkipSelf() @Inject(OVN_ACCORDION) accordion: OvnAccordionBase,
    _changeDetectorRef: ChangeDetectorRef,
    _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
    private _viewContainerRef: ViewContainerRef,
    // @breaking-change 8.0.0 _document and _animationMode to be made required
    @Inject(DOCUMENT) _document?: any,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) public _animationMode?: string
  ) {
    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this.accordion = accordion;
    this._document = _document;

    // We need a Subject with distinctUntilChanged, because the `done` event
    // fires twice on some browsers. See https://github.com/angular/angular/issues/24084
    this._bodyAnimationDone
      .pipe(
        distinctUntilChanged((x, y) => {
          return x.fromState === y.fromState && x.toState === y.toState;
        })
      )
      .subscribe(event => {
        if (event.fromState !== 'void') {
          if (event.toState === 'expanded') {
            this.afterExpand.emit();
          } else if (event.toState === 'collapsed') {
            this.afterCollapse.emit();
          }
        }
      });
  }

  /** Determines whether the expansion panel should have spacing between it and its siblings. */
  _hasSpacing(): boolean {
    if (this.accordion) {
      // We don't need to subscribe to the `stateChanges` of the parent accordion because each time
      // the [displayMode] input changes, the change detection will also cover the host bindings
      // of this expansion panel.
      return (
        (this.expanded
          ? this.accordion.displayMode
          : this._getExpandedState()) === 'default'
      );
    }
    return false;
  }

  /** Gets the expanded state string. */
  _getExpandedState(): OvnExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  ngAfterContentInit() {
    if (this._lazyContent) {
      // Render the content as soon as the panel becomes open.
      this.opened
        .pipe(
          startWith<void>(null!),
          filter(() => this.expanded && !this._portal),
          take(1)
        )
        .subscribe(() => {
          this._portal = new TemplatePortal(
            this._lazyContent._template,
            this._viewContainerRef
          );
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this._inputChanges.next(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._bodyAnimationDone.complete();
    this._inputChanges.complete();
  }

  /** Checks whether the expansion panel's content contains the currently-focused element. */
  _containsFocus(): boolean {
    if (this._body && this._document) {
      const focusedElement = this._document.activeElement;
      const bodyElement = this._body.nativeElement;
      return (
        focusedElement === bodyElement || bodyElement.contains(focusedElement)
      );
    }

    return false;
  }
}

@Directive({
  selector: 'ovn-action-row',
  host: {
    class: 'ovn-action-row'
  }
})
export class OvnExpansionPanelActionRow {}

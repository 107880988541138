import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'ovation-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingComponent implements OnInit {
  @Input() starCount = 5;
  @Input()
  get rating(): number {
    return this._rating;
  }
  set rating(value: number) {
    this._rating = Math.round(value);
    this.cd.markForCheck();
  }
  private _rating = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'member' },
  {
    path: 'onboarding',
    loadChildren: './onboarding/onboarding.module#OnboardingModule',
    canActivate: []
  },
  {
    path: 'member',
    loadChildren: './member/member.module#MemberModule',
    canActivate: []
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export const FULLRANGE_PERCENTAGE_OPTIONS = [
  { id: '1', displayValue: '0%', calcValue: '0.0000' },
  { id: '2', displayValue: '0.5%', calcValue: '0.0050' },
  { id: '3', displayValue: '1.0%', calcValue: '0.0100' },
  { id: '4', displayValue: '1.5%', calcValue: '0.0150' },
  { id: '5', displayValue: '2.0%', calcValue: '0.0200' },
  { id: '6', displayValue: '2.5%', calcValue: '0.0250' },
  { id: '7', displayValue: '3.0%', calcValue: '0.0300' },
  { id: '8', displayValue: '3.5%', calcValue: '0.0350' },
  { id: '9', displayValue: '4.0%', calcValue: '0.0400' },
  { id: '10', displayValue: '4.5%', calcValue: '0.0450' },
  { id: '11', displayValue: '5.0%', calcValue: '0.0500' },
  { id: '12', displayValue: '5.5%', calcValue: '0.0550' },
  { id: '13', displayValue: '6.0%', calcValue: '0.0600' },
  { id: '14', displayValue: '6.5%', calcValue: '0.0650' },
  { id: '15', displayValue: '7.0%', calcValue: '0.0700' },
  { id: '16', displayValue: '7.5%', calcValue: '0.0750' },
  { id: '17', displayValue: '8.0%', calcValue: '0.0800' },
  { id: '18', displayValue: '8.5%', calcValue: '0.0850' },
  { id: '19', displayValue: '9.0%', calcValue: '0.0900' },
  { id: '20', displayValue: '9.5%', calcValue: '0.0950' },
  { id: '21', displayValue: '10.0%', calcValue: '0.1000' },
  { id: '22', displayValue: '10.5%', calcValue: '0.1050' },
  { id: '23', displayValue: '11.0%', calcValue: '0.1100' },
  { id: '24', displayValue: '11.5%', calcValue: '0.1150' },
  { id: '25', displayValue: '12.0%', calcValue: '0.1200' },
  { id: '26', displayValue: '12.5%', calcValue: '0.1250' },
  { id: '27', displayValue: '13.0%', calcValue: '0.1300' },
  { id: '28', displayValue: '13.5%', calcValue: '0.1350' },
  { id: '29', displayValue: '14.0%', calcValue: '0.1400' },
  { id: '30', displayValue: '14.5%', calcValue: '0.1450' },
  { id: '31', displayValue: '15.0%', calcValue: '0.1500' },
  { id: '32', displayValue: '15.5%', calcValue: '0.1550' },
  { id: '33', displayValue: '16.0%', calcValue: '0.1600' },
  { id: '34', displayValue: '16.5%', calcValue: '0.1650' },
  { id: '35', displayValue: '17.0%', calcValue: '0.1700' },
  { id: '36', displayValue: '17.5%', calcValue: '0.1750' },
  { id: '37', displayValue: '18.0%', calcValue: '0.1800' },
  { id: '38', displayValue: '18.5%', calcValue: '0.1850' },
  { id: '39', displayValue: '19.0%', calcValue: '0.1900' },
  { id: '40', displayValue: '19.5%', calcValue: '0.1950' },
  { id: '41', displayValue: '20.0%', calcValue: '0.2000' },
  { id: '42', displayValue: '20.5%', calcValue: '0.2050' },
  { id: '43', displayValue: '21.0%', calcValue: '0.2100' },
  { id: '44', displayValue: '21.5%', calcValue: '0.2150' },
  { id: '45', displayValue: '22.0%', calcValue: '0.2200' },
  { id: '46', displayValue: '22.5%', calcValue: '0.2250' },
  { id: '47', displayValue: '23.0%', calcValue: '0.2300' },
  { id: '48', displayValue: '23.5%', calcValue: '0.2350' },
  { id: '49', displayValue: '24.0%', calcValue: '0.2400' },
  { id: '50', displayValue: '24.5%', calcValue: '0.2450' },
  { id: '51', displayValue: '25.0%', calcValue: '0.2500' },
  { id: '52', displayValue: '25.5%', calcValue: '0.2550' },
  { id: '53', displayValue: '26.0%', calcValue: '0.2600' },
  { id: '54', displayValue: '26.5%', calcValue: '0.2650' },
  { id: '55', displayValue: '27.0%', calcValue: '0.2700' },
  { id: '56', displayValue: '27.5%', calcValue: '0.2750' },
  { id: '57', displayValue: '28.0%', calcValue: '0.2800' },
  { id: '58', displayValue: '28.5%', calcValue: '0.2850' },
  { id: '59', displayValue: '29.0%', calcValue: '0.2900' },
  { id: '60', displayValue: '29.5%', calcValue: '0.2950' },
  { id: '61', displayValue: '30.0%', calcValue: '0.3000' },
  { id: '62', displayValue: '30.5%', calcValue: '0.3050' },
  { id: '63', displayValue: '31.0%', calcValue: '0.3100' },
  { id: '64', displayValue: '31.5%', calcValue: '0.3150' },
  { id: '65', displayValue: '32.0%', calcValue: '0.3200' },
  { id: '66', displayValue: '32.5%', calcValue: '0.3250' },
  { id: '67', displayValue: '33.0%', calcValue: '0.3300' },
  { id: '68', displayValue: '33.5%', calcValue: '0.3350' },
  { id: '69', displayValue: '34.0%', calcValue: '0.3400' },
  { id: '70', displayValue: '34.5%', calcValue: '0.3450' },
  { id: '71', displayValue: '35.0%', calcValue: '0.3500' },
  { id: '72', displayValue: '35.5%', calcValue: '0.3550' },
  { id: '73', displayValue: '36.0%', calcValue: '0.3600' },
  { id: '74', displayValue: '36.5%', calcValue: '0.3650' },
  { id: '75', displayValue: '37.0%', calcValue: '0.3700' },
  { id: '76', displayValue: '37.5%', calcValue: '0.3750' },
  { id: '77', displayValue: '38.0%', calcValue: '0.3800' },
  { id: '78', displayValue: '38.5%', calcValue: '0.3850' },
  { id: '79', displayValue: '39.0%', calcValue: '0.3900' },
  { id: '80', displayValue: '39.5%', calcValue: '0.3950' },
  { id: '81', displayValue: '40.0%', calcValue: '0.4000' }
];

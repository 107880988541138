import { Directive, AfterViewInit, OnInit } from '@angular/core';
import { ElementQueries } from 'css-element-queries';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ovnElementQuery]',
  exportAs: 'ovnElementQuery'
})
export class ElementQueryDirective implements AfterViewInit {
  ngAfterViewInit() {
    ElementQueries.listen();
    ElementQueries.init();
  }
}

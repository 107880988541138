import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
  HostBinding,
  ElementRef,
  AfterContentInit,
  AfterContentChecked,
  Renderer2,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import PopperJs from 'popper.js';

import { RISKS } from '../../dictionaries';
import { SliderComponent, TickConfig, SliderChange } from '@ovation/slider';

@Component({
  selector: 'ovation-risk-slider',
  templateUrl: './risk-slider.component.html',
  styleUrls: ['./risk-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RiskSliderComponent implements OnInit, AfterViewInit {
  public tickConfig: TickConfig[];

  @HostBinding('class') class = 'ovn-risk-slider';
  @ViewChild(SliderComponent) baseSlider: SliderComponent;
  /** Reference to the inner slider wrapper element. */
  @ViewChild('sliderWrapper')
  private sliderWrapper: ElementRef;

  @ViewChild('thumbLabelIcon') private thumbLabelIcon: ElementRef;
  @ViewChild('thumbLabelText') private thumbLabelText: ElementRef;

  /** Event emitted when the slider value has changed. */
  @Output() readonly change: EventEmitter<any> = new EventEmitter<any>();

  private popper: PopperJs;

  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.tickConfig = RISKS;
  }

  ngAfterViewInit() {
    this.popper = new PopperJs(
      this.thumbLabelIcon.nativeElement,
      this.thumbLabelText.nativeElement,
      {
        placement: 'auto',
        modifiers: {
          flip: {
            behavior: ['left', 'right'],
            padding: 20,
            boundariesElement: this.el.nativeElement
          },
          preventOverflow: {
            boundariesElement: this.el.nativeElement,
            priority: ['right', 'left']
          }
        }
      }
    );

    /** Emit initial value */
    this.valueChanges({
      source: this.baseSlider,
      value: this.baseSlider.value
    });

    this.updateLabelPosition();
  }

  public formatLabelDisplay(value: number | null) {
    if (!value) {
      return 0;
    }

    if (this.tickConfig) {
      return this.tickConfig.find(item => item.value === value);
    }

    return value;
  }

  public sliderThumbMoves(event: any) {
    this.updateLabelPosition();
  }

  public updateLabelPosition() {
    setTimeout(() => {
      this.popper.scheduleUpdate();
      this.cd.markForCheck();
    }, 100);
  }

  public valueChanges(event: SliderChange) {
    this.change.emit({
      ...event,
      label: this.tickConfig.find(item => item.value === event.value).label
    });
  }
}

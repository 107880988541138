import { Injectable } from '@angular/core';

import { User } from '../models';
import {
  PROFILES,
  FUNDS,
  GRAPHDATA,
  CONTRIBUTIONS,
  WATCHLIST,
  BENEFITS,
  MORTALITY_FACTORS,
  REGIONS,
  TAXRATES,
  INVESTMENTS
} from './mock-data';
import {
  FULLRANGE_PERCENTAGE_OPTIONS,
  FULLRANGE_PERCENTAGE_OPTIONS_QUATERINC,
  GUARANTEE_PERIOD_OPTIONS,
  INVESTMENT_GROWTH,
  LUMPSUM_PERCENTAGE_OPTIONS,
  PARTNER_PERCENTAGE_OPTIONS,
  PENSION_INCREASE_OPTIONS
} from './mock-data/ui-lists';
import { MESSAGES } from '@ovation/messages';

import { InMemoryDbService, RequestInfo } from 'angular-in-memory-web-api';

@Injectable({
  providedIn: 'root'
})
export class DataServiceMock implements InMemoryDbService {
  constructor() {}

  createDb(reqInfo?: RequestInfo) {
    const users: User[] = [
      {
        id: 1,
        dateOfBirth: '1981-11-21T00:00:00+02:00',
        age: 37,
        retirementAge: 65,
        monthlySalary: 62500,
        yearlySalary: 750000,
        riskAppetite: 3,
        userMonthlyContribution: 0.05,
        employerMonthlyContribution: 0.05,
        userTaxBracket: 0.41,
        fundPerformance: {
          changePercentage: 0.05
        },
        funds: [
          {
            fund: {
              actualManagementFee: 1.25,
              changePercentage: 0.05,
              createdById: null,
              createdDateTime: '0001-01-01T00:00:00+00:00',
              fundFactSheetUrl:
                'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
              fundManagerName: 'Investec Fund Mgrs SA',
              fundManagerWebsite: 'www.investecassetmanagement.com',
              fundName: 'Investec Worldwide Equity FF H',
              id: 22,
              pensionProviderName: 'Dashboard Investec',
              performanceFee: 0,
              returnsLast3Years: 14.47,
              returnsLast5Years: 19.8,
              returnsMonthToDate: -0.52,
              returnsYearToDate: 24.61,
              riskRating: 3,
              starRating: 3,
              valuationDate: '2017-11-21T00:00:00+02:00'
            },
            contribution: 0.2,
            totalContributionAmount: 8250
          },
          {
            contribution: 0.2,
            totalContributionAmount: 8250,
            fund: {
              actualManagementFee: 0.96,
              changePercentage: -0.28,
              createdById: null,
              createdDateTime: '0001-01-01T00:00:00+00:00',
              fundFactSheetUrl:
                'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
              fundManagerName: 'Investec Fund Mgrs SA',
              fundManagerWebsite: 'www.investecassetmanagement.com',
              fundName: 'Investec Property Equity H',
              id: 24,
              pensionProviderName: 'Dashboard Investec',
              performanceFee: 0,
              returnsLast3Years: 11.26,
              returnsLast5Years: 13.81,
              returnsMonthToDate: 0.71,
              returnsYearToDate: 11.8,
              riskRating: 3,
              starRating: 4,
              valuationDate: '2017-11-21T00:00:00+02:00'
            }
          },
          {
            fund: {
              fundName: 'Investec Property Equity I',
              fundFactSheetUrl:
                'https://drive.google.com/file/d/14WPjdQin9g6rcjxLOK4ARZRtH4Sqt4Ok/view?usp=sharing',
              fundManagerName: 'Investec Fund Mgrs SA',
              fundManagerWebsite: 'www.investecassetmanagement.com',
              pensionProviderName: 'Dashboard Investec',
              actualManagementFee: 0.68,
              performanceFee: 0,
              valuationDate: '2017-11-21T00:00:00+02:00',
              changePercentage: -0.28,
              returnsMonthToDate: 0.73,
              returnsYearToDate: 12.08,
              returnsLast3Years: 11.57,
              returnsLast5Years: 14.14,
              starRating: 4,
              riskRating: 3,
              id: 25,
              createdDateTime: '0001-01-01T00:00:00+00:00',
              createdById: null
            },
            contribution: 0.6,
            totalContributionAmount: 16745
          }
        ],
        benefits: []
      },
      {
        id: 2,
        dateOfBirth: '1980-11-21T00:00:00+02:00',
        age: 38,
        retirementAge: 65,
        monthlySalary: 62500,
        yearlySalary: 750000,
        riskAppetite: null,
        funds: null,
        userMonthlyContribution: null,
        employerMonthlyContribution: null
      }
    ];

    const funds = [...FUNDS];

    const userFunds = [
      {
        id: 1,
        userId: 1,
        fundId: 20
      },
      {
        id: 2,
        userId: 1,
        fundId: 22
      },
      {
        id: 3,
        userId: 1,
        fundId: 23
      }
    ];

    const graphData = [...GRAPHDATA];

    const contributions = [...CONTRIBUTIONS];

    const watchlist = [...WATCHLIST];

    const benefits = [...BENEFITS];

    const messages = [...MESSAGES];

    const profiles = [...PROFILES];

    const mortalityFactors = [...MORTALITY_FACTORS];

    const regions = [...REGIONS];

    const taxrates = [...TAXRATES];

    const uiLists = {
      investmentGrowth: INVESTMENT_GROWTH,
      pensionIncreaseOptions: PENSION_INCREASE_OPTIONS,
      guaranteePeriodOptions: GUARANTEE_PERIOD_OPTIONS,
      partnerPercentageOptions: PARTNER_PERCENTAGE_OPTIONS,
      fullRangePercentageOptions: FULLRANGE_PERCENTAGE_OPTIONS,
      fullRangePercentageOptionsQuaterInc: FULLRANGE_PERCENTAGE_OPTIONS_QUATERINC,
      lumpSumPercentageOptions: LUMPSUM_PERCENTAGE_OPTIONS
    };

    const investments = [...INVESTMENTS];

    const progressBarDto = {
      TargetRetirementAmount: 5000000
    };

    const retirementPlanGetCurrentValueComponent = {
      MonthlyAnnuityIncome: 33000,
      RetirementSavingsTotal: 2600000
    };

    const retirementPlanGetFutureValueComponent = {
      RetirementSavingsProjectedTotal: 4250000,
      MonthlyAnnuityIncome: 420000
    };

    return {
      users,
      funds,
      userFunds,
      graphData,
      contributions,
      watchlist,
      benefits,
      messages,
      profiles,
      mortalityFactors,
      regions,
      taxrates,
      investmentGrowth: uiLists.investmentGrowth,
      pensionIncreaseOptions: uiLists.pensionIncreaseOptions,
      guaranteePeriodOptions: uiLists.guaranteePeriodOptions,
      partnerPercentageOptions: uiLists.partnerPercentageOptions,
      fullRangePercentageOptions: uiLists.fullRangePercentageOptions,
      fullRangePercentageOptionsQuaterInc:
        uiLists.fullRangePercentageOptionsQuaterInc,
      lumpSumPercentageOptions: uiLists.lumpSumPercentageOptions,
      investments,
      progressBarDto,
      retirementPlanGetCurrentValueComponent,
      retirementPlanGetFutureValueComponent
    };
  }
}

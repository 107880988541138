import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class OvationChart implements OnInit, OnDestroy {
  public legendPosition: 'bottom' | 'right' = 'bottom';
  public legendOrientation: 'vertical' | 'horizontal' = 'vertical';
  protected destroyed$ = new Subject<boolean>();

  constructor(protected breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this.breakpointObserver
      .observe('(min-width: 768px)')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((bs: BreakpointState) => {
        if (bs.matches) {
          this.legendPosition = 'right';
          this.legendOrientation = 'vertical';
        } else {
          this.legendPosition = 'bottom';
        }
      });

    this.breakpointObserver
      .observe('(min-width: 576px)')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((bs: BreakpointState) => {
        if (bs.matches) {
          this.legendOrientation = 'horizontal';
        } else {
          this.legendOrientation = 'vertical';
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

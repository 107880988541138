export const LUMPSUM_PERCENTAGE_OPTIONS = [
  { id: '1', displayValue: '0%', calcValue: '0.0000' },
  { id: '2', displayValue: '1.0%', calcValue: '0.0100' },
  { id: '3', displayValue: '2.0%', calcValue: '0.0200' },
  { id: '4', displayValue: '3.0%', calcValue: '0.0300' },
  { id: '5', displayValue: '4.0%', calcValue: '0.0400' },
  { id: '6', displayValue: '5.0%', calcValue: '0.0500' },
  { id: '7', displayValue: '6.0%', calcValue: '0.0600' },
  { id: '8', displayValue: '7.0%', calcValue: '0.0700' },
  { id: '9', displayValue: '8.0%', calcValue: '0.0800' },
  { id: '10', displayValue: '9.0%', calcValue: '0.0900' },
  { id: '11', displayValue: '10.0%', calcValue: '0.1000' },
  { id: '12', displayValue: '11.0%', calcValue: '0.1100' },
  { id: '13', displayValue: '12.0%', calcValue: '0.1200' },
  { id: '14', displayValue: '13.0%', calcValue: '0.1300' },
  { id: '15', displayValue: '14.0%', calcValue: '0.1400' },
  { id: '16', displayValue: '15.0%', calcValue: '0.1500' },
  { id: '17', displayValue: '16.0%', calcValue: '0.1600' },
  { id: '18', displayValue: '17.0%', calcValue: '0.1700' },
  { id: '19', displayValue: '18.0%', calcValue: '0.1800' },
  { id: '20', displayValue: '19.0%', calcValue: '0.1900' },
  { id: '21', displayValue: '20.0%', calcValue: '0.2000' },
  { id: '22', displayValue: '21.0%', calcValue: '0.2100' },
  { id: '23', displayValue: '22.0%', calcValue: '0.2200' },
  { id: '24', displayValue: '23.0%', calcValue: '0.2300' },
  { id: '25', displayValue: '24.0%', calcValue: '0.2400' },
  { id: '26', displayValue: '25.0%', calcValue: '0.2500' },
  { id: '27', displayValue: '26.0%', calcValue: '0.2600' },
  { id: '28', displayValue: '27.0%', calcValue: '0.2700' },
  { id: '29', displayValue: '28.0%', calcValue: '0.2800' },
  { id: '30', displayValue: '29.0%', calcValue: '0.2900' },
  { id: '31', displayValue: '30.0%', calcValue: '0.3000' },
  { id: '32', displayValue: '31.0%', calcValue: '0.3100' },
  { id: '33', displayValue: '32.0%', calcValue: '0.3200' },
  { id: '34', displayValue: '33.0%', calcValue: '0.3300' }
];

/**
 * OnlineBenefits Employee Client API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ReferenceDataDto } from '../model/referenceDataDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ReferenceDataService {
  protected basePath = 'http://localhost:26786';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param refType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referenceDataGetAll(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ReferenceDataDto>>;
  public referenceDataGetAll(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReferenceDataDto>>>;
  public referenceDataGetAll(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReferenceDataDto>>>;
  public referenceDataGetAll(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (refType === null || refType === undefined) {
      throw new Error(
        'Required parameter refType was null or undefined when calling referenceDataGetAll.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (refType !== undefined && refType !== null) {
      queryParameters = queryParameters.set('refType', <any>refType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ReferenceDataDto>>(
      `${this.basePath}/api/ReferenceData/GetAll`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param refType
   * @param code
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referenceDataGetByCode(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    code: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReferenceDataDto>;
  public referenceDataGetByCode(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    code: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ReferenceDataDto>>;
  public referenceDataGetByCode(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    code: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ReferenceDataDto>>;
  public referenceDataGetByCode(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    code: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (refType === null || refType === undefined) {
      throw new Error(
        'Required parameter refType was null or undefined when calling referenceDataGetByCode.'
      );
    }

    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling referenceDataGetByCode.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (refType !== undefined && refType !== null) {
      queryParameters = queryParameters.set('refType', <any>refType);
    }
    if (code !== undefined && code !== null) {
      queryParameters = queryParameters.set('code', <any>code);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ReferenceDataDto>(
      `${this.basePath}/api/ReferenceData/GetByCode`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param refType
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public referenceDataGetById(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReferenceDataDto>;
  public referenceDataGetById(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ReferenceDataDto>>;
  public referenceDataGetById(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ReferenceDataDto>>;
  public referenceDataGetById(
    refType:
      | 'Countries'
      | 'Genders'
      | 'MaritalStatuses'
      | 'OrganisationStates'
      | 'PensionSchemeTypes'
      | 'UnitTypes'
      | 'RiskCategories'
      | 'ReturnCategories'
      | 'FeeLevelCategories'
      | 'ContextEntityTypes'
      | 'Industries'
      | 'Notifications'
      | 'ContributionTypes'
      | 'PaymentTypes'
      | 'PayerTypes'
      | 'AddressTypes'
      | 'ContactTypes'
      | 'NotificationFormats'
      | 'FieldDelimiterTypes'
      | 'RowDelimiterTypes'
      | 'UploadTypes'
      | 'PayrollFrequencies'
      | 'PayrollStates'
      | 'PayrollItemTypes'
      | 'EmploymentStatuses'
      | 'LeaverProrateContributionRules'
      | 'JoinerProrateContributionRules'
      | 'WaitingPeriodCalculationRules'
      | 'AdjustmentProrateContributionRules'
      | 'LeavingReasons'
      | 'ContributionIncrements'
      | 'ContributionTaxBases'
      | 'BenefitTypes'
      | 'CommentTypes'
      | 'RelationshipTypes'
      | 'AgeBands'
      | 'PublishStates'
      | 'RetirementSavingTypes'
      | 'Widgets'
      | 'SubscriptionLevels'
      | 'Badges'
      | 'Ethnicities'
      | 'TradeInstructions'
      | 'CostBasisTypes'
      | 'ContributionDeductionTypes',
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (refType === null || refType === undefined) {
      throw new Error(
        'Required parameter refType was null or undefined when calling referenceDataGetById.'
      );
    }

    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling referenceDataGetById.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (refType !== undefined && refType !== null) {
      queryParameters = queryParameters.set('refType', <any>refType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ReferenceDataDto>(
      `${this.basePath}/api/ReferenceData/GetById/${encodeURIComponent(
        String(id)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

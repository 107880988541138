import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  Contribution,
  Fund,
  GraphDataPoint,
  User,
  WatchItem,
  Benefit,
  Profile,
  Investment
} from '../models';

import { Message } from '@ovation/messages';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private static httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  public getBirthDateValid(date: string): Observable<any> {
    return of(true);
  }

  public setPassword(password: string): Observable<any> {
    return of(true);
  }

  public getProfile(id: number): Observable<Profile> {
    return this.http.get<any>(`api/profiles/${id}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getRegion(id: number): Observable<any> {
    return this.http.get<any>(`api/regions/${id}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getTaxRates(): Observable<any> {
    return this.http.get<any>(`api/taxrates`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getUiLists(): Observable<any> {
    return this.http.get<any>(`api/uiLists`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getInvestmentGrowth(): Observable<any> {
    return this.http.get<any>(`api/investmentGrowth`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getPensionIncreaseOptions(): Observable<any> {
    return this.http.get<any>(`api/pensionIncreaseOptions`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getGuaranteePeriodOptions(): Observable<any> {
    return this.http.get<any>(`api/guaranteePeriodOptions`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getPartnerPercentageOptions(): Observable<any> {
    return this.http.get<any>(`api/partnerPercentageOptions`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getFullRangePercentageOptions(): Observable<any> {
    return this.http.get<any>(`api/fullRangePercentageOptions`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getFullRangePercentageOptionsQuaterInc(): Observable<any> {
    return this.http.get<any>(`api/fullRangePercentageOptionsQuaterInc`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }
  public getLumpSumPercentageOptions(): Observable<any> {
    return this.http.get<any>(`api/lumpSumPercentageOptions`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getMortalityFactors(): Observable<any[]> {
    return this.http.get<any[]>(`api/mortalityFactors`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getUser(id: number): Observable<User> {
    return this.http.get<User>(`api/users/${id}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public updateUser(user: User): Observable<any> {
    console.log('updateUser', user);

    return this.http
      .put<User>(`api/users/${user.id}`, user, DataService.httpOptions)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public getFunds(): Observable<Fund[]> {
    return this.http.get<Fund[]>(`api/funds`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getBenefits(): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(`api/benefits`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getFund(fundId: number): Observable<Fund[]> {
    return this.http.get<Fund[]>(`api/funds/${fundId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getBenefit(benefitId: number): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(`api/benefits/${benefitId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getFundsByRiskRating(riskRating: number) {
    return this.http.get<Fund[]>(`api/funds?riskRating=${riskRating}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getUserFunds(userId: number): Observable<Fund[]> {
    return this.http.get<Fund[]>(`api/userFunds?userId=${userId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getUserBenefits(userId: number): Observable<Benefit[]> {
    return this.http.get<Benefit[]>(`api/userBenefits?userId=${userId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public updateUserFunds(userId: number, userFunds: any[]): Observable<any> {
    return this.http
      .put<Fund[]>(
        `api/userFunds?userId=${userId}`,
        userFunds,
        DataService.httpOptions
      )
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public updateUserBenefits(
    userId: number,
    userBenefits: any[]
  ): Observable<any> {
    return this.http
      .put<Benefit[]>(
        `api/userBenefits?userId=${userId}`,
        userBenefits,
        DataService.httpOptions
      )
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public getContributions(userId?: number): Observable<Contribution[]> {
    return this.http
      .get<Contribution[]>(`api/contributions?userId=${userId}`)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  // public getUserFundsWithContributions(): Observable<FundContribution[]> {
  //   let pot = 100;

  //   return of(
  //     FUNDS.map((fund, i) => {
  //       let contrib;

  //       if (i < FUNDS.length - 1) {
  //         contrib = Math.floor(Math.random() * Math.floor(pot));
  //         pot = pot - contrib;
  //       } else {
  //         contrib = pot;
  //       }

  //       return {
  //         ...fund,
  //         contribution: contrib
  //       };
  //     })
  //   );
  // }

  public getGraphData(fundName: string): Observable<GraphDataPoint[]> {
    return this.http
      .get<GraphDataPoint[]>(`api/graphData?fundName=${fundName}`)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public getUserFundsWatchlist(userId?: number): Observable<WatchItem[]> {
    return this.http.get<WatchItem[]>(`api/watchlist?userId=${userId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public addUserFundsWatchlistItem(watchItem: WatchItem): Observable<any> {
    return this.http
      .post<WatchItem>(`api/watchlist`, watchItem, DataService.httpOptions)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public updateUserFundsWatchlistItem(watchItem: WatchItem): Observable<any> {
    return this.http
      .put<WatchItem>(
        `api/watchlist/${watchItem.id}`,
        watchItem,
        DataService.httpOptions
      )
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public deleteUserFundsWatchlistItem(watchItemId: number): Observable<any> {
    return this.http
      .delete(`api/watchlist/${watchItemId}`, DataService.httpOptions)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public getAllMessages(): Observable<Message[]> {
    return this.http.get<Message[]>(`api/messages`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getMessage(id: string): Observable<Message> {
    return this.http.get<Message>(`api/messages/${id}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public getUserInvestments(userId: number): Observable<Investment[]> {
    return this.http.get<Investment[]>(`api/investments?userId=${userId}`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public addInvestment(investment: Investment): Observable<any> {
    return this.http
      .post<Investment>(`api/investments`, investment, DataService.httpOptions)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public updateInvestment(investment: Investment): Observable<any> {
    console.log('updateInvestment', investment.id);

    return this.http
      .put<Investment>(
        `api/investments/${investment.id}`,
        investment,
        DataService.httpOptions
      )
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public deleteInvestment(watchItemId: number): Observable<any> {
    return this.http
      .delete(`api/investments/${watchItemId}`, DataService.httpOptions)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public retirementPlanGetProgressBarComponent(): Observable<any> {
    return this.http.get<any>(`api/progressBarDto`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  public retirementPlanGetCurrentValueComponent(): Observable<any> {
    return this.http
      .get<any>(`api/retirementPlanGetCurrentValueComponent`)
      .pipe(
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public retirementPlanGetFutureValueComponent(): Observable<any> {
    return this.http.get<any>(`api/retirementPlanGetFutureValueComponent`).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  private handleError(error: any) {
    console.error(error);
    return throwError(error);
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import {
  takeUntil,
  map,
  debounceTime,
  distinctUntilChanged,
  tap
} from 'rxjs/operators';
import * as moment from 'moment';

import { FundTable } from '../../classes';

@Component({
  selector: 'ovation-confirm-switch-contributions-table',
  templateUrl: './confirm-switch-contributions-table.component.html',
  styleUrls: ['./confirm-switch-contributions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ConfirmSwitchContributionsTableComponent extends FundTable
  implements OnChanges {
  @Input() increment = 1;
  @Input() switchAmounts: number[] = [];
  @Output() totalSwitchChange = new EventEmitter<number>();
  public amountToReinvest = 0;
  public valueDate = moment()
    .subtract(1, 'days')
    .format('DD/MM/YYYY');
  public amountToSwitch = 0;

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super(fb, cd, locale);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.data) {
      this.formGroup.addControl(
        'fundsSwitchInTotal',
        this.fb.control(0, [...this.pctValidators, Validators.min(100)])
      );
      this.formGroup.addControl('fundsSwitchIn', this.fb.array([]));
      this.formGroup.addControl('fundsTotalContributions', this.fb.array([]));

      for (let i = 0; i < this.data.length; i++) {
        (<FormArray>this.formGroup.controls.fundsSwitchIn).push(
          this.fb.control(0, this.pctValidators)
        );

        (<FormArray>this.formGroup.controls.fundsTotalContributions).push(
          this.fb.control(0, [])
        );
      }

      this.formGroup.controls.fundsSwitchIn.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          map(vals => {
            return vals.map(val => (val ? parseInt(<string>val, 10) : 0));
          }),
          map(vals => vals.reduce((acc, curr) => acc + curr, 0)),
          debounceTime(100),
          distinctUntilChanged(),
          tap(v => {
            this.updateSwitchTotal(v);

            this.cd.markForCheck();
          })
        )
        .subscribe();

      for (let i = 0; i < this.data.length; i++) {
        (<FormArray>this.formGroup.controls.fundsTotalContributions)
          .at(i)
          .setValue(this.data[i].totalContributionAmount);
      }
    }
  }

  private updateSwitchTotal(value: number) {
    this.formGroup.controls.fundsSwitchInTotal.setValue(value);
    this.amountToReinvest = (value / 100) * this.availableFunds;

    console.log('this.amountToReinvest', this.amountToReinvest);

    this.totalSwitchChange.emit(value);
  }
}

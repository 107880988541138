import {
  Component,
  ChangeDetectionStrategy,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  Input,
  HostBinding
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FundTable } from '../../classes';

@Component({
  selector: 'ovation-invested-funds-table',
  templateUrl: './invested-funds-table.component.html',
  styleUrls: ['./invested-funds-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InvestedFundsTableComponent extends FundTable {
  @HostBinding('class.ovn-fund-invested-table') id = true;
  @Input() increment = 1;

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    super(fb, cd, locale);
  }
}

export const FULLRANGE_PERCENTAGE_OPTIONS_QUATERINC = [
  { id: '1', displayValue: '0.00%', calcValue: '0.0000' },
  { id: '2', displayValue: '0.25%', calcValue: '0.0025' },
  { id: '3', displayValue: '0.50%', calcValue: '0.0050' },
  { id: '4', displayValue: '0.75%', calcValue: '0.0075' },
  { id: '5', displayValue: '1.00%', calcValue: '0.0100' },
  { id: '6', displayValue: '1.25%', calcValue: '0.0125' },
  { id: '7', displayValue: '1.50%', calcValue: '0.0150' },
  { id: '8', displayValue: '1.75%', calcValue: '0.0175' },
  { id: '9', displayValue: '2.00%', calcValue: '0.0200' },
  { id: '10', displayValue: '2.25%', calcValue: '0.0225' },
  { id: '11', displayValue: '2.50%', calcValue: '0.0250' },
  { id: '12', displayValue: '2.75%', calcValue: '0.0275' },
  { id: '13', displayValue: '3.00%', calcValue: '0.0300' },
  { id: '14', displayValue: '3.25%', calcValue: '0.0325' },
  { id: '15', displayValue: '3.50%', calcValue: '0.0350' },
  { id: '16', displayValue: '3.75%', calcValue: '0.0375' },
  { id: '17', displayValue: '4.00%', calcValue: '0.0400' },
  { id: '18', displayValue: '4.25%', calcValue: '0.0425' },
  { id: '19', displayValue: '4.50%', calcValue: '0.0450' },
  { id: '20', displayValue: '4.75%', calcValue: '0.0475' },
  { id: '21', displayValue: '5.00%', calcValue: '0.0500' },
  { id: '22', displayValue: '5.25%', calcValue: '0.0525' },
  { id: '23', displayValue: '5.50%', calcValue: '0.0550' },
  { id: '24', displayValue: '5.75%', calcValue: '0.0575' },
  { id: '25', displayValue: '6.00%', calcValue: '0.0600' },
  { id: '26', displayValue: '6.25%', calcValue: '0.0625' },
  { id: '27', displayValue: '6.50%', calcValue: '0.0650' },
  { id: '28', displayValue: '6.75%', calcValue: '0.0675' },
  { id: '29', displayValue: '7.00%', calcValue: '0.0700' },
  { id: '30', displayValue: '7.25%', calcValue: '0.0725' },
  { id: '31', displayValue: '7.50%', calcValue: '0.0750' },
  { id: '32', displayValue: '7.75%', calcValue: '0.0775' },
  { id: '33', displayValue: '8.00%', calcValue: '0.0800' },
  { id: '34', displayValue: '8.25%', calcValue: '0.0825' },
  { id: '35', displayValue: '8.50%', calcValue: '0.0850' },
  { id: '36', displayValue: '8.75%', calcValue: '0.0875' },
  { id: '37', displayValue: '9.00%', calcValue: '0.0900' },
  { id: '38', displayValue: '9.25%', calcValue: '0.0925' },
  { id: '39', displayValue: '9.50%', calcValue: '0.0950' },
  { id: '40', displayValue: '9.75%', calcValue: '0.0975' },
  { id: '41', displayValue: '10.00%', calcValue: '0.1000' },
  { id: '42', displayValue: '10.25%', calcValue: '0.1025' },
  { id: '43', displayValue: '10.50%', calcValue: '0.1050' },
  { id: '44', displayValue: '10.75%', calcValue: '0.1075' },
  { id: '45', displayValue: '11.00%', calcValue: '0.1100' },
  { id: '46', displayValue: '11.25%', calcValue: '0.1125' },
  { id: '47', displayValue: '11.50%', calcValue: '0.1150' },
  { id: '48', displayValue: '11.75%', calcValue: '0.1175' },
  { id: '49', displayValue: '12.00%', calcValue: '0.1200' },
  { id: '50', displayValue: '12.25%', calcValue: '0.1225' },
  { id: '51', displayValue: '12.50%', calcValue: '0.1250' },
  { id: '52', displayValue: '12.75%', calcValue: '0.1275' },
  { id: '53', displayValue: '13.00%', calcValue: '0.1300' },
  { id: '54', displayValue: '13.25%', calcValue: '0.1325' },
  { id: '55', displayValue: '13.50%', calcValue: '0.1350' },
  { id: '56', displayValue: '13.75%', calcValue: '0.1375' },
  { id: '57', displayValue: '14.00%', calcValue: '0.1400' },
  { id: '58', displayValue: '14.25%', calcValue: '0.1425' },
  { id: '59', displayValue: '14.50%', calcValue: '0.1450' },
  { id: '60', displayValue: '14.75%', calcValue: '0.1475' },
  { id: '61', displayValue: '15.00%', calcValue: '0.1500' },
  { id: '62', displayValue: '15.25%', calcValue: '0.1525' },
  { id: '63', displayValue: '15.50%', calcValue: '0.1550' },
  { id: '64', displayValue: '15.75%', calcValue: '0.1575' },
  { id: '65', displayValue: '16.00%', calcValue: '0.1600' },
  { id: '66', displayValue: '16.25%', calcValue: '0.1625' },
  { id: '67', displayValue: '16.50%', calcValue: '0.1650' },
  { id: '68', displayValue: '16.75%', calcValue: '0.1675' },
  { id: '69', displayValue: '17.00%', calcValue: '0.1700' },
  { id: '70', displayValue: '17.25%', calcValue: '0.1725' },
  { id: '71', displayValue: '17.50%', calcValue: '0.1750' },
  { id: '72', displayValue: '17.75%', calcValue: '0.1775' },
  { id: '73', displayValue: '18.00%', calcValue: '0.1800' },
  { id: '74', displayValue: '18.25%', calcValue: '0.1825' },
  { id: '75', displayValue: '18.50%', calcValue: '0.1850' },
  { id: '76', displayValue: '18.75%', calcValue: '0.1875' },
  { id: '77', displayValue: '19.00%', calcValue: '0.1900' },
  { id: '78', displayValue: '19.25%', calcValue: '0.1925' },
  { id: '79', displayValue: '19.50%', calcValue: '0.1950' },
  { id: '80', displayValue: '19.75%', calcValue: '0.1975' },
  { id: '81', displayValue: '20.00%', calcValue: '0.2000' },
  { id: '82', displayValue: '20.25%', calcValue: '0.2025' },
  { id: '83', displayValue: '20.50%', calcValue: '0.2050' },
  { id: '84', displayValue: '20.75%', calcValue: '0.2075' },
  { id: '85', displayValue: '21.00%', calcValue: '0.2100' },
  { id: '86', displayValue: '21.25%', calcValue: '0.2125' },
  { id: '87', displayValue: '21.50%', calcValue: '0.2150' },
  { id: '88', displayValue: '21.75%', calcValue: '0.2175' },
  { id: '89', displayValue: '22.00%', calcValue: '0.2200' },
  { id: '90', displayValue: '22.25%', calcValue: '0.2225' },
  { id: '91', displayValue: '22.50%', calcValue: '0.2250' },
  { id: '92', displayValue: '22.75%', calcValue: '0.2275' },
  { id: '93', displayValue: '23.00%', calcValue: '0.2300' },
  { id: '94', displayValue: '23.25%', calcValue: '0.2325' },
  { id: '95', displayValue: '23.50%', calcValue: '0.2350' },
  { id: '96', displayValue: '23.75%', calcValue: '0.2375' },
  { id: '97', displayValue: '24.00%', calcValue: '0.2400' },
  { id: '98', displayValue: '24.25%', calcValue: '0.2425' },
  { id: '99', displayValue: '24.50%', calcValue: '0.2450' },
  { id: '100', displayValue: '24.75%', calcValue: '0.2475' },
  { id: '101', displayValue: '25.00%', calcValue: '0.2500' },
  { id: '102', displayValue: '25.25%', calcValue: '0.2525' },
  { id: '103', displayValue: '25.50%', calcValue: '0.2550' },
  { id: '104', displayValue: '25.75%', calcValue: '0.2575' },
  { id: '105', displayValue: '26.00%', calcValue: '0.2600' },
  { id: '106', displayValue: '26.25%', calcValue: '0.2625' },
  { id: '107', displayValue: '26.50%', calcValue: '0.2650' },
  { id: '108', displayValue: '26.75%', calcValue: '0.2675' },
  { id: '109', displayValue: '27.00%', calcValue: '0.2700' },
  { id: '110', displayValue: '27.25%', calcValue: '0.2725' },
  { id: '111', displayValue: '27.50%', calcValue: '0.2750' },
  { id: '112', displayValue: '27.75%', calcValue: '0.2775' },
  { id: '113', displayValue: '28.00%', calcValue: '0.2800' },
  { id: '114', displayValue: '28.25%', calcValue: '0.2825' },
  { id: '115', displayValue: '28.50%', calcValue: '0.2850' },
  { id: '116', displayValue: '28.75%', calcValue: '0.2875' },
  { id: '117', displayValue: '29.00%', calcValue: '0.2900' },
  { id: '118', displayValue: '29.25%', calcValue: '0.2925' },
  { id: '119', displayValue: '29.50%', calcValue: '0.2950' },
  { id: '120', displayValue: '29.75%', calcValue: '0.2975' },
  { id: '121', displayValue: '30.00%', calcValue: '0.3000' },
  { id: '122', displayValue: '30.25%', calcValue: '0.3025' },
  { id: '123', displayValue: '30.50%', calcValue: '0.3050' },
  { id: '124', displayValue: '30.75%', calcValue: '0.3075' },
  { id: '125', displayValue: '31.00%', calcValue: '0.3100' },
  { id: '126', displayValue: '31.25%', calcValue: '0.3125' },
  { id: '127', displayValue: '31.50%', calcValue: '0.3150' },
  { id: '128', displayValue: '31.75%', calcValue: '0.3175' },
  { id: '129', displayValue: '32.00%', calcValue: '0.3200' },
  { id: '130', displayValue: '32.25%', calcValue: '0.3225' },
  { id: '131', displayValue: '32.50%', calcValue: '0.3250' },
  { id: '132', displayValue: '32.75%', calcValue: '0.3275' },
  { id: '133', displayValue: '33.00%', calcValue: '0.3300' },
  { id: '134', displayValue: '33.25%', calcValue: '0.3325' },
  { id: '135', displayValue: '33.50%', calcValue: '0.3350' },
  { id: '136', displayValue: '33.75%', calcValue: '0.3375' },
  { id: '137', displayValue: '34.00%', calcValue: '0.3400' },
  { id: '138', displayValue: '34.25%', calcValue: '0.3425' },
  { id: '139', displayValue: '34.50%', calcValue: '0.3450' },
  { id: '140', displayValue: '34.75%', calcValue: '0.3475' },
  { id: '141', displayValue: '35.00%', calcValue: '0.3500' },
  { id: '142', displayValue: '35.25%', calcValue: '0.3525' },
  { id: '143', displayValue: '35.50%', calcValue: '0.3550' },
  { id: '144', displayValue: '35.75%', calcValue: '0.3575' },
  { id: '145', displayValue: '36.00%', calcValue: '0.3600' },
  { id: '146', displayValue: '36.25%', calcValue: '0.3625' },
  { id: '147', displayValue: '36.50%', calcValue: '0.3650' },
  { id: '148', displayValue: '36.75%', calcValue: '0.3675' },
  { id: '149', displayValue: '37.00%', calcValue: '0.3700' },
  { id: '150', displayValue: '37.25%', calcValue: '0.3725' },
  { id: '151', displayValue: '37.50%', calcValue: '0.3750' },
  { id: '152', displayValue: '37.75%', calcValue: '0.3775' },
  { id: '153', displayValue: '38.00%', calcValue: '0.3800' },
  { id: '154', displayValue: '38.25%', calcValue: '0.3825' },
  { id: '155', displayValue: '38.50%', calcValue: '0.3850' },
  { id: '156', displayValue: '38.75%', calcValue: '0.3875' },
  { id: '157', displayValue: '39.00%', calcValue: '0.3900' },
  { id: '158', displayValue: '39.25%', calcValue: '0.3925' },
  { id: '159', displayValue: '39.50%', calcValue: '0.3950' },
  { id: '160', displayValue: '39.75%', calcValue: '0.3975' },
  { id: '161', displayValue: '40.00%', calcValue: '0.4000' },
  { id: '162', displayValue: '40.25%', calcValue: '0.4025' },
  { id: '163', displayValue: '40.50%', calcValue: '0.4050' },
  { id: '164', displayValue: '40.75%', calcValue: '0.4075' },
  { id: '165', displayValue: '41.00%', calcValue: '0.4100' },
  { id: '166', displayValue: '41.25%', calcValue: '0.4125' },
  { id: '167', displayValue: '41.50%', calcValue: '0.4150' },
  { id: '168', displayValue: '41.75%', calcValue: '0.4175' },
  { id: '169', displayValue: '42.00%', calcValue: '0.4200' },
  { id: '170', displayValue: '42.25%', calcValue: '0.4225' },
  { id: '171', displayValue: '42.50%', calcValue: '0.4250' },
  { id: '172', displayValue: '42.75%', calcValue: '0.4275' },
  { id: '173', displayValue: '43.00%', calcValue: '0.4300' },
  { id: '174', displayValue: '43.25%', calcValue: '0.4325' },
  { id: '175', displayValue: '43.50%', calcValue: '0.4350' },
  { id: '176', displayValue: '43.75%', calcValue: '0.4375' },
  { id: '177', displayValue: '44.00%', calcValue: '0.4400' },
  { id: '178', displayValue: '44.25%', calcValue: '0.4425' },
  { id: '179', displayValue: '44.50%', calcValue: '0.4450' },
  { id: '180', displayValue: '44.75%', calcValue: '0.4475' },
  { id: '181', displayValue: '45.00%', calcValue: '0.4500' },
  { id: '182', displayValue: '45.25%', calcValue: '0.4525' },
  { id: '183', displayValue: '45.50%', calcValue: '0.4550' },
  { id: '184', displayValue: '45.75%', calcValue: '0.4575' },
  { id: '185', displayValue: '46.00%', calcValue: '0.4600' },
  { id: '186', displayValue: '46.25%', calcValue: '0.4625' },
  { id: '187', displayValue: '46.50%', calcValue: '0.4650' },
  { id: '188', displayValue: '46.75%', calcValue: '0.4675' },
  { id: '189', displayValue: '47.00%', calcValue: '0.4700' },
  { id: '190', displayValue: '47.25%', calcValue: '0.4725' },
  { id: '191', displayValue: '47.50%', calcValue: '0.4750' },
  { id: '192', displayValue: '47.75%', calcValue: '0.4775' },
  { id: '193', displayValue: '48.00%', calcValue: '0.4800' },
  { id: '194', displayValue: '48.25%', calcValue: '0.4825' },
  { id: '195', displayValue: '48.50%', calcValue: '0.4850' },
  { id: '196', displayValue: '48.75%', calcValue: '0.4875' },
  { id: '197', displayValue: '49.00%', calcValue: '0.4900' },
  { id: '198', displayValue: '49.25%', calcValue: '0.4925' },
  { id: '199', displayValue: '49.50%', calcValue: '0.4950' },
  { id: '200', displayValue: '49.75%', calcValue: '0.4975' },
  { id: '201', displayValue: '50.00%', calcValue: '0.5000' },
  { id: '202', displayValue: '50.25%', calcValue: '0.5025' },
  { id: '203', displayValue: '50.50%', calcValue: '0.5050' },
  { id: '204', displayValue: '50.75%', calcValue: '0.5075' },
  { id: '205', displayValue: '51.00%', calcValue: '0.5100' },
  { id: '206', displayValue: '51.25%', calcValue: '0.5125' },
  { id: '207', displayValue: '51.50%', calcValue: '0.5150' },
  { id: '208', displayValue: '51.75%', calcValue: '0.5175' },
  { id: '209', displayValue: '52.00%', calcValue: '0.5200' },
  { id: '210', displayValue: '52.25%', calcValue: '0.5225' },
  { id: '211', displayValue: '52.50%', calcValue: '0.5250' },
  { id: '212', displayValue: '52.75%', calcValue: '0.5275' },
  { id: '213', displayValue: '53.00%', calcValue: '0.5300' },
  { id: '214', displayValue: '53.25%', calcValue: '0.5325' },
  { id: '215', displayValue: '53.50%', calcValue: '0.5350' },
  { id: '216', displayValue: '53.75%', calcValue: '0.5375' },
  { id: '217', displayValue: '54.00%', calcValue: '0.5400' },
  { id: '218', displayValue: '54.25%', calcValue: '0.5425' },
  { id: '219', displayValue: '54.50%', calcValue: '0.5450' },
  { id: '220', displayValue: '54.75%', calcValue: '0.5475' },
  { id: '221', displayValue: '55.00%', calcValue: '0.5500' },
  { id: '222', displayValue: '55.25%', calcValue: '0.5525' },
  { id: '223', displayValue: '55.50%', calcValue: '0.5550' },
  { id: '224', displayValue: '55.75%', calcValue: '0.5575' },
  { id: '225', displayValue: '56.00%', calcValue: '0.5600' },
  { id: '226', displayValue: '56.25%', calcValue: '0.5625' },
  { id: '227', displayValue: '56.50%', calcValue: '0.5650' },
  { id: '228', displayValue: '56.75%', calcValue: '0.5675' },
  { id: '229', displayValue: '57.00%', calcValue: '0.5700' },
  { id: '230', displayValue: '57.25%', calcValue: '0.5725' },
  { id: '231', displayValue: '57.50%', calcValue: '0.5750' },
  { id: '232', displayValue: '57.75%', calcValue: '0.5775' },
  { id: '233', displayValue: '58.00%', calcValue: '0.5800' },
  { id: '234', displayValue: '58.25%', calcValue: '0.5825' },
  { id: '235', displayValue: '58.50%', calcValue: '0.5850' },
  { id: '236', displayValue: '58.75%', calcValue: '0.5875' },
  { id: '237', displayValue: '59.00%', calcValue: '0.5900' },
  { id: '238', displayValue: '59.25%', calcValue: '0.5925' },
  { id: '239', displayValue: '59.50%', calcValue: '0.5950' },
  { id: '240', displayValue: '59.75%', calcValue: '0.5975' },
  { id: '241', displayValue: '60.00%', calcValue: '0.6000' },
  { id: '242', displayValue: '60.25%', calcValue: '0.6025' },
  { id: '243', displayValue: '60.50%', calcValue: '0.6050' },
  { id: '244', displayValue: '60.75%', calcValue: '0.6075' },
  { id: '245', displayValue: '61.00%', calcValue: '0.6100' },
  { id: '246', displayValue: '61.25%', calcValue: '0.6125' },
  { id: '247', displayValue: '61.50%', calcValue: '0.6150' },
  { id: '248', displayValue: '61.75%', calcValue: '0.6175' },
  { id: '249', displayValue: '62.00%', calcValue: '0.6200' },
  { id: '250', displayValue: '62.25%', calcValue: '0.6225' },
  { id: '251', displayValue: '62.50%', calcValue: '0.6250' },
  { id: '252', displayValue: '62.75%', calcValue: '0.6275' },
  { id: '253', displayValue: '63.00%', calcValue: '0.6300' },
  { id: '254', displayValue: '63.25%', calcValue: '0.6325' },
  { id: '255', displayValue: '63.50%', calcValue: '0.6350' },
  { id: '256', displayValue: '63.75%', calcValue: '0.6375' },
  { id: '257', displayValue: '64.00%', calcValue: '0.6400' },
  { id: '258', displayValue: '64.25%', calcValue: '0.6425' },
  { id: '259', displayValue: '64.50%', calcValue: '0.6450' },
  { id: '260', displayValue: '64.75%', calcValue: '0.6475' },
  { id: '261', displayValue: '65.00%', calcValue: '0.6500' },
  { id: '262', displayValue: '65.25%', calcValue: '0.6525' },
  { id: '263', displayValue: '65.50%', calcValue: '0.6550' },
  { id: '264', displayValue: '65.75%', calcValue: '0.6575' },
  { id: '265', displayValue: '66.00%', calcValue: '0.6600' },
  { id: '266', displayValue: '66.25%', calcValue: '0.6625' },
  { id: '267', displayValue: '66.50%', calcValue: '0.6650' },
  { id: '268', displayValue: '66.75%', calcValue: '0.6675' },
  { id: '269', displayValue: '67.00%', calcValue: '0.6700' },
  { id: '270', displayValue: '67.25%', calcValue: '0.6725' },
  { id: '271', displayValue: '67.50%', calcValue: '0.6750' },
  { id: '272', displayValue: '67.75%', calcValue: '0.6775' },
  { id: '273', displayValue: '68.00%', calcValue: '0.6800' },
  { id: '274', displayValue: '68.25%', calcValue: '0.6825' },
  { id: '275', displayValue: '68.50%', calcValue: '0.6850' },
  { id: '276', displayValue: '68.75%', calcValue: '0.6875' },
  { id: '277', displayValue: '69.00%', calcValue: '0.6900' },
  { id: '278', displayValue: '69.25%', calcValue: '0.6925' },
  { id: '279', displayValue: '69.50%', calcValue: '0.6950' },
  { id: '280', displayValue: '69.75%', calcValue: '0.6975' },
  { id: '281', displayValue: '70.00%', calcValue: '0.7000' },
  { id: '282', displayValue: '70.25%', calcValue: '0.7025' },
  { id: '283', displayValue: '70.50%', calcValue: '0.7050' },
  { id: '284', displayValue: '70.75%', calcValue: '0.7075' },
  { id: '285', displayValue: '71.00%', calcValue: '0.7100' },
  { id: '286', displayValue: '71.25%', calcValue: '0.7125' },
  { id: '287', displayValue: '71.50%', calcValue: '0.7150' },
  { id: '288', displayValue: '71.75%', calcValue: '0.7175' },
  { id: '289', displayValue: '72.00%', calcValue: '0.7200' },
  { id: '290', displayValue: '72.25%', calcValue: '0.7225' },
  { id: '291', displayValue: '72.50%', calcValue: '0.7250' },
  { id: '292', displayValue: '72.75%', calcValue: '0.7275' },
  { id: '293', displayValue: '73.00%', calcValue: '0.7300' },
  { id: '294', displayValue: '73.25%', calcValue: '0.7325' },
  { id: '295', displayValue: '73.50%', calcValue: '0.7350' },
  { id: '296', displayValue: '73.75%', calcValue: '0.7375' },
  { id: '297', displayValue: '74.00%', calcValue: '0.7400' },
  { id: '298', displayValue: '74.25%', calcValue: '0.7425' },
  { id: '299', displayValue: '74.50%', calcValue: '0.7450' },
  { id: '300', displayValue: '74.75%', calcValue: '0.7475' },
  { id: '301', displayValue: '75.00%', calcValue: '0.7500' },
  { id: '302', displayValue: '75.25%', calcValue: '0.7525' },
  { id: '303', displayValue: '75.50%', calcValue: '0.7550' },
  { id: '304', displayValue: '75.75%', calcValue: '0.7575' },
  { id: '305', displayValue: '76.00%', calcValue: '0.7600' },
  { id: '306', displayValue: '76.25%', calcValue: '0.7625' },
  { id: '307', displayValue: '76.50%', calcValue: '0.7650' },
  { id: '308', displayValue: '76.75%', calcValue: '0.7675' },
  { id: '309', displayValue: '77.00%', calcValue: '0.7700' },
  { id: '310', displayValue: '77.25%', calcValue: '0.7725' },
  { id: '311', displayValue: '77.50%', calcValue: '0.7750' },
  { id: '312', displayValue: '77.75%', calcValue: '0.7775' },
  { id: '313', displayValue: '78.00%', calcValue: '0.7800' },
  { id: '314', displayValue: '78.25%', calcValue: '0.7825' },
  { id: '315', displayValue: '78.50%', calcValue: '0.7850' },
  { id: '316', displayValue: '78.75%', calcValue: '0.7875' },
  { id: '317', displayValue: '79.00%', calcValue: '0.7900' },
  { id: '318', displayValue: '79.25%', calcValue: '0.7925' },
  { id: '319', displayValue: '79.50%', calcValue: '0.7950' },
  { id: '320', displayValue: '79.75%', calcValue: '0.7975' },
  { id: '321', displayValue: '80.00%', calcValue: '0.8000' },
  { id: '322', displayValue: '80.25%', calcValue: '0.8025' },
  { id: '323', displayValue: '80.50%', calcValue: '0.8050' },
  { id: '324', displayValue: '80.75%', calcValue: '0.8075' },
  { id: '325', displayValue: '81.00%', calcValue: '0.8100' },
  { id: '326', displayValue: '81.25%', calcValue: '0.8125' },
  { id: '327', displayValue: '81.50%', calcValue: '0.8150' },
  { id: '328', displayValue: '81.75%', calcValue: '0.8175' },
  { id: '329', displayValue: '82.00%', calcValue: '0.8200' },
  { id: '330', displayValue: '82.25%', calcValue: '0.8225' },
  { id: '331', displayValue: '82.50%', calcValue: '0.8250' },
  { id: '332', displayValue: '82.75%', calcValue: '0.8275' },
  { id: '333', displayValue: '83.00%', calcValue: '0.8300' },
  { id: '334', displayValue: '83.25%', calcValue: '0.8325' },
  { id: '335', displayValue: '83.50%', calcValue: '0.8350' },
  { id: '336', displayValue: '83.75%', calcValue: '0.8375' },
  { id: '337', displayValue: '84.00%', calcValue: '0.8400' },
  { id: '338', displayValue: '84.25%', calcValue: '0.8425' },
  { id: '339', displayValue: '84.50%', calcValue: '0.8450' },
  { id: '340', displayValue: '84.75%', calcValue: '0.8475' },
  { id: '341', displayValue: '85.00%', calcValue: '0.8500' },
  { id: '342', displayValue: '85.25%', calcValue: '0.8525' },
  { id: '343', displayValue: '85.50%', calcValue: '0.8550' },
  { id: '344', displayValue: '85.75%', calcValue: '0.8575' },
  { id: '345', displayValue: '86.00%', calcValue: '0.8600' },
  { id: '346', displayValue: '86.25%', calcValue: '0.8625' },
  { id: '347', displayValue: '86.50%', calcValue: '0.8650' },
  { id: '348', displayValue: '86.75%', calcValue: '0.8675' },
  { id: '349', displayValue: '87.00%', calcValue: '0.8700' },
  { id: '350', displayValue: '87.25%', calcValue: '0.8725' },
  { id: '351', displayValue: '87.50%', calcValue: '0.8750' },
  { id: '352', displayValue: '87.75%', calcValue: '0.8775' },
  { id: '353', displayValue: '88.00%', calcValue: '0.8800' },
  { id: '354', displayValue: '88.25%', calcValue: '0.8825' },
  { id: '355', displayValue: '88.50%', calcValue: '0.8850' },
  { id: '356', displayValue: '88.75%', calcValue: '0.8875' },
  { id: '357', displayValue: '89.00%', calcValue: '0.8900' },
  { id: '358', displayValue: '89.25%', calcValue: '0.8925' },
  { id: '359', displayValue: '89.50%', calcValue: '0.8950' },
  { id: '360', displayValue: '89.75%', calcValue: '0.8975' },
  { id: '361', displayValue: '90.00%', calcValue: '0.9000' },
  { id: '362', displayValue: '90.25%', calcValue: '0.9025' },
  { id: '363', displayValue: '90.50%', calcValue: '0.9050' },
  { id: '364', displayValue: '90.75%', calcValue: '0.9075' },
  { id: '365', displayValue: '91.00%', calcValue: '0.9100' },
  { id: '366', displayValue: '91.25%', calcValue: '0.9125' },
  { id: '367', displayValue: '91.50%', calcValue: '0.9150' },
  { id: '368', displayValue: '91.75%', calcValue: '0.9175' },
  { id: '369', displayValue: '92.00%', calcValue: '0.9200' },
  { id: '370', displayValue: '92.25%', calcValue: '0.9225' },
  { id: '371', displayValue: '92.50%', calcValue: '0.9250' },
  { id: '372', displayValue: '92.75%', calcValue: '0.9275' },
  { id: '373', displayValue: '93.00%', calcValue: '0.9300' },
  { id: '374', displayValue: '93.25%', calcValue: '0.9325' },
  { id: '375', displayValue: '93.50%', calcValue: '0.9350' },
  { id: '376', displayValue: '93.75%', calcValue: '0.9375' },
  { id: '377', displayValue: '94.00%', calcValue: '0.9400' },
  { id: '378', displayValue: '94.25%', calcValue: '0.9425' },
  { id: '379', displayValue: '94.50%', calcValue: '0.9450' },
  { id: '380', displayValue: '94.75%', calcValue: '0.9475' },
  { id: '381', displayValue: '95.00%', calcValue: '0.9500' },
  { id: '382', displayValue: '95.25%', calcValue: '0.9525' },
  { id: '383', displayValue: '95.50%', calcValue: '0.9550' },
  { id: '384', displayValue: '95.75%', calcValue: '0.9575' },
  { id: '385', displayValue: '96.00%', calcValue: '0.9600' },
  { id: '386', displayValue: '96.25%', calcValue: '0.9625' },
  { id: '387', displayValue: '96.50%', calcValue: '0.9650' },
  { id: '388', displayValue: '96.75%', calcValue: '0.9675' },
  { id: '389', displayValue: '97.00%', calcValue: '0.9700' },
  { id: '390', displayValue: '97.25%', calcValue: '0.9725' },
  { id: '391', displayValue: '97.50%', calcValue: '0.9750' },
  { id: '392', displayValue: '97.75%', calcValue: '0.9775' },
  { id: '393', displayValue: '98.00%', calcValue: '0.9800' },
  { id: '394', displayValue: '98.25%', calcValue: '0.9825' },
  { id: '395', displayValue: '98.50%', calcValue: '0.9850' },
  { id: '396', displayValue: '98.75%', calcValue: '0.9875' },
  { id: '397', displayValue: '99.00%', calcValue: '0.9900' },
  { id: '398', displayValue: '99.25%', calcValue: '0.9925' },
  { id: '399', displayValue: '99.50%', calcValue: '0.9950' },
  { id: '400', displayValue: '99.75%', calcValue: '0.9975' },
  { id: '401', displayValue: '100.00%', calcValue: '1.0000' }
];

import { BehaviorSubject, combineLatest } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import { UtilitiesService } from '../services';
import { Scheme } from './scheme.interface';
import { Policy } from './policy.interface';
import { Band } from './band.interface';
import { BandsEnum } from '../enums';
import { PensionPeriod } from './pension-period';

export class Pension {
  private scheme: Scheme;
  public name: any;
  public managed: boolean;
  public annualManagementCharge: any;
  public dateJoined: any;
  // The grossInvestmentReturn is currently set at the profile level and applied to all pensions, i.e.: this isn't currently being used.
  public grossInvestmentReturn: any = parseFloat('0.0500');
  public initialValuation$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public band$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _band: any;
  public get band(): any {
    return this._band;
  }
  public set band(value: any) {
    this._band = value;
    this.band$.next(value);
  }

  public initialValuationDate$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public netInvestmentReturn: any;
  public inflatedValuation$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private _currentFundValue: any;
  public get currentFundValue(): any {
    return this._currentFundValue;
  }
  public set currentFundValue(value: any) {
    this._currentFundValue = value;
    this.inflatedValuation$.next(value);
  }

  public selectedEmployeeRate$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public selectedCompanyRate$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public rates: any;
  public contributions: any;
  public taxRelief$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public monthlyContributions: any = {
    taxRelief$: new BehaviorSubject<any>(null),
    company$: new BehaviorSubject<any>(null),
    employee$: new BehaviorSubject<any>(null)
  };
  public netEmployeeContribution$: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(0);

  get totalMonthlyAmount() {
    let _totalAmount = 0;
    if (this.monthlyContributions) {
      _totalAmount =
        this.monthlyContributions.company$.value +
        this.monthlyContributions.employee$.value;
    }

    return _totalAmount;
  }

  public finalValuation$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  private _finalValuation: any = 0;
  set finalValuation(value: any) {
    this._finalValuation = value;
    this.finalValuation$.next(value);
  }

  get finalValuation() {
    let _finalValuation = this._finalValuation;
    if (this.assumptions && this.term && _finalValuation > 0) {
      const _adjustForInflation = this.adjustForInflation$.value;

      if (_adjustForInflation) {
        const _inflation = parseFloat(this.assumptions.inflation);
        const _termYears = parseInt(this.term.years, 10);
        const _termMonths = parseInt(this.term.months, 10);

        const _years = _termYears + _termMonths / 12;
        const _compoundedInflation = Math.pow(1 + _inflation, _years);

        _finalValuation = _finalValuation / _compoundedInflation;
      }
    }

    return _finalValuation;
  }

  public periods$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _periods: any;
  public get periods(): any {
    return this._periods;
  }
  public set periods(value: any) {
    this._periods = value;
    this.periods$.next(value);
  }

  public annualPension: any;
  public monthlyContributionBreakdown$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  private term: any;
  private assumptions: any;
  private policy: Policy;

  private _companyRate: any;
  private companyRate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get companyRate(): any {
    if (!this._companyRate) {
      return 0.0;
    } else {
      return this.selectedCompanyRate$.value.calcValue;
    }
  }
  public set companyRate(value: any) {
    this._companyRate = value;
    this.companyRate$.next(value);
    this.selectedCompanyRate$.next(value);
  }

  private _employeeRate: number;
  private employeeRate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get employeeRate(): number {
    if (!this.selectedEmployeeRate$.value) {
      return 0.0;
    } else {
      return this.selectedEmployeeRate$.value;
    }
  }
  public set employeeRate(value: number) {
    this._employeeRate = value;
    this.employeeRate$.next(value);
    this.selectedEmployeeRate$.next(value);
  }

  public companyContributions$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(null);
  private _companyContributions: number;
  public get companyContributions(): number {
    let _companyContribution = 0;

    if (this._companyContributions) {
      _companyContribution = this.companyRate * this.annualSalary;
    }

    return _companyContribution;
  }
  public set companyContributions(value: number) {
    this._companyContributions = value;
    this.companyContributions$.next(value);
  }

  public employeeContributions$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(null);
  private _employeeContributions: number;
  public get employeeContributions(): number {
    return this._employeeContributions;
  }
  public set employeeContributions(value: number) {
    this._employeeContributions = value;
    this.employeeContributions$.next(value);
  }

  public contributionAmount$: BehaviorSubject<number> = new BehaviorSubject<
    number
  >(null);
  private _contributionAmount: number;
  public get contributionAmount(): number {
    if (!this.managed) {
      return this._contributionAmount;
    }

    let _contributionsAmount = 0;

    if (this.companyContributions && this.employeeContributions) {
      _contributionsAmount =
        this.companyContributions + this.employeeContributions;
    }

    this.contributionAmount = _contributionsAmount;

    return _contributionsAmount;
  }
  public set contributionAmount(value: number) {
    this._contributionAmount = value;
    this.contributionAmount$.next(value);
  }

  public contributionsLimitReached$: BehaviorSubject<boolean>;
  private contributionsIncreaseRate$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);

  private _annualSalary: number;
  public get annualSalary(): number {
    return this._annualSalary;
  }
  public set annualSalary(value: number) {
    this._annualSalary = value;
  }

  private adjustForInflation$: BehaviorSubject<any>;
  private dateOfRetirement$: BehaviorSubject<any>;
  private salaryIncrease$: BehaviorSubject<any>;
  private annualSalary$: BehaviorSubject<number>;
  private employerContributionAmount$: BehaviorSubject<any>;
  private employeeContributionAmount$: BehaviorSubject<any>;
  private fullRangePercentageOptionsQuaterInc: any[];

  constructor(
    scheme: Scheme,
    term: any,
    assumptions: any,
    fullRangePercentageOptionsQuaterInc: any[],
    grossInvestmentReturn: number,
    annualSalary: any,
    adjustForInflation$: BehaviorSubject<any>,
    contributionsLimitReached$: BehaviorSubject<boolean>,
    dateOfRetirement$: BehaviorSubject<any>,
    salaryIncrease$: BehaviorSubject<any>,
    annualSalary$: BehaviorSubject<number>,
    employerContributionAmount$: BehaviorSubject<any>,
    employeeContributionAmount$: BehaviorSubject<any>,
    public utilitiesService: UtilitiesService
  ) {
    this.scheme = scheme;
    this.policy = scheme.policy;
    this.term = term;
    this.assumptions = assumptions;
    this.annualSalary = annualSalary;
    this.adjustForInflation$ = adjustForInflation$;
    this.contributionsLimitReached$ = contributionsLimitReached$;
    this.dateOfRetirement$ = dateOfRetirement$;
    this.salaryIncrease$ = salaryIncrease$;
    this.annualSalary$ = annualSalary$;
    this.employerContributionAmount$ = employerContributionAmount$;
    this.employeeContributionAmount$ = employeeContributionAmount$;

    const yearDate = this.utilitiesService.yearDate();

    this.fullRangePercentageOptionsQuaterInc = fullRangePercentageOptionsQuaterInc;

    this.managed = scheme.managed;
    this.annualManagementCharge = this.assumptions.annualManagementCharge;

    if (grossInvestmentReturn) {
      this.grossInvestmentReturn = grossInvestmentReturn;
    }

    this.netInvestmentReturn =
      (1 + this.grossInvestmentReturn) /
        (1 + parseFloat(this.annualManagementCharge)) -
      1;

    const _employeeRate = fullRangePercentageOptionsQuaterInc.find(item => {
      return (
        parseFloat(item.calcValue) ===
        parseFloat(this.policy.contributions.employee)
      );
    });

    this.employeeRate = _employeeRate ? parseFloat(_employeeRate.calcValue) : 0;

    this.companyRate = fullRangePercentageOptionsQuaterInc.find(item => {
      return item.calcValue === this.policy.contributions.company;
    });

    if (this.managed) {
      // Contributions banding for a managed pension
      const contributionBands = this.policy.contributions.band;

      this.band = {
        type: contributionBands.type,
        title: contributionBands.title,
        helpText: contributionBands.helpText,
        helpTextVisible: contributionBands.helpText == null ? false : true,
        bands: [...contributionBands.bands]
      };
    }

    this.rates = {
      company: this.companyRate,
      employee: this.employeeRate,
      contributionsIncrease: this.policy.contributionsIncreaseRate
    };

    this.initialValuation$.next(this.policy.initialFunding);

    const initialValuationDateValue =
      this.policy.initialValuationDate &&
      this.policy.initialValuationDate !== ''
        ? new Date(this.policy.initialValuationDate)
        : yearDate;

    this.initialValuationDate$.next(initialValuationDateValue);

    // SELECTED COMPANY RATE
    combineLatest(this.selectedEmployeeRate$, this.band$)
      .pipe(skipWhile(([employeeRate, band]) => !employeeRate || !band))
      .subscribe(([employeeRate, band]) => {
        // if this is a managed fund, ensure the company contribution is correct
        if (this.managed && band.type === BandsEnum.Matching) {
          const _currentCompany = this.utilitiesService.getCompanyRateFromBand(
            band.bands,
            employeeRate
          );

          this.companyRate = this.fullRangePercentageOptionsQuaterInc.find(
            item => {
              return parseFloat(item.calcValue) === _currentCompany;
            }
          );
        }
      });

    combineLatest(this.initialValuation$, this.initialValuationDate$)
      .pipe(
        skipWhile(
          ([initialValuation, initialValuationDate]) =>
            !initialValuation || !initialValuationDate
        )
      )
      .subscribe(([initialValuation, initialValuationDate]) => {
        const fromDate = new Date(initialValuationDate);
        const toDate = yearDate;
        const months = this.utilitiesService.DateDiff.inMonths(
          fromDate,
          toDate
        );
        const years = months / 12;

        const _initialValuation = initialValuation;
        const _netInvestmentReturn = this.netInvestmentReturn;

        const inflatedValuation =
          _initialValuation * Math.pow(1 + _netInvestmentReturn, years);

        this.currentFundValue = inflatedValuation;
      });

    this.employeeRate$.pipe(skipWhile(v => !v)).subscribe(employeeRate => {
      this.employeeContributions = employeeRate * this.annualSalary;
    });

    this.companyRate$.pipe(skipWhile(v => !v)).subscribe(companyRate => {
      this.companyContributions = this.companyRate * this.annualSalary;
    });

    combineLatest(
      this.employeeContributions$,
      this.employeeRate$,
      this.companyRate$
    )
      .pipe
      // skipWhile(
      //   ([employeeContributions, employeeRate, companyRate]) =>
      //     employeeContributions === undefined ||
      //     employeeContributions ||
      //     null ||
      //     employeeRate === undefined ||
      //     employeeRate ||
      //     null ||
      //     companyRate === undefined ||
      //     companyRate ||
      //     null
      // )
      ()
      .subscribe(([employeeContributions, employeeRate, companyRate]) => {
        let _taxRelief = 0;
        // only ever against the managed pension
        if (this.managed) {
          // tax efficiency warning
          const _taxReliefRate = parseFloat(this.assumptions.taxRelief);
          const _employeeRate = parseFloat(employeeRate);
          const _companyRate = parseFloat(companyRate);
          const isNotTaxEfficient =
            _companyRate + _employeeRate <= _taxReliefRate;
          this.contributionsLimitReached$.next(!isNotTaxEfficient);

          const _annualSalary = this.annualSalary;
          const _taxBand = this.assumptions.taxBand;
          const _employeeContributionAmount = employeeContributions;
          const _preContribution = _annualSalary * _taxBand;
          const _postContribution =
            (_annualSalary - _employeeContributionAmount) * _taxBand;

          _taxRelief = _preContribution - _postContribution;
        }
        this.taxRelief$.next(_taxRelief);
      });

    combineLatest(
      this.taxRelief$,
      this.companyContributions$,
      this.employeeContributions$
    )
      .pipe
      // skipWhile(
      //   ([taxRelief, companyContributions, employeeContributions]) =>
      //     taxRelief === undefined ||
      //     taxRelief === null ||
      //     companyContributions === undefined ||
      //     companyContributions === null ||
      //     employeeContributions === undefined ||
      //     employeeContributions === null
      // )
      ()
      .subscribe(([taxRelief, companyContributions, employeeContributions]) => {
        let _taxRelief = 0;
        if (taxRelief) {
          const _taxReliefRate = taxRelief;
          _taxRelief = _taxReliefRate / 12;
        }
        this.monthlyContributions.taxRelief$.next(_taxRelief);

        let _companyContribution = 0;
        if (companyContributions) {
          const _companyContributionRate = companyContributions;
          _companyContribution = _companyContributionRate / 12;
        }

        this.monthlyContributions.company$.next(_companyContribution);

        let _employeeContribution = 0;
        if (employeeContributions) {
          const _employeeContributionRate = employeeContributions;
          _employeeContribution = _employeeContributionRate / 12;
        }
        this.monthlyContributions.employee$.next(_employeeContribution);
      });

    // netEmployeeContribution
    combineLatest(
      this.employeeContributions$,
      this.monthlyContributions.taxRelief$
    )
      .pipe
      // skipWhile(
      //   ([employeeContributions, taxRelief]) =>
      //     employeeContributions === undefined ||
      //     employeeContributions === null ||
      //     taxRelief === undefined ||
      //     taxRelief === null
      // )
      ()
      .subscribe(([employeeContributions, taxRelief]) => {
        let _employeeContribution = 0;
        if (employeeContributions && taxRelief) {
          const _employeeContributionRate = employeeContributions;
          _employeeContribution = _employeeContributionRate / 12 - taxRelief;
        }

        this.netEmployeeContribution$.next(_employeeContribution);
      });

    // for unmanaged funds use the user-defined monthly contributions amount
    if (!this.managed) {
      this.contributionAmount = parseFloat(
        this.policy.contributions.contributionAmount
      );
    } else if (this.policy.contributions.band.type === BandsEnum.Matching) {
      const _currentCompany = this.utilitiesService.getCompanyRateFromBand(
        this.policy.contributions.band.bands,
        this.employeeRate$.value
      );

      const _companyRate = this.fullRangePercentageOptionsQuaterInc.find(
        item => {
          return parseFloat(item.calcValue) === _currentCompany;
        }
      );

      this.selectedCompanyRate$.next(_companyRate);
    }

    // Pension Periods
    combineLatest(this.dateOfRetirement$, this.employeeRate$)
      .pipe(
        skipWhile(
          ([dateOfRetirement, employeeRate]) =>
            !dateOfRetirement || !employeeRate
        )
      )
      .subscribe(([dateOfRetirement, employeeRate]) => {
        this.setPensionPeriods(dateOfRetirement);
      });
  }

  private setPensionPeriods(dateOfRetirement: Date): void {
    const _projectionStartDate = this.utilitiesService.yearDate();
    const _projectionEndDate = dateOfRetirement;
    let _periodStartDate = _projectionStartDate;

    // const _employeeContributionRate = pension.employeeRate;
    // const _employerContributionRate = pension.companyRate;
    // const _netInvestmentReturn = pension.netInvestmentReturn;
    let _currentFundValue = this.currentFundValue;
    const _salaryIncrease = this.salaryIncrease$.value; //self.rates.contributionsIncrease();
    let _annualSalary = this.annualSalary$.value;
    // const _contributionAmount = pension.contributionAmount;
    let _employerContributionAmount = 0;
    let _employeeContributionAmount = 0;
    let _isFirstPeriod = true;
    const _start = _projectionStartDate.getFullYear();
    const _end = _projectionEndDate.getFullYear();

    let _period: PensionPeriod = null;

    for (let i = _start; i <= _end; i++) {
      _period = new PensionPeriod(
        this,
        _projectionStartDate,
        _projectionEndDate,
        _periodStartDate,
        _isFirstPeriod,
        _salaryIncrease,
        _annualSalary,
        _currentFundValue
      );

      // modify variables for next period calculations
      _periodStartDate = _period.endDate;
      _isFirstPeriod = false;
      _currentFundValue = _period.valuation;
      _annualSalary = _period.salary;
      _employerContributionAmount += _period.employerContributionAmount;
      _employeeContributionAmount += _period.employeeContributionAmount;
    }

    if (this.managed) {
      // this.annualSalary$.next(_annualSalary);
      // this.employerContributionAmount$.next(_employerContributionAmount);
      // this.employeeContributionAmount$.next(_employeeContributionAmount);
    }

    this.finalValuation = _currentFundValue;
  }
}

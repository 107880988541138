import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  LOCALE_ID,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FundTable } from '../../classes';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'ovation-monthly-contributions-table',
  templateUrl: './monthly-contributions-table.component.html',
  styleUrls: ['./monthly-contributions-table.component.scss']
})
export class MonthlyContributionsTableComponent implements OnChanges {
  @Input() monthlySalary = 0;
  @Input() yearlySalary = 0;
  @Input() userMonthlyContributionPct = 0;
  @Input() employerMonthlyContributionPct = 0;
  @Input() taxReliefThreshold = 0;
  @Input() userTaxBracket = 0;

  public userContributionAmount = 0;
  public employerContributionAmount = 0;

  constructor(
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.userContributionAmount =
      this.monthlySalary * this.userMonthlyContributionPct;
    this.employerContributionAmount =
      this.monthlySalary * this.employerMonthlyContributionPct;
  }
}

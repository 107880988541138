import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { IncrementerComponent } from './incrementer.component';

@NgModule({
  imports: [CommonModule, AngularSvgIconModule],
  declarations: [IncrementerComponent],
  exports: [IncrementerComponent]
})
export class IncrementerModule {}

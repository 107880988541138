import { Profile } from './profile.interface';
import { Region } from './region.interface';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { skipWhile, map, tap } from 'rxjs/operators';
import { Pension } from './pension';
import { UtilitiesService } from '../services';
import { Scheme } from './scheme.interface';
import { PensionPeriod } from './pension-period';
import { Calculations } from './calculations';
import { Output, EventEmitter } from '@angular/core';

export class ProfileModel {
  private static partnerBirthDateOffset: number = 3;

  @Output() update = new EventEmitter<ProfileModel>();

  private utilitiesService: UtilitiesService;

  private _profile: Profile;
  public get profile(): Profile {
    return this._profile;
  }
  public set profile(value: Profile) {
    this._profile = value;
    this.profile$.next(value);
  }

  public profile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(
    null
  );
  public calculations: Calculations;
  private rates$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private links$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public adjustForInflation$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public assumptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public annuity$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private schemes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private region$: BehaviorSubject<Region> = new BehaviorSubject<Region>(null);
  private _region: Region;
  public get region(): Region {
    return this._region;
  }
  public set region(value: Region) {
    this._region = value;
    this.region$.next(value);
  }

  private taxRates$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private fullRangePercentageOptions$: BehaviorSubject<any[]>;
  private grossInvestmentReturn$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  private pensionIncreaseOptions$: BehaviorSubject<any[]>;
  private guaranteePeriodOptions$: BehaviorSubject<any[]>;
  private partnerPercentageOptions$: BehaviorSubject<any[]>;

  public selectedGrossInvestmentGrowth$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public grossInvestmentGrowth$: BehaviorSubject<any> = new BehaviorSubject<
    any
  >(null);
  public salaryIncrease$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public name$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public gender$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public birthDate$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public birthYear$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public ageOfRetirement$ = new BehaviorSubject<number>(null);
  private _ageOfRetirement: number;
  public get ageOfRetirement(): number {
    return this._ageOfRetirement;
  }
  public set ageOfRetirement(value: number) {
    this._ageOfRetirement = value;
    this.ageOfRetirement$.next(value);

    this.update.emit({ ...this, ageOfRetirement: value });
  }

  public dateOfRetirement$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public employmentStartDate$ = new BehaviorSubject<any>(null);
  public annualSalary$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public partnerGender$ = new BehaviorSubject<any>(null);
  private _partnerBirthDate$ = new BehaviorSubject<any>(null);

  public partnerBirthDate$: Observable<any>;
  public pensions$ = new BehaviorSubject<Pension[]>([]);
  public selectedPensionIncrease$: Observable<any>;
  public selectedGuaranteePeriod$: Observable<any>;
  public selectedPartnerPercentage$: Observable<any>;
  public managedPension$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public employeeContributionRateManaged$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public totalUnmanagedContribution$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public totalPensionsValue$: Observable<any>;
  public managedPensions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public unmanagedPensions$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);

  private fullRangePercentageOptionsQuaterInc$: BehaviorSubject<
    any[]
  > = new BehaviorSubject<any[]>(null);

  public employerContributionAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);
  public employeeContributionAmount$: BehaviorSubject<
    any
  > = new BehaviorSubject<any>(null);

  public contributionsLimitReached$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  constructor(
    profileData: Profile,
    region: Region,
    taxRates: any,
    utilitiesService: UtilitiesService,
    fullRangePercentageOptions$: BehaviorSubject<any[]>,
    fullRangePercentageOptionsQuaterInc$: BehaviorSubject<any[]>,
    pensionIncreaseOptions$: BehaviorSubject<any[]>,
    guaranteePeriodOptions$: BehaviorSubject<any[]>,
    partnerPercentageOptions$: BehaviorSubject<any[]>
  ) {
    this.profile = profileData;
    this.region = region;
    this.utilitiesService = utilitiesService;
    this.fullRangePercentageOptions$ = fullRangePercentageOptions$;
    this.fullRangePercentageOptionsQuaterInc$ = fullRangePercentageOptionsQuaterInc$;
    this.pensionIncreaseOptions$ = pensionIncreaseOptions$;
    this.guaranteePeriodOptions$ = guaranteePeriodOptions$;
    this.partnerPercentageOptions$ = partnerPercentageOptions$;
    this.schemes$.next(this.profile.schemes);
    this.taxRates$.next(taxRates);

    // this.calculations = new Calculations(this, this.assumptions$, this.utilitiesService);

    this.partnerBirthDate$ = this.profile$.pipe(
      skipWhile(profile => !profile),
      map(profile => {
        if (profile.partnerBirthDate && profile.partnerBirthDate !== '') {
          return new Date(profile.partnerBirthDate);
        } else {
          let _partnerBirthDate: Date;
          const _birthDate = new Date(profile.birthDate);
          if (profile.partnerGender === 'Male') {
            _partnerBirthDate = new Date(
              _birthDate.getFullYear() + ProfileModel.partnerBirthDateOffset,
              _birthDate.getMonth(),
              _birthDate.getDate()
            );
          } else {
            _partnerBirthDate = new Date(
              _birthDate.getFullYear() - ProfileModel.partnerBirthDateOffset,
              _birthDate.getMonth(),
              _birthDate.getDate()
            );
          }
          return _partnerBirthDate;
        }
      })
    );

    combineLatest(this.profile$, this.fullRangePercentageOptions$)
      .pipe(
        map(([profile, fullRangePercentageOptions]) => {
          return fullRangePercentageOptions.find(item => {
            return item.calcValue === profile.employment.grossInvestmentReturn;
          });
        })
      )
      .subscribe(val => {
        this.grossInvestmentReturn$.next(val);
      });

    this.grossInvestmentReturn$.subscribe(val => {
      this.grossInvestmentGrowth$.next(val);
      this.selectedGrossInvestmentGrowth$.next(val);
    });

    combineLatest(this.profile$, this.fullRangePercentageOptions$)
      .pipe(
        map(([profile, fullRangePercentageOptions]) => {
          return fullRangePercentageOptions.find(item => {
            return item.calcValue === profile.employment.rates.salaryIncrease;
          });
        }),
        map(val => parseFloat(val.calcValue))
      )
      .subscribe(val => this.salaryIncrease$.next(val));

    combineLatest(this.birthDate$, this.ageOfRetirement$)
      .pipe(
        skipWhile(([birthDate, retirementAge]) => !birthDate || !retirementAge),
        map(([birthDate, retirementAge]) => {
          const _birthDate = new Date(birthDate);

          const y = _birthDate.getFullYear() + retirementAge;

          const m = _birthDate.getMonth();
          const d = _birthDate.getDate();
          return new Date(y, m, d);
        })
      )
      .subscribe(val => {
        this.dateOfRetirement$.next(val);
      });

    this.selectedPensionIncrease$ = combineLatest(
      this.profile$,
      this.pensionIncreaseOptions$
    ).pipe(
      map(([profile, pensionIncreaseOptions]) => {
        return pensionIncreaseOptions.find(item => {
          return item.calcValue === profile.annuity.pensionIncrease;
        });
      })
    );

    this.selectedGuaranteePeriod$ = combineLatest(
      this.profile$,
      this.guaranteePeriodOptions$
    ).pipe(
      map(([profile, guaranteePeriodOptions]) => {
        return guaranteePeriodOptions.find(item => {
          return item.calcValue === profile.annuity.guaranteePeriod;
        });
      })
    );

    this.selectedPartnerPercentage$ = combineLatest(
      this.profile$,
      this.partnerPercentageOptions$
    ).pipe(
      map(([profile, partnerPercentageOptions]) => {
        return partnerPercentageOptions.find(item => {
          return item.calcValue === profile.annuity.partnerPercentage;
        });
      })
    );

    this.unmanagedPensions$
      .pipe(
        skipWhile(
          unmanagedPensions => !unmanagedPensions || !unmanagedPensions.length
        ),
        map(unmanagedPensions => {
          let _unmanagedContribution = 0;

          unmanagedPensions.forEach((pensionItem: Pension) => {
            _unmanagedContribution += pensionItem.contributionAmount$.value
              ? pensionItem.contributionAmount$.value
              : 0;
          });

          return _unmanagedContribution;
        })
      )
      .subscribe(val => {
        this.totalUnmanagedContribution$.next(val);
      });

    this.totalPensionsValue$ = this.pensions$.pipe(
      map(pensions => {
        let _totalValue = 0;
        pensions.forEach(pensionItem => {
          _totalValue += parseFloat(pensionItem.currentFundValue);
        });
        return _totalValue;
      })
    );

    this.profile$.pipe(skipWhile(profile => !profile)).subscribe(profile => {
      this.name$.next(profile.name);
      this.gender$.next(profile.gender);
      this.birthDate$.next(profile.birthDate);
      this.birthYear$.next(new Date(profile.birthDate).getFullYear());
      this.ageOfRetirement = parseInt(profile.retirementAge, 10);
      this.partnerGender$.next(profile.partnerGender);
      this._partnerBirthDate$.next(profile.partnerBirthDate);
      this.links$.next(profile.links);
      this.adjustForInflation$.next(profile.adjustForInflation);
      this.employmentStartDate$.next(profile.employment.startDate);
      this.annualSalary$.next(parseFloat(profile.annualSalary));
      this.schemes$.next(profile.schemes);
      this.rates$.next(profile.employment.rates);
    });

    this.pensions$
      .pipe(skipWhile(pensions => !pensions || !pensions.length))
      .subscribe(pensions => {
        // const _pensions = pensions.map(pension => {
        //   if (pension.rates) {
        //     this.setPensionPeriods(pension);
        //   }

        //   return pension;
        // });

        const managedPensions = pensions.filter(pension => {
          return pension.managed === true;
        });

        const unManagedPensions = pensions.filter(pension => {
          return pension.managed === false;
        });

        const employeeContributionRateManaged: number = managedPensions.length
          ? managedPensions[0].employeeRate
          : 0;

        this.managedPension$.next(managedPensions[0]);
        this.managedPensions$.next(managedPensions);
        this.unmanagedPensions$.next(unManagedPensions);

        this.employeeContributionRateManaged$.next(
          employeeContributionRateManaged
        );
      });

    // MANAGED PENSION
    this.managedPension$
      .pipe(skipWhile(val => !val))
      .subscribe((pension: Pension) => {
        this.employeeContributionRateManaged$.next(pension.employeeRate);
        // if (pension.rates) {
        //   this.setPensionPeriods(pension);
        // }
      });

    // SET ASSUMPTIONS
    combineLatest(this.profile$, this.region$, this.taxRates$)
      .pipe(
        skipWhile(
          ([profile, region, taxRates]) => !profile || !region || !taxRates
        )
      )
      .subscribe(([profile, region, taxRates]) => {
        this.assumptions$.next({
          annualManagementCharge: region.annualManagementCharge,
          lowerInvestment: profile.employment.rates.low,
          midInvestment: profile.employment.rates.medium,
          highInvestment: profile.employment.rates.high,
          yieldAdjustment: profile.employment.rates.yieldAdjustment,
          ilg5: profile.employment.rates.ilg5,
          ilg0: profile.employment.rates.ilg0,
          annuityExpense: profile.annuity.expenseAssumption,
          inflation: region.inflation,
          taxFreeCashPercentage: parseFloat(<string>(
            (region.taxFreeCashMax.percentage as unknown)
          )),
          taxRelief: region.taxEfficiencyLimit,
          taxBand:
            profile.taxBand !== '0.0000'
              ? profile.taxBand
              : this.utilitiesService.getTaxRateFromSalary(
                  taxRates,
                  profile.annualSalary
                ),
          mediumAdjustment: profile.employment.rates.mediumAdjustment,
          lowAdjustment: profile.employment.rates.lowAdjustment
        });
      });

    // ANNUITY
    combineLatest(
      this.selectedPartnerPercentage$,
      this.selectedGuaranteePeriod$,
      this.selectedPensionIncrease$,
      this.profile$
    )
      .pipe(
        map(
          ([partnerPercentage, guaranteePeriod, pensionIncrease, profile]) => {
            return {
              partnerPercentage,
              guaranteePeriod,
              pensionIncrease,
              monthlyPaymentAdjustment: profile.annuity.monthlyPaymentAdjustment
            };
          }
        )
      )
      .subscribe(annuity => {
        this.annuity$.next(annuity);
      });

    this.setPensions();
  }

  // populate pensions after initial instantiations so that the PensionModel can register it's computed dependencies

  public setPensions() {
    const schemes = this.schemes$.value;

    for (let i = 0; i < schemes.length; i++) {
      const pensionItem = schemes[i];

      if (pensionItem.policy !== undefined) {
        this.addPension(pensionItem);
      }
    }
    // set the pensions tab for View/Edit to managed for initial binding
    // app.ui().variables.selectedPensionItem(this.managedPension());
  }

  public addPension(pensionItem: Scheme) {
    const fullRangePercentageOptionsQuaterInc: any[] = this.fullRangePercentageOptionsQuaterInc$.getValue();

    const grossInvestmentReturn = parseFloat(
      this.selectedGrossInvestmentGrowth$.value.calcValue
    );

    const pension = new Pension(
      pensionItem,
      this.rates$.value,
      this.assumptions$.value,
      fullRangePercentageOptionsQuaterInc,
      grossInvestmentReturn,
      this.annualSalary$.value,
      this.adjustForInflation$,
      this.contributionsLimitReached$,
      this.dateOfRetirement$,
      this.salaryIncrease$,
      this.annualSalary$,
      this.employerContributionAmount$,
      this.employeeContributionAmount$,
      this.utilitiesService
    );

    // if (pension.rates) {
    //   this.setPensionPeriods(pension);
    // }

    this.pensions$.next([...this.pensions$.value, pension]);
  }

  // public addTempPension(pensionItem: Scheme) {
  //   const _pension = new Pension(pensionItem);
  //   return _pension;
  // }

  public removePension(pensionItem: Scheme) {
    const pensions = this.pensions$.value;

    this.pensions$.next(
      pensions.filter(item => item.name !== pensionItem.name)
    );
  }

  public getProfile(): Observable<Profile> {
    return this.profile$;
  }

  // private setPensionPeriods(pension: Pension): void {
  //   const _projectionStartDate = this.utilitiesService.yearDate();
  //   const _projectionEndDate = this.dateOfRetirement$.value;
  //   let _periodStartDate = _projectionStartDate;

  //   // const _employeeContributionRate = pension.employeeRate;
  //   // const _employerContributionRate = pension.companyRate;
  //   // const _netInvestmentReturn = pension.netInvestmentReturn;
  //   let _currentFundValue = pension.currentFundValue;
  //   const _salaryIncrease = this.salaryIncrease$.value; //self.rates.contributionsIncrease();
  //   let _annualSalary = this.annualSalary$.value;
  //   // const _contributionAmount = pension.contributionAmount;
  //   let _employerContributionAmount = 0;
  //   let _employeeContributionAmount = 0;

  //   let _isFirstPeriod = true;
  //   const _start = _projectionStartDate.getFullYear();
  //   const _end = _projectionEndDate.getFullYear();

  //   let _period: PensionPeriod = null;

  //   for (let i = _start; i <= _end; i++) {
  //     _period = new PensionPeriod(
  //       pension,
  //       _projectionStartDate,
  //       _projectionEndDate,
  //       _periodStartDate,
  //       _isFirstPeriod,
  //       _salaryIncrease,
  //       _annualSalary
  //     );

  //     // modify variables for next period calculations
  //     _periodStartDate = _period.endDate;
  //     _isFirstPeriod = false;
  //     _currentFundValue = _period.valuation;
  //     _annualSalary = _period.salary;
  //     _employerContributionAmount += _period.employerContributionAmount;
  //     _employeeContributionAmount += _period.employeeContributionAmount;

  //     console.log('_currentFundValue', _currentFundValue);
  //   }

  //   if (pension.managed) {
  //     this.annualSalary$.next(_annualSalary);

  //     this.employerContributionAmount$.next(_employerContributionAmount);
  //     this.employeeContributionAmount$.next(_employeeContributionAmount);
  //   }

  //   pension.finalValuation = _currentFundValue;
  // }
}

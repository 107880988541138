import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  FormsModule as AngularFormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import {
  RestrictInputDirective,
  DecimalFormatterDirective,
  ClearInputDirective
} from './directives';

@NgModule({
  imports: [CommonModule],
  declarations: [
    RestrictInputDirective,
    DecimalFormatterDirective,
    ClearInputDirective
  ],
  exports: [
    AngularFormsModule,
    ReactiveFormsModule,
    RestrictInputDirective,
    DecimalFormatterDirective,
    ClearInputDirective
  ],
  providers: [DecimalPipe]
})
export class FormsModule {}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  HostBinding
} from '@angular/core';
import { Fund, WatchItem } from '@ovation/core';

@Component({
  selector: 'ovation-fund-card',
  templateUrl: './fund-card.component.html',
  styleUrls: ['./fund-card.component.scss']
})
export class FundCardComponent implements OnChanges {
  @HostBinding('class.ovn-fund-card') class = 'ovn-fund-card';
  @Input() fund: Fund;
  @Input() layout: 'normal' | 'compact' | 'horizontal' = 'normal';
  @Input() showAddButton = false;
  @Input() watch: WatchItem = undefined;
  @Output() add = new EventEmitter();
  @Output() watchChange = new EventEmitter();
  @Output() removeWatch = new EventEmitter();
  @Output() addWatch = new EventEmitter();

  public inWatchList: WatchItem = undefined;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.watch) {
      this.inWatchList = {
        ...this.watch,
        ...(this.watch.value ? { value: this.watch.value * 100 } : {})
      };

      if (!this.inWatchList) {
        this.inWatchList = {
          fundId: undefined,
          alert: undefined,
          value: undefined
        };
      }
    }
  }

  public getAbs(data: number): number {
    return Math.abs(data);
  }

  public addFund() {
    this.add.emit(this.fund.id);
  }

  public updateWatch(alert?: boolean, value?: number) {
    this.inWatchList = {
      ...this.inWatchList,
      ...(alert ? { alert } : {}),
      ...(value ? { value: value * 100 } : {})
    };

    if (this.inWatchList.fundId) {
      if (this.inWatchList.id) {
        this.watchChange.emit({
          ...this.inWatchList,
          ...(this.inWatchList.value
            ? { value: this.inWatchList.value / 100 }
            : {})
        });
      } else {
        this.addWatch.emit({
          ...this.inWatchList
        });
      }
    }
  }

  public toggleWatch() {
    if (!!this.watch) {
      this.removeWatch.emit(this.watch.id);
    } else {
      this.inWatchList = {
        ...this.inWatchList,
        fundId: this.fund.id
      };

      this.updateWatch();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { NgDatepickerModule } from 'ng2-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';

import { ExpansionModule } from '@ovation/expansion';
import { RatingsModule } from '@ovation/ratings';
import { SliderModule } from '@ovation/slider';
import { CheckboxModule } from '@ovation/checkbox';
import { FormsModule as OvationFormsModule } from '@ovation/forms';
import { IncrementerModule } from '@ovation/incrementer';
import { ContentComponent } from './components/content/content.component';
import { FundCardComponent } from './components/fund-card/fund-card.component';
import { RiskSliderComponent } from '../shared/components/risk-slider/risk-slider.component';
import { FundContibutionsTableComponent } from '../shared/components/fund-contibutions-table/fund-contibutions-table.component';
import { LineGraphComponent } from './components/line-graph/line-graph.component';
import { ElementQueryDirective } from './directives';
import { TaxReliefSliderComponent } from './components/tax-relief-slider/tax-relief-slider.component';
import { SplitPercentageInputComponent } from './components/split-percentage-input/split-percentage-input.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { AllocateContributionsTableComponent } from './components/allocate-contributions-table/allocate-contributions-table.component';
import { DashboardFundsTableComponent } from './components/dashboard-funds-table/dashboard-funds-table.component';
import { SwitchContributionsTableComponent } from './components/switch-contributions-table/switch-contributions-table.component';
import { ConfirmSwitchContributionsTableComponent } from './components/confirm-switch-contributions-table/confirm-switch-contributions-table.component';
import { MonthlyContributionsTableComponent } from './components/monthly-contributions-table/monthly-contributions-table.component';
import { TaxReliefTextComponent } from './components/tax-relief-text/tax-relief-text.component';
import { BenefitsSummaryTableComponent } from './components/benefits-summary-table/benefits-summary-table.component';
import { ContributionsHistoryTableComponent } from './components/contributions-history-table/contributions-history-table.component';
import { ContributionsHistoryChartComponent } from './components/contributions-history-chart/contributions-history-chart.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FundCardHorizontalComponent } from './components/fund-card-horizontal/fund-card-horizontal.component';
import { FundCardCompactComponent } from './components/fund-card-compact/fund-card-compact.component';
import { InvestedFundsTableComponent } from './components/invested-funds-table/invested-funds-table.component';
import { ContributingFundsTableComponent } from './components/contributing-funds-table/contributing-funds-table.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularSvgIconModule,
    ChartsModule,
    DropDownsModule,
    NgDatepickerModule,
    ReactiveFormsModule,
    NgSelectModule,
    RatingsModule,
    DialogsModule,
    ButtonsModule,
    SliderModule,
    GridModule,
    OvationFormsModule,
    ScrollViewModule,
    InputsModule,
    DateInputsModule,
    LayoutModule,
    ExcelModule,
    PDFExportModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ExpansionModule,
    CheckboxModule,
    IncrementerModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DecimalPipe
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularSvgIconModule,
    ChartsModule,
    DropDownsModule,
    NgDatepickerModule,
    ReactiveFormsModule,
    NgSelectModule,
    RatingsModule,
    ContentComponent,
    FundCardComponent,
    DialogsModule,
    ButtonsModule,
    OvationFormsModule,
    IncrementerModule,
    GridModule,
    RiskSliderComponent,
    FundContibutionsTableComponent,
    ScrollViewModule,
    LineGraphComponent,
    ElementQueryDirective,
    TaxReliefSliderComponent,
    InputsModule,
    SplitPercentageInputComponent,
    PieChartComponent,
    AllocateContributionsTableComponent,
    DateInputsModule,
    DashboardFundsTableComponent,
    SwitchContributionsTableComponent,
    ConfirmSwitchContributionsTableComponent,
    LayoutModule,
    MonthlyContributionsTableComponent,
    TaxReliefTextComponent,
    BenefitsSummaryTableComponent,
    ExcelModule,
    PDFExportModule,
    ContributionsHistoryTableComponent,
    ContributionsHistoryChartComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DatePickerComponent,
    FundCardHorizontalComponent,
    FundCardCompactComponent,
    InvestedFundsTableComponent,
    ContributingFundsTableComponent,
    ExpansionModule,
    CheckboxModule,
    InfoDialogComponent
  ],
  declarations: [
    ContentComponent,
    FundCardComponent,
    RiskSliderComponent,
    FundContibutionsTableComponent,
    LineGraphComponent,
    ElementQueryDirective,
    TaxReliefSliderComponent,
    SplitPercentageInputComponent,
    PieChartComponent,
    AllocateContributionsTableComponent,
    DashboardFundsTableComponent,
    SwitchContributionsTableComponent,
    ConfirmSwitchContributionsTableComponent,
    MonthlyContributionsTableComponent,
    TaxReliefTextComponent,
    BenefitsSummaryTableComponent,
    ContributionsHistoryTableComponent,
    ContributionsHistoryChartComponent,
    DatePickerComponent,
    FundCardHorizontalComponent,
    FundCardCompactComponent,
    InvestedFundsTableComponent,
    ContributingFundsTableComponent,
    InfoDialogComponent
  ],
  entryComponents: [InfoDialogComponent]
})
export class SharedModule {}

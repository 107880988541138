import { Message } from '../../models';

export const MESSAGES: Message[] = [
  {
    id: 1,
    date: '2019-07-01T00:00:00+00:00',
    title: 'Your pension value is R 4.0m',
    sentTo: 'robert@company.com',
    messageType: 'email',
    read: true,
    data:
      "<h3>Amendments meant to improve retirement outcomes for investors</h3><p>Regulations requiring pension funds to set defaults are aimed at guiding you through saving for and buying a pension at retirement.</p><h2>FUNDS ARE READY TO IMPLEMENT… BUT THERE ARE A FEW TEETHING ISSUES.</h2><p>Amendments to the Pension Funds Act have just come into force, aimed at helping investors ensure they retire with enough money. But many funds have failed to meet the deadline to enact the changes.</p><p>The default regulations, as they are called, are the culmination of efforts by Treasury to ensure that South Africans get cheaper retirement products, more education on preserving their savings and counselling on what products to buy when they retire.</p><p>The desired outcome is a pensioner who has enough money to live on - unlike the majority of South Africans who currently retire with little or nothing.</p><p>However, many funds are not ready to implement new regulations under the Act and have applied for exemptions.</p><h5>The regulations, which came into force at the beginning of March, oblige funds to establish:</h5><ul><li>A default investment option for your savings;</li><li>A default option for preserving your savings if you change employers before you retire and the ability to move your savings to your new employer's fund; and</li><li>A default pension that you can opt into when you retire.</li></ul><p>The aim is to ensure that the trustees of your fund assist you to invest appropriately and cost-effectively and that you are encouraged to preserve your savings if you change jobs.</p><p>The regulations also oblige funds to give you good advice when you retire and to provide cost-effective and suitable pension options when your fund pays out at retirement.</p><p>Despite their having had 18 months to put these measures in place, many funds failed to meet the deadline.</p>"
  },
  {
    id: 2,
    date: '2019-07-01T00:00:00+00:00',
    title: 'Did you know',
    sentTo: 'robert@company.com',
    messageType: 'sms',
    read: false,
    data:
      "<h3>Amendments meant to improve retirement outcomes for investors</h3><p>Regulations requiring pension funds to set defaults are aimed at guiding you through saving for and buying a pension at retirement.</p><h2>FUNDS ARE READY TO IMPLEMENT… BUT THERE ARE A FEW TEETHING ISSUES.</h2><p>Amendments to the Pension Funds Act have just come into force, aimed at helping investors ensure they retire with enough money. But many funds have failed to meet the deadline to enact the changes.</p><p>The default regulations, as they are called, are the culmination of efforts by Treasury to ensure that South Africans get cheaper retirement products, more education on preserving their savings and counselling on what products to buy when they retire.</p><p>The desired outcome is a pensioner who has enough money to live on - unlike the majority of South Africans who currently retire with little or nothing.</p><p>However, many funds are not ready to implement new regulations under the Act and have applied for exemptions.</p><h5>The regulations, which came into force at the beginning of March, oblige funds to establish:</h5><ul><li>A default investment option for your savings;</li><li>A default option for preserving your savings if you change employers before you retire and the ability to move your savings to your new employer's fund; and</li><li>A default pension that you can opt into when you retire.</li></ul><p>The aim is to ensure that the trustees of your fund assist you to invest appropriately and cost-effectively and that you are encouraged to preserve your savings if you change jobs.</p><p>The regulations also oblige funds to give you good advice when you retire and to provide cost-effective and suitable pension options when your fund pays out at retirement.</p><p>Despite their having had 18 months to put these measures in place, many funds failed to meet the deadline.</p>"
  },
  {
    id: 3,
    date: '2019-07-01T00:00:00+00:00',
    title: 'Pension funds buy time on new rules',
    sentTo: 'robert@company.com',
    messageType: 'email',
    read: false,
    data:
      "<h3>Amendments meant to improve retirement outcomes for investors</h3><p>Regulations requiring pension funds to set defaults are aimed at guiding you through saving for and buying a pension at retirement.</p><h2>FUNDS ARE READY TO IMPLEMENT… BUT THERE ARE A FEW TEETHING ISSUES.</h2><p>Amendments to the Pension Funds Act have just come into force, aimed at helping investors ensure they retire with enough money. But many funds have failed to meet the deadline to enact the changes.</p><p>The default regulations, as they are called, are the culmination of efforts by Treasury to ensure that South Africans get cheaper retirement products, more education on preserving their savings and counselling on what products to buy when they retire.</p><p>The desired outcome is a pensioner who has enough money to live on - unlike the majority of South Africans who currently retire with little or nothing.</p><p>However, many funds are not ready to implement new regulations under the Act and have applied for exemptions.</p><h5>The regulations, which came into force at the beginning of March, oblige funds to establish:</h5><ul><li>A default investment option for your savings;</li><li>A default option for preserving your savings if you change employers before you retire and the ability to move your savings to your new employer's fund; and</li><li>A default pension that you can opt into when you retire.</li></ul><p>The aim is to ensure that the trustees of your fund assist you to invest appropriately and cost-effectively and that you are encouraged to preserve your savings if you change jobs.</p><p>The regulations also oblige funds to give you good advice when you retire and to provide cost-effective and suitable pension options when your fund pays out at retirement.</p><p>Despite their having had 18 months to put these measures in place, many funds failed to meet the deadline.</p>"
  }
];
